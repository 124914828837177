import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import ImageUploader from '../../shared/ImageUploader';

const styles = (theme) => ({
  avatar: {
    height: 125,
    marginBottom: theme.spacing.unit,
    width: 125
  }
});

export function ImageField({ classes, input }) {
  const [open, setOpen] = useState(false);

  const handleUpdate = (results) => {
    if (results && results.length) {
      const image = results[0];
      input.onChange(image);
    }
  };

  return (
    <React.Fragment>
      <Avatar className={classes.avatar} src={input.value}>C</Avatar>
      <IconButton onClick={() => setOpen(true)}>
        <PhotoCameraIcon />
      </IconButton>
      <ImageUploader
        allowNull
        onClose={() => setOpen(false)}
        onUpdate={handleUpdate}
        maxFiles={1}
        preset={process.env.REACT_APP_CLOUDINARY_PRESET}
        show={open}
        title="Upload App Image" />
    </React.Fragment>
  );
}

ImageField.propTypes = {
  classes: PropTypes.object.isRequired,
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired
  })
};

export default withStyles(styles)(ImageField);
