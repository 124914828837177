import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FieldControls from './FieldControls';

const styles = (theme) => ({
  fieldLabel: {
    color: theme.palette.primary.main,
    fontSize: '0.75em',
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  fieldValue: {
    borderBottom: [1, 'solid', theme.palette.grey[300]],
    cursor: 'pointer'
  },
  fieldWrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: 42,
  },
  showingPlaceholder: {
    opacity: 0.4
  }
});

class EditableField extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    editable: PropTypes.bool,
    label: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    variant: PropTypes.string
  }

  static defaultProps = {
    variant: 'subtitle1'
  }

  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      value: this.props.value
    };
  }

  handleCancel = () => {
    this.setState({ isEditing: false, value: this.props.value });
  }

  handleConfirm = () => {
    this.setState({ isEditing: false });
    if (this.props.onChange) {
      this.props.onChange(this.state.value);
    }
  }

  handleChange = (event) => {
    this.setState({ isEditing: true, value: event.target.value });
  }

  handleClick = () => {
    this.setState({ isEditing: true });
  }

  render() {
    const { className: classNameProp, classes, editable, label, variant } = this.props;

    let fieldComponent = <Typography variant={variant}>{this.props.value}</Typography>;

    if (editable) {
      fieldComponent = (
        <Typography
          className={classNames(
            classes.fieldValue,
            { [classes.showingPlaceholder]: !this.state.value },
            classNameProp
          )}
          onClick={this.handleClick}
          variant={variant}>
          {this.state.value || this.props.placeholder}
        </Typography>
      );
    }

    if (this.state.isEditing) {
      fieldComponent = (
        <TextField
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <FieldControls
                  handleCancel={this.handleCancel}
                  handleConfirm={this.handleConfirm} />
              </InputAdornment>
            )
          }}
          onChange={this.handleChange}
          placeholder={this.props.placeholder}
          value={this.state.value} />
      );
    }

    return (
      <div className={classes.fieldWrapper}>
        {label && <Typography className={classes.fieldLabel} variant={variant}>{label}</Typography>}
        {fieldComponent}
      </div>
    );
  }
}

export default withStyles(styles)(EditableField);
