import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { ProjectRoom } from '@colliers-international/project-room';
import { ability } from '../config/ability';

const styles = (theme) => ({
  projectRoomContainer: {
    height: 'calc(100vh - 86px)',
    marginLeft: theme.spacing.unit * 6,
    [theme.breakpoints.down('xs')]: {
      height: 'calc(100vh - 73px)',
      marginLeft: 0,
    }
  }
});

const ProjectRoomContainer = ({ classes, createInvites, history, location, logoUrl, match, setPageTitles }) => {
  if (!ability.can('read', 'projectRooms', ability.defaultTenantId)) {
    history.push('/');
    return null;
  }

  function handleInvite(recipients, projectName) {
    createInvites({
      logoUrl,
      projectName,
      projectUrl: `${window.location.origin}${location.pathname}`,
      recipients
    });
  }

  function handleTitle(title) {
    setPageTitles({
      route: `Project | ${title}`,
      mobile: title
    });
  }

  return (
    <div className={classes.projectRoomContainer}>
      <ProjectRoom
        channelUrl={match.params.id}
        onInvite={handleInvite}
        onTitle={handleTitle} />
    </div>
  );
};

ProjectRoomContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  createInvites: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  logoUrl: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  setPageTitles: PropTypes.func.isRequired,
};

export default withStyles(styles)(ProjectRoomContainer);
