import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import InputComponent from './InputComponent';

const styles = {
  input: {
    display: 'flex',
    padding: 0,
  },
};

class Control extends React.Component {
  render() {
    const { children, classes, innerProps, innerRef, selectProps } = this.props;

    return (
      <TextField
        fullWidth
        InputProps={{
          inputComponent: InputComponent,
          inputProps: {
            className: classes.input,
            inputRef: innerRef,
            children,
            ...innerProps,
          },
        }}
        {...selectProps.textFieldProps} />
    );
  }
}

Control.propTypes = {
  children: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  innerProps: PropTypes.object.isRequired,
  innerRef: PropTypes.func,
  selectProps: PropTypes.object.isRequired,
};

export default withStyles(styles)(Control);