import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgComponent = (props) => (
  <SvgIcon
    viewBox="0 0 44 44" role="img" aria-labelledby="title"
    {...props}>
    <title id="title">{props.title || 'Support icon'}</title>
    <g fill="none" fillRule="evenodd">
      <circle cx={22} cy={22} r={22} />
      <g transform="translate(6 6)">
        <path
          d="M27.451 25.201c-.416.638-1.068 1.168-1.957 1.59A330.896 330.896 0 0 0 17 31"
          stroke="#FFC605"
          strokeWidth={2.28}
          strokeLinecap="round"
          strokeLinejoin="round" />
        <circle
          fill="#FFC605"
          cx={16.5}
          cy={29.5}
          r={2.5} />
        <path
          d="M4 22V9.429C4 4.22 7.582 0 12 0h8c4.418 0 8 4.221 8 9.429V22"
          stroke="#FFF"
          strokeWidth={2.28} />
        {/* eslint-disable-next-line */}
        <path d="M28.052 12h1.896c1.133 0 2.052.919 2.052 2.052v6.896A2.052 2.052 0 0 1 29.948 23h-1.896A2.052 2.052 0 0 1 26 20.948v-6.896c0-1.133.919-2.052 2.052-2.052z" fill="#FFF" />
        <rect
          fill="#FFF"
          y={12}
          width={6}
          height={11}
          rx={3} />
      </g>
    </g>
  </SvgIcon>
);

SvgComponent.propTypes = {
  title: PropTypes.string,
};

export default SvgComponent;
