import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Skin from '../config/skin';

const styles = (theme) => ({
  logo: {
    zIndex: theme.zIndex.drawer + 101,
    position: 'fixed',
    top: theme.spacing.unit * 2.5,
    left: theme.spacing.unit * 2.5,
    height: 100,
    overflow: 'visible',
    transition: theme.transitions.create('left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('xs')]: {
      left: '50% !important',
      top: theme.spacing.unit * 3.5,
      marginLeft: -50
    }
  },
  logoOpen: {
    left: theme.spacing.unit * 2.5,
    transition: theme.transitions.create('left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }
});

const Logo = ({
  classes,
  navDrawerOpen,
  permissionsFetched,
  toggleNavDrawer
}) => {
  if (permissionsFetched) {
    return (
      <Link to="/" onClick={toggleNavDrawer}>
        <img
          alt={`${Skin.name} logo`}
          src={Skin.logoUrl}
          className={classNames(classes.logo, { [classes.logoOpen]: navDrawerOpen })} />
      </Link>
    );
  }

  return null;
};

Logo.propTypes = {
  classes: PropTypes.object.isRequired,
  navDrawerOpen: PropTypes.bool,
  permissionsFetched: PropTypes.bool.isRequired,
  toggleNavDrawer: PropTypes.func
};

export default withStyles(styles)(Logo);
