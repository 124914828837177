import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import withMobileDialog from '@material-ui/core/withMobileDialog';

const Legalese = ({ fetchUserProfile, fetchingProfile, email, fullScreen, hasAcceptedTerms, updateUserProfile }) => {
  useEffect(() => {
    if (fetchingProfile === undefined) fetchUserProfile();
  });

  let open = fetchingProfile === false;

  if (hasAcceptedTerms || (email && email.includes('@colliers.com'))) open = false;

  const handleAccept = async () => await updateUserProfile({ hasAcceptedTerms: true });

  return (
    <Dialog fullScreen={fullScreen} open={open} scroll="paper">
      <DialogTitle>User Agreement</DialogTitle>
      <DialogContent>
        <Typography>
          {/* eslint-disable-next-line max-len */}
          {'Welcome to the Colliers Technology Platform. Please review the following rules and terms that govern your use and access to the Colliers Technology Platform and Colliers360 as they constitute a legally binding agreement between you, your company and Colliers International. You have been granted access (on a read-only basis) to view the Colliers Technology Platform for general informational purposes only. Your access to the Colliers Technology Platform and Colliers360 is solely for your use; any other use is expressly prohibited. Colliers International makes no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability or completeness of any data or information contained on the Colliers Technology Platform or Colliers360. You are hereby advised not to rely on any of the data or information contained on the Colliers Technology Platform or Colliers360 without first making an independent verification as to the accuracy, adequacy, validity, reliability, availability and completeness thereof. You acknowledge and agree that you have no right, title or interest in or to the Colliers Technology Platform or Colliers360, including, without limitation, no right, title or interest in or to the software (or any intellectual property rights embedded therein). Colliers International shall not be liable for any special, indirect, incidental, consequential, punitive or exemplary damages in connection with the Colliers Technology Platform, including, but not limited to, lost profits and data, even if Colliers International has knowledge of the possibility of such damages.'}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleAccept}>Accept</Button>
      </DialogActions>
    </Dialog>
  );
};

Legalese.propTypes = {
  email: PropTypes.string.isRequired,
  fetchUserProfile: PropTypes.func.isRequired,
  fetchingProfile: PropTypes.bool,
  fullScreen: PropTypes.bool.isRequired,
  hasAcceptedTerms: PropTypes.bool,
  updateUserProfile: PropTypes.func.isRequired
};

export default withMobileDialog()(Legalese);
