export function serializeParams(obj) {
  return Object.keys(obj)
    .map((key) => `${key}=${encodeURIComponent(obj[key])}`)
    .join('&');
}

class Api {
  constructor(apiUrl, tokenKey) {
    this.apiUrl = apiUrl;
    this.tokenKey = tokenKey;
  }

  url = (resource, id, queryParams) => {
    if (resource.indexOf('/') !== 0) {
      resource = `/${resource}`;
    }

    let queryString;
    if (queryParams) {
      queryString = serializeParams(queryParams);
    }

    let urlString;
    if (queryString) {
      urlString = `${this.apiUrl}${resource}${id ? `/${id}/?` : '/?'}${queryString}`;
    } else {
      urlString = `${this.apiUrl}${resource}${id ? `/${id}` : ''}`;
    } 

    urlString = urlString.replace(/(https?:\/\/)|(\/){2,}/g, '$1$2');

    return urlString;
  }

  checkStatus = (response) => {
    return response.ok ? Promise.resolve(response) : Promise.reject(response);
  }

  getHeaders = (headers = {}, isJson = true) => {
    const token = window.localStorage.getItem(this.tokenKey) || null;
    const finalHeaders = {
      Accept: 'application/json',
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
      Authorization: `Bearer ${token}`,
      ...headers,
    };

    if (isJson) {
      finalHeaders['Content-Type'] = 'application/json';
    }

    return finalHeaders;
  }

  parseJson = (response) => {
    return response.json().then((items) => {
      const total = response.headers.get('X-Total-Items');
      if (total) items.total = parseInt(total, 10);
      return items;
    });
  }

  create = (resource, data, headers, queryParams) => {
    return fetch(this.url(resource, null, queryParams), {
      method: 'POST',
      headers: this.getHeaders(headers, !(data instanceof FormData)),
      body: (data instanceof FormData) ? data : JSON.stringify(data),
      // formData: data
    })
      .then(this.checkStatus)
      .then(this.parseJson);
  }

  download = (resource, id, queryParams, options = {}) => {
    return fetch(this.url(resource, id, queryParams), {
      method: 'GET',
      headers: this.getHeaders(),
      ...options,
    }).then(this.checkStatus);
  }

  read = (resource, id, queryParams, options = {}) => {
    return this.download(resource, id, queryParams, options)
      .then(this.parseJson);
  }

  update = (resource, { id, ...data }, fullUpdate = false) => {
    return fetch(this.url(resource, id), {
      method: fullUpdate ? 'PUT' : 'PATCH',
      headers: this.getHeaders(),
      body: (data instanceof FormData) ? data : JSON.stringify(data)
    })
      .then(this.checkStatus)
      .then(this.parseJson);
  }

  destroy = (resource, id) => {
    return fetch(this.url(resource, id), {
      method: 'DELETE',
      headers: this.getHeaders(),
    }).then(this.checkStatus);
  }
}

export default Api;
