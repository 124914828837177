import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Paper, Typography, withStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Review from './Review';
import ReviewFormDialog from './ConnectedReviewFormDialog';

const styles = (theme) => ({
  center: {
    display: 'flex',
    justifyContent: 'center'
  },
  loadingContainer: {
    paddingTop: theme.spacing.unit * 4,
    paddingBottom: theme.spacing.unit * 4,
  },
  noReviews: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    height: '150px',
    textAlign: 'center',
    padding: theme.spacing.unit * 2,
    marginLeft: theme.spacing.unit * 4,
    marginRight: theme.spacing.unit * 4
  },
  reviewButton: {
    borderRadius: '5px',
    color: theme.palette.secondary.light,
    fontWeight: 'bold',
    textTransform: 'none',
  },
  reviewContainer: {
    marginTop: theme.spacing.unit * 5,
    marginBottom: theme.spacing.unit * 5,
    paddingLeft: theme.spacing.unit * 4,
    paddingRight: theme.spacing.unit * 4,
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  reviewHeader: {
    paddingLeft: theme.spacing.unit * 4,
    paddingRight: theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit * 3,
    alignItems: 'center'
  },
  reviewTitle: {
    color: theme.palette.secondary.light,
    fontWeight: 'bold'
  },
});

class ReviewContainer extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    clearReviews: PropTypes.func.isRequired,
    fetchReviews: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    reviews: PropTypes.object,
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      openDialog: false
    };
  }

  componentDidMount() {
    this.props.fetchReviews({
      slug: this.props.slug,
      page: this.props.reviews.page ? this.props.reviews.page : 1,
      limit: this.props.reviews.limit ? this.props.reviews.limit : 6
    });
  }

  componentWillUnmount() {
    this.props.clearReviews();
  }

  handleFetchMoreClick = () => {
    this.props.fetchReviews({
      slug: this.props.slug,
      page: this.props.reviews.page + 1,
      limit: this.props.reviews.limit ? this.props.reviews.limit : 6
    });
  }

  handleOpenDialog = () => {
    this.setState({ openDialog: true });
  }

  handleCloseDialog = () => {
    this.setState({ openDialog: false });
  }

  // can edit the review if it's the same id as mine (or if it has no idea -- that indicates I just created it)
  getEditFunc(review) {
    if (this.props.reviews && this.props.reviews.my && (this.props.reviews.my._id === review._id || !review._id)) {
      return this.handleOpenDialog;
    }
    return null;
  }

  render() {
    const {
      classes,
      isFetching,
      reviews,
      slug,
      title
    } = this.props;

    // if finished fetching successfully
    if (reviews && reviews.docs) {
      return (
        <React.Fragment>
          <Grid
            container
            className={classes.reviewContainer}>
            <Grid
              item
              className={classes.reviewHeader}
              style={{ display:'flex',justifyContent:'space-between' }}
              xs={12}>
              <div style={{ display:'flex',justifyContent:'flex-start',alignItems:'baseline' }}>
                <Typography variant="h5" className={classes.reviewTitle}>Reviews</Typography>
              </div>
              <Button
                className={classes.reviewButton}
                onClick={this.handleOpenDialog}
                variant="outlined">
                + {reviews.my && Object.keys(reviews.my).length ? 'Edit' : 'Add'} Review
              </Button>
            </Grid>

            {reviews.docs && reviews.docs.length > 0 && (
              <React.Fragment>
                {reviews.docs && reviews.docs.map((review, index) => (
                  <Grid
                    key={index} item xs={12}
                    sm={6}>
                    <Review onEdit={this.getEditFunc(review)} {...review} />
                  </Grid>
                ))}
                {reviews.pages && ~~reviews.pages > ~~reviews.page && 
                  <Grid item xs={12} className={classes.center}>
                    {isFetching && <CircularProgress />}
                    {!isFetching && (
                      <Button
                        onClick={this.handleFetchMoreClick}
                        variant="outlined">Load More</Button>
                    )}
                  </Grid>
                }
              </React.Fragment>
            )}

            {!(reviews.docs && reviews.docs.length > 0) && (
              <Paper className={classes.noReviews}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography>No reviews yet! Be the first to write one.</Typography>
                  </Grid>
                </Grid>
              </Paper>
            )}
          </Grid>

          {this.state.openDialog && (
            <ReviewFormDialog
              appSlug={slug}
              appTitle={title}
              myReview={reviews.my}
              onClose={this.handleCloseDialog} />
          )}

        </React.Fragment>
      );
    } else if (isFetching) {
      return <div className={classes.loadingContainer}><CircularProgress /></div>;
    }
    return null;
  }
}

export default withStyles(styles)(ReviewContainer);
