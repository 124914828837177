import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Profile from './Profile';
import * as actions from './actions';

const mapStateToProps = ({ profile }) => {
  return {
    hasError: false,
    isFetching: false,
    ...profile
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(actions, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
