import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import skin from '../config/skin';
import UserModal from './UserModal';
import * as actions from './actions';

const mapStateToProps = () => {
  return {
    logoUrl: `${window.location.origin}${skin.logoUrl}`
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(actions, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(UserModal);
