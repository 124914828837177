import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Star, StarHalf, StarBorder } from '@material-ui/icons';

const styles = (theme) => ({
  root: {
    color: 'rgba(0,0,0,0.25)',
  },
  hovered: {
    backgroundColor: 'inherit',
    color: theme.palette.colliersYellow,
    opacity: 0.75,
  },
  yellow: {
    color: theme.palette.colliersYellow,
  }
});

class RatingStar extends Component {
  static propTypes = {
    averageRating: PropTypes.number,
    classes: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    handleClick: PropTypes.func.isRequired,
    handleMouseEnter: PropTypes.func.isRequired,
    handleMouseLeave: PropTypes.func.isRequired,
    hoverValue: PropTypes.number,
    index: PropTypes.number.isRequired,
    rating: PropTypes.number,
  }

  starClick = () => {
    this.props.handleClick(this.props.index);
  }

  starHover = () => {
    this.props.handleMouseEnter(this.props.index);
  }

  renderIcon() {
    const { averageRating, classes, index, hoverValue, rating } = this.props;
    const filled = index <= rating;
    const hovered = index <= hoverValue;

    if (hoverValue > 0) {
      if (hovered) {
        return <Star className={classes.hovered} />;
      }
      return <StarBorder />;
    } else if (averageRating && rating === null) {
      const leftOver = averageRating - (index - 1);
      if (leftOver > .76) {
        return <Star />;
      } else if (leftOver > .35 && leftOver < .76) {
        return <StarHalf />;
      }
      return <StarBorder />;
    }
    if (filled) {
      return <Star className={classes.yellow} />;
    }
    return <StarBorder />;
  }

  render() {
    const { classes, index, handleMouseLeave, disabled } = this.props;

    return (
      <span
        key={index}
        className={classes.root}
        disabled={disabled}
        onMouseEnter={this.starHover}
        onMouseLeave={handleMouseLeave}
        onClick={this.starClick} >
        {this.renderIcon()}
      </span>
    );
  }
}

export default withStyles(styles)(RatingStar);
