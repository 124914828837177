import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReviewContainer from './ReviewContainer';
import * as actions from './actions';

const mapStateToProps = ({ reviews }) => {
  return {
    isFetching: reviews.isFetching,
    hasError: reviews.hasError,
    reviews: reviews.reviews
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(actions, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewContainer);