import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button, Grid, Typography, withStyles } from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditOutlined';
import Rating from '../../shared/rating/Rating';

const styles = (theme) => ({
  editButton: {
    color: theme.palette.secondary.light,
  },
  noReview: {
    fontStyle: 'italic'
  },
  spaceOut: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  reviewItem: {
    color: theme.palette.secondary.light,
    paddingLeft: theme.spacing.unit * 4,
    paddingRight: theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit * 4,
    whiteSpace: 'pre-line'
  },
  reviewRatingContainer: {
    height: '40px'
  },
  reviewText: {
    color: 'inherit',
    cursor: 'pointer',
    fontWeight: 'bold',
    transition: theme.transitions.create('color', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '&:hover': {
      color: theme.palette.text.secondary,
    }
  },
  reviewTextContainer: {
    marginBottom: theme.spacing.unit,
    marginTop: 0,
    wordBreak: 'break-word',
    minHeight: '6em',
    [theme.breakpoints.down('xs')]: {
      minHeight: 0
    }
  },
  reviewTextClamped: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': '4',  /* number of lines to show */
    lineHeight: '1.5em',        /* fallback */
    maxHeight: '6em',           /* fallback */
  },
  reviewUser: {
    color: theme.palette.primary.main,
    fontSize: '1rem',
    fontWeight: 'bold',
  },
});

class Review extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    onEdit: PropTypes.func,
    rating: PropTypes.number,
    review: PropTypes.string,
    user: PropTypes.object,
  }
  
  constructor(props) {
    super(props);
    this.state = { clamped: true };
  }

  handleClick = () => {
    this.setState({ clamped: !this.state.clamped });
  }

  render() {
    const {
      classes,
      onEdit,
      rating,
      review,
      user
    } = this.props;

    const publicUser = (user && user.public) ? user.public : {};

    return (
      <Grid
        container
        className={classes.reviewItem}
        alignItems="stretch"
        direction="column"
        justify="flex-start">
        <div className={classnames(classes.reviewRatingContainer, classes.spaceOut)}>
          <Rating
            readOnly={true}
            fixedRating={rating} />
          {onEdit && (
            <Button className={classes.editButton} onClick={onEdit}>
              <EditIcon />
            </Button>
          )}
        </div>
        <div
          className={classnames(classes.reviewTextContainer, { [classes.reviewTextClamped]: this.state.clamped })}
          onClick={this.handleClick}>
          {review && <Typography variant="body1" className={classes.reviewText}>{review}</Typography>}
          {!review && <Typography variant="caption" className={classes.noReview}>(no comments included)</Typography>}
        </div>
        <Typography variant="button" className={classes.reviewUser}>
          - {publicUser.name || 'Anonymous'}{publicUser.location && `, ${publicUser.location}`}
        </Typography>
      </Grid>
    );
  }
}

export default withStyles(styles)(Review);
