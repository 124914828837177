
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { AuthConsumer } from 'react-check-auth';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ViewListIcon from '@material-ui/icons/ViewList';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
import Chip from '@material-ui/core/Chip';
import InputAdornment from '@material-ui/core/InputAdornment';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import SearchIcon from '@material-ui/icons/Search';
import debounce from 'debounce';
import FullScreenLoader from '../shared/FullScreenLoader';
import DefaultPageLayout from '../shared/DefaultPageLayout';
import { CATEGORIES } from '../constants/categories';
import CategoryApps from './CategoryApps';
import ListView from './ListView';
import CardView from './CardView';

const styles = (theme) => ({
  addButtonIcon: {
    height: 90,
    width: 90
  },
  addCard: {
    '&': {
      alignItems: 'center',
      borderRadius: '10px',
      color: theme.palette.grey[600],
      display: 'flex',
      height: 268,
      justifyContent: 'center',
      margin: 'auto',
    },
    '&:hover': {
      color: theme.palette.primary.light,
    }
  },
  noMatches: {
    alignItems: 'center',
    borderRadius: '10px',
    color: theme.palette.grey[600],
    display: 'flex',
    minHeight: 125,
    justifyContent: 'center',
    margin: 'auto',
  },
  dropdown: {
    borderRadius: 5,
    color: theme.palette.text.secondary,
    fontWeight: 'bold',
    justifyContent: 'space-between',
    textAlign: 'left',
    textTransform: 'none',
    width: '48%',
    marginLeft: theme.spacing.unit,
    whiteSpace: 'nowrap'
  },
  dropdownContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 440,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    }
  },
  dropdownInner: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  filterTag: {
    cursor: 'pointer',
    margin: [1, 2],
    '& > span': {
      padding: [0, 8],
    },
    '& > svg': {
      width: 18
    }
  },
  filterTagList: {
    backgroundColor: '#f3fbff !important',
    flexWrap: 'wrap',
    height: 'auto',
    minHeight: 24,
    padding: 4,
  },
  menuItem: {
    borderBottom: '1px solid #EEEEEE',
  },
  outerContainer: {
    
  },
  searchContainer: {
    alignItems: 'flex-end',
    display: 'flex',
    marginRight: theme.spacing.unit * 3,
    width: 300,
    [theme.breakpoints.down('xs')]: {
      margin: [10, 0, 0, theme.spacing.unit],
      width: 'auto'
    }
  },
  searchInput: {
    height: 38,
    opacity: 0.75
  },
  searchIcon: {
    color: theme.palette.text.secondary
  },
  tooltip: {
    top: '-8px !important'
  },
  viewButton: {
    marginLeft: theme.spacing.unit,
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  currentViewButton: {
    color: '#2B3F8A'
  }
});

class Launcher extends Component {
  static propTypes = {
    addTagToFilterBy: PropTypes.func.isRequired,
    apps: PropTypes.array,
    classes: PropTypes.object.isRequired,
    fetchUserApps: PropTypes.func.isRequired,
    filterBy: PropTypes.array,
    groupConfig: PropTypes.object.isRequired,
    groups: PropTypes.array,
    isFetching: PropTypes.bool.isRequired,
    removeTagFromFilterBy: PropTypes.func.isRequired,
    sortBy: PropTypes.string,
    theme: PropTypes.object.isRequired,
    updateSort: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      inHoverStateCreate: false,
      menuAnchorSort: null,
      menuAnchorFilter: null,
      filterFilter: '',
      filterTag: null,
      isCategoryView: true,
      isCardView: false,
      isListView: false,
      search: '',
      alphabeticalSorted: false,
      featureSorted: false,
      pinnedSorted: false
    };
  }

  componentDidMount() {
    this.props.fetchUserApps();
  }

  handleListViewClick = () => {
    this.setState({ isListView: true, isCategoryView: false, isCardView: false });
  }

  handleCardViewClick = () => {
    this.setState({ isCardView: true, isCategoryView: false, isListView: false });
  }

  handleCategoryViewClick = () => {
    this.setState({ isCategoryView: true, isCardView: false, isListView: false });
  }

  handleMouseEnterCreate = () => {
    this.setState({ inHoverStateCreate: true });
  }

  handleMouseLeaveCreate = () => {
    this.setState({ inHoverStateCreate: false });
  }

  handleSortClick = (event) => {
    this.setState({ menuAnchorSort: event.currentTarget });
  }

  handleSortClose = () => {
    this.setState({ menuAnchorSort: null });
  }

  updateSort = (sortBy) => () => {
    this.setState({ sortBy });
    this.handleSortClose();
  }

  handleFilterClick = (event) => {
    this.setState({ menuAnchorFilter: event.currentTarget });
  }

  handleFilterClose = () => {
    this.setState({ menuAnchorFilter: null });
  }

  updateFilter = (filterTag) => () => {
    this.setState({
      filterTag,
      filterFilter: ''
    });
    this.handleFilterClose();
  }

  clearFilter = () => {
    this.setState({ filterTag: null });
  }

  filterFilters = (event) => {
    this.setState({ filterFilter: event.target.value });
  }

  updateSearch = (event) => {
    this.updateSearchDebounced(event.target.value);
  }
  updateSearchDebounced = debounce((input) => {
    this.setState({ search: input });
  }, 200);

  updateSort = (sortBy = null) => () => {
    this.props.updateSort(sortBy);

    if (sortBy === null) {
      this.setState({ alphabeticalSorted: false, featureSorted: false, pinnedSorted: false });
    }

    if (sortBy === 'az') {
      this.setState({ alphabeticalSorted: false });
    } else if (sortBy === 'za') {
      this.setState({ alphabeticalSorted: true });
    }

    if (sortBy === 'feature') {
      this.setState({ featureSorted: !this.state.featureSorted });
    }

    if (sortBy === 'pin') {
      this.setState({ pinnedSorted: !this.state.pinnedSorted });
    }
  
    this.handleSortClose();
  }
  addFilterTag = (filterTag) => () => {
    this.props.addTagToFilterBy(filterTag);
  }
  deleteFilterTag = (filterTag) => () => {
    this.props.removeTagFromFilterBy(filterTag);
  }

  getCurrentSortLabel = () => {
    if (this.state.search) {
      return 'Sort by Relevance';
    } 
    switch (this.props.sortBy) {
      case 'az': return 'Sort Alphabetically (A-Z)';
      case 'za': return 'Sort Alphabetically (Z-A)';
      default: return 'Sort by Relevance';
    }
  };

  cleanSearchString = (str) => {
    return str.replace(/[^a-zA-Z0-9]/g, ' ').replace(/\s{2,}/, ' ');
  }

  sortByTitle = (a, b) => {
    return a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1;
  }
  
  sortByPin = (a, b) => {
    return this.pinnedSorted ? a.pinned - b.pinned : b.pinned - a.pinned;
  }
  
  sortByFeature = (a, b) => {
    return this.featureSorted ? a.featured - b.featured : b.featured - a.featured;
  }
  
  sortByFeaturedRank = (a, b) => {
    return a.featuredRank < b.featuredRank ? -1 : 1;
  }
  
  defaultSort = (a, b) => {
    if (a.featured !== b.featured) {
      // If featured status is different, prioritize featured items
      return this.sortByFeature(a, b) || (b.featured - a.featured);
    }
    
    if (a.pinned !== b.pinned) {
      // If pinned status is different, prioritize pinned items
      const pinSort = this.sortByPin(a, b);
      if (pinSort !== 0) {
        return pinSort;
      }
    }
  
    // If featured and pinned status are the same, or pinSort is 0, sort alphabetically
    return this.sortByTitle(a, b);
  }

  render() {
    const { 
      classes, 
      filterBy, 
      groupConfig, 
      groups, 
      isFetching, 
      sortBy 
    } = this.props;
    const { 
      inHoverStateCreate, 
      isCardView, 
      isCategoryView, 
      isListView, 
      menuAnchorSort, 
      menuAnchorFilter, 
      search, alphabeticalSorted, featureSorted, pinnedSorted  
    } = this.state;

    const sortFunctions = {
      az: this.sortByTitle.bind(this),
      za: (a, b) => this.sortByTitle(b, a),
      pin: ((a, b) => {
        if (a.pinned !== b.pinned) {
          return pinnedSorted ? a.pinned - b.pinned : b.pinned - a.pinned;
        }
        return this.sortByTitle(a, b);
      }),
      feature: ((a, b) => {
        if (a.featured !== b.featured) {
          return featureSorted ? a.featured - b.featured : b.featured - a.featured;
        }
        if (a.featured && b.featured && a.featuredRank !== b.featuredRank) {
          return this.sortByFeaturedRank(a, b);
        }
        return this.sortByTitle(a, b);
      }),
    };

    // consistent menu positioning
    const menuPositionProps = {
      getContentAnchorEl: null,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
      PaperProps: {
        style: {
          maxHeight: '50%',
          width: 225,
        },
      }
    };

    const launcherGroups = groups.filter(({ id }) => groupConfig.launcherGroupIds.includes(id));
    
    // filter to get apps enabled by viewAs client
    const enabledAppIds = launcherGroups
      .reduce((acc, cur) => acc.concat(cur.enabledApps || []), [])
      .filter((value, index, self) => self.indexOf(value) === index);
    const apps = this.props.apps.filter((app) => enabledAppIds.includes(app._id));

    // get featured apps according to launcher groups
    const featuredAppsByGroup = launcherGroups
      .filter((group) => group.featuredApps && group.featuredApps.length > 0)
      .map((group) => group.featuredApps);
      
    // get all featured apps (from all groups) combined, then sort by final group's featured app order
    let launcherFeaturedApps = [];
    if (featuredAppsByGroup.length > 0) {
      const mostRecentGroupRankings = featuredAppsByGroup[featuredAppsByGroup.length - 1];
      launcherFeaturedApps = featuredAppsByGroup
        .reduce((acc, value) => { return [...acc, ...value]; }, [])
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort((a, b) => {
          const aIndex = mostRecentGroupRankings.indexOf(a);
          const bIndex = mostRecentGroupRankings.indexOf(b);
          if (aIndex === bIndex) {
            return 0;
          } else if (aIndex === -1) {
            return 1;
          } else if (bIndex === -1) {
            return -1;
          }
          return aIndex < bIndex ? -1 : 1;
        });
    }

    return (
      <AuthConsumer>
        {({ isLoading, userInfo }) => {
          if (isLoading || isFetching) {
            return <FullScreenLoader />;
          } else if (userInfo) {
            // get a list of unique tags used by the apps list
            const uniqueTags = Array.from(
              new Set(
                apps.reduce((allTags, app) => allTags.concat(app.tags), [])
              )
            ).sort((a, b) => (a.toLowerCase() < b.toLowerCase() ? -1 : 1));

            // apply featured apps (including ordering)
            let filteredApps = apps.map((app) => {
              const featuredRank = launcherFeaturedApps.indexOf(app._id);
              return {
                ...app,
                featured: (featuredRank > -1),
                featuredRank: (featuredRank > -1) ? featuredRank : launcherFeaturedApps.length
              };
            });

            // apply filterBy tags to apps
            if (filterBy.length > 0) {
              filteredApps = filteredApps.filter((app) => app.tags.find((t) => ~filterBy.indexOf(t)));
            }

            // apply search string (and sort by search relevance)
            if (search) {
              const searchRegex = new RegExp(`\\b${this.cleanSearchString(search)}`, 'ig');
              filteredApps = filteredApps
                .map((app) => {
                  const searchable = [
                    app.title,
                    app.tags.join(' '),
                    app.url,
                    app.summary,
                    app.description,
                    (app.contact || {}).name,
                    (app.contact || {}).email,
                  ];

                  // the earlier the match the better, and also benefit from multiple matches
                  let searchScore = 0;
                  searchable.forEach((str, index) => {
                    if (str) {
                      const matches = this.cleanSearchString(str).match(searchRegex);
                      if (matches) {
                        searchScore += matches.length * Math.pow(10, searchable.length - index);
                      }
                    }
                  });

                  // add the searchScore to the filtered app objects
                  return {
                    ...app,
                    searchScore
                  };
                })

                // filter out any non-matches
                .filter((app) => app.searchScore > 0);
            }

            // apply sort
            filteredApps.sort((a, b) => {
              if (search && a.searchScore !== b.searchScore) {
                return a.searchScore > b.searchScore ? -1 : 1;
              }
          
              return sortFunctions[sortBy] ? sortFunctions[sortBy](a, b) : this.defaultSort(a, b);
            });

            // render
            return (
              <DefaultPageLayout
                title={isCategoryView ? 'Applications by Category' : 'Applications'}
                titleActions={(
                  <React.Fragment>
                    <div className={classes.searchContainer}>
                      <TextField
                        className={classes.searchInput}
                        fullWidth
                        onChange={this.updateSearch}
                        placeholder="Search"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon className={classes.searchIcon} />
                            </InputAdornment>
                          )
                        }} />
                    </div>
                    <div className={classes.dropdownContainer}>
                      <Tooltip classes={{ popper: classes.tooltip }} placement="bottom" title="Categories">
                        <IconButton
                          className={`${classes.viewButton} ${isCategoryView ? classes.currentViewButton : ''}`}
                          onClick={this.handleCategoryViewClick}>
                          <ViewListIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip classes={{ popper: classes.tooltip }} placement="bottom" title="Tiles">
                        <IconButton
                          className={`${classes.viewButton} ${isCardView ? classes.currentViewButton : ''}`}
                          onClick={this.handleCardViewClick}>
                          <ViewModuleIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip classes={{ popper: classes.tooltip }} placement="bottom" title="List">
                        <IconButton
                          className={`${classes.viewButton} ${isListView ? classes.currentViewButton : ''}`}
                          onClick={this.handleListViewClick}>
                          <ViewHeadlineIcon />
                        </IconButton>
                      </Tooltip>
                      <Button
                        className={classes.dropdown}
                        disabled={!!search}
                        onClick={this.handleSortClick}
                        variant="outlined">
                        <span className={classes.dropdownInner}>
                          {this.getCurrentSortLabel()}
                        </span>
                        <KeyboardArrowDownIcon />
                      </Button>
                      <Menu
                        anchorEl={menuAnchorSort}
                        open={Boolean(menuAnchorSort)}
                        onClose={this.handleSortClose}
                        {...menuPositionProps}>
                        <MenuItem
                          onClick={this.updateSort(null)}
                          className={classes.menuItem}>
                          Relevance
                        </MenuItem>
                        <MenuItem
                          onClick={this.updateSort('az')}
                          className={classes.menuItem}>
                          Alphabetical (A-Z)
                        </MenuItem>
                        <MenuItem
                          onClick={this.updateSort('za')}
                          className={classes.menuItem}>
                          Alphabetical (Z-A)
                        </MenuItem>
                      </Menu>

                      <Button
                        className={classes.dropdown}
                        onClick={this.handleFilterClick}
                        variant="outlined">
                        {filterBy.length === 0 && 'Filter by...'}
                        {filterBy.length > 0 && (
                          <span className={classes.dropdownInner}>
                            Tag{filterBy.length > 1 ? 's' : ''}:
                            &quot;{filterBy.join('", "')}&quot;
                          </span>
                        )}
                        <KeyboardArrowDownIcon />
                      </Button>
                      <Menu
                        anchorEl={menuAnchorFilter}
                        open={Boolean(menuAnchorFilter)}
                        onClose={this.handleFilterClose}
                        getContentAnchorEl={null}
                        MenuListProps={{ style:{ paddingTop: 0 } }}
                        {...menuPositionProps}>
                        {filterBy.length > 0 && (
                          <MenuItem className={classNames([classes.menuItem,classes.filterTagList])}>
                            {filterBy.map((tag, index) => (
                              <Chip
                                key={index}
                                className={classes.filterTag}
                                clickable={false}
                                label={tag}
                                onClick={this.deleteFilterTag(tag)}
                                onDelete={this.deleteFilterTag(tag)}
                                color="primary"
                                variant="outlined" />
                            ))}
                          </MenuItem>
                        )}
                        <MenuItem className={classes.menuItem}>
                          <TextField
                            fullWidth
                            onChange={this.filterFilters}
                            placeholder="Search tags..."
                            InputProps={{
                              disableUnderline: true,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon className={classes.searchIcon} />
                                </InputAdornment>
                              )
                            }} />
                        </MenuItem>
                        {uniqueTags
                          .filter((tag) => {
                            // (don't include tags that are already selected)
                            if (filterBy.indexOf(tag) === -1) {
                              // if there is a filter-search set, match to that search
                              if (this.state.filterFilter) {
                                return tag.toLowerCase().indexOf(this.state.filterFilter.toLowerCase()) !== -1;
                              }
                              // otherwise, can return all
                              return true;
                            }
                            return false;
                          })
                          .map((tag, index) => (
                            <MenuItem
                              key={index}
                              onClick={this.addFilterTag(tag)}
                              className={classes.menuItem}>
                              {tag}
                            </MenuItem>
                          ))
                        }
                      </Menu>
                    </div>
                  </React.Fragment>
                )}>
                <div className={classes.outerContainer}>
                  {(isCategoryView && !isListView && !isCardView)
                    && (
                      <>
                        {filteredApps.filter((app) => app.pinned === true).length > 0 && (
                          <CategoryApps 
                            title={'My Apps'}
                            alphabeticalSorted={alphabeticalSorted}
                            updateSort={this.updateSort}
                            filteredApps={filteredApps.filter((app) => app.pinned === true)}
                            apps={apps}
                            handleMouseEnter={this.handleMouseEnterCreate}
                            handleMouseLeave={this.handleMouseLeaveCreate}
                            inHoverState={inHoverStateCreate} />
                        )}
                        {filteredApps.filter((app) => app.featured === true).length > 0 && (
                          <CategoryApps 
                            title={'Featured'}
                            alphabeticalSorted={alphabeticalSorted}
                            updateSort={this.updateSort}
                            filteredApps={filteredApps.filter((app) => app.featured === true) || []}
                            apps={apps}
                            handleMouseEnter={this.handleMouseEnterCreate}
                            handleMouseLeave={this.handleMouseLeaveCreate}
                            inHoverState={inHoverStateCreate} />
                        )}
                        {CATEGORIES.map((category, index) =>
                          (filteredApps.filter((app) => 
                            Array.isArray(app.category) && 
                            app.category.includes(category)).length > 0) &&
                             (
                               <CategoryApps
                                 key={index}
                                 alphabeticalSorted={alphabeticalSorted}
                                 updateSort={this.updateSort}
                                 title={category}
                                 filteredApps={filteredApps.filter((app) => Array.isArray(app.category) 
                                  && app.category.includes(category))}
                                 apps={apps}
                                 handleMouseEnter={this.handleMouseEnterCreate}
                                 handleMouseLeave={this.handleMouseLeaveCreate}
                                 inHoverState={inHoverStateCreate} />
                             )
                        )}
                      </>
                    )
                  }
                  {(isCardView && !isListView && !isCategoryView)
                    && (
                      <CardView
                        addApp={true}
                        filteredApps={filteredApps}
                        handleMouseEnter={this.handleMouseEnterCreate}
                        handleMouseLeave={this.handleMouseLeaveCreate}
                        inHoverState={inHoverStateCreate}
                        view={isCardView}
                        apps={apps} />
                    )
                  }
                  {(isListView && !isCardView && !isCategoryView)
                    && (
                      <ListView
                        updateSort={this.updateSort}
                        sortBy={this.props.sortBy} 
                        alphabeticalSorted={alphabeticalSorted} 
                        featureSorted={featureSorted}
                        pinnedSorted={pinnedSorted} 
                        filteredApps={filteredApps}
                        apps={apps} />
                    )}
                </div>
              </DefaultPageLayout>
            );
          }
          return (
            <Typography variant="h5">
              You are not logged in! Please login to continue.
            </Typography>
          );
        }}
      </AuthConsumer>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Launcher);

