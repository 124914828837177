import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing.unit * 2,
  },
  cancelButton: {
    marginRight: theme.spacing.unit * 2,
    fontWeight: 'bold',
    borderRadius: 5
  },
  submitButton: {
    backgroundColor: theme.palette.primary.main,
    fontWeight: 'bold',
    borderRadius: 5
  },
});

const ActionButtons = ({ classes, handleCancel }) => {
  return (
    <div className={classes.actionButtons}>
      {handleCancel && (
        <Button
          className={classes.cancelButton}
          color="secondary"
          onClick={handleCancel}
          size="large"
          variant="outlined">
          Cancel
        </Button>
      )}
      <Button
        className={classes.submitButton}
        color="primary"
        size="large"
        type="submit"
        variant="contained">
        Submit
      </Button>
    </div>
  );
};

ActionButtons.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCancel: PropTypes.func
};

export default withStyles(styles)(ActionButtons);
