import React from 'react';
import PropTypes from 'prop-types';
import FileIcon, { defaultStyles } from 'react-file-icon';

const Pdf = ({ size = 36 }) => (
  <FileIcon
    extension="pdf"
    {...defaultStyles.pdf}
    color="#d93831"
    foldColor="#b52620"
    glyphColor="rgba(255,255,255,0.5)"
    labelUppercase={true}
    size={size} />
);

Pdf.propTypes = {
  size: PropTypes.number
};

export default Pdf;

