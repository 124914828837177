import auth0 from 'auth0-js';
import get from 'lodash/get';
import api from '../services/myColliersApi';
import { history } from '../store';

class Auth {
  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.auth0 = new auth0.WebAuth({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
      domain: process.env.REACT_APP_AUTH0_DOMAIN,
      responseType: 'token id_token',
      scope: 'openid profile'
    });
  }

  login() {
    const next = `${window.location.pathname}${window.location.search}`;
    this.auth0.authorize({
      redirectUri: `${window.location.origin}/callback?next=${encodeURIComponent(next)}`
    });
  }

  getNext() {
    const storedNext = window.localStorage.getItem('next_url');
    if (storedNext) {
      window.localStorage.removeItem('next_url');
      try {
        const storedUrl = new URL(storedNext);
        return storedUrl.pathname;
      } catch (err) {
        return '/';
      }
    }
    const url = new URL(window.location.href);
    return url.searchParams.has('next') ? url.searchParams.get('next') : '/';
  }

  handleAuthentication(redirect) {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          return resolve(this.setSession(authResult));
        } else if (err) {
          //TODO logging
          if (/\[unverified\]/.test(err.errorDescription)) {
            if (redirect) {
              return reject({ redirect, type: 'verify' });
            }

            return reject({ type: 'verify' });
          }

          return reject(() => history.replace(this.getNext()));
        }
      });
    });
  }

  setSession(authResult) {
    // Set the time that the Access Token will expire at
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', get(authResult, 'idTokenPayload.exp', 0) * 1000);
    // bust permission cache
    return api.read('/users/refresh', null, { bustPermissionsCache: 1 }).then(() => {
      // navigate to the home route
      history.replace(this.getNext());
    });
  }

  logout() {
    // Clear Access Token and ID Token from local storage
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('project-room-token');
    localStorage.removeItem('view_as_client_id');
    window.location.href = `${process.env.REACT_APP_AUTH0_LOG_OUT_URL}${encodeURI(window.location.origin)}`;
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // ID Token's expiry time
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    if (expiresAt) {
      if (new Date().getTime() < expiresAt) {
        return true;
      }

      // if expiresAt is set but has passed, user is not fully logged out, so logout now
      localStorage.setItem('next_url', window.location.href);
      this.logout();
    }
    return false;
  }
}

export const auth = new Auth();
