import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createGroup } from '../groups/actions';
import CreateClientGroup from './CreateClientGroup';

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ createGroup }, dispatch);
};

export default connect(null, mapDispatchToProps)(CreateClientGroup);
