import React, { Component } from 'react';
import PropTypes from 'prop-types';
import trim from 'lodash/trim';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import NewFileIcon from '@material-ui/icons/InsertDriveFileOutlined';
import NewMessageIcon from '@material-ui/icons/ChatBubbleOutline';
import NewMemberIcon from '@material-ui/icons/PersonAddOutlined';
import NotificationIcon from '@material-ui/icons/NotificationsNoneRounded';
import RemoveIcon from '@material-ui/icons/Clear';
import { withRouter } from 'react-router-dom';

const styles = (theme) => ({
  dismissBtn: {
    display: 'none'
  },
  list: {
    maxWidth: '33vw',
    padding: 0,
    width: 600,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '50vw'
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '100%'
    }
  },
  listItemPrimary: {
    color: theme.palette.text.secondary,
    fontSize: '0.9rem',
    fontWeight: '600',
    wordBreak: 'break-word',
    [theme.breakpoints.down('xs')]: {
      lineHeight: 1.25,
    }
  },
  listItemSecondary: {
    fontSize: '0.813rem',
    lineHeight: 1.25,
    wordBreak: 'break-word'
  },
  iconContainer: {
    color: theme.palette.primary.light,
    marginRight: 0
  },
  menuLink: {
    display: 'block',
    width: '100%'
  },
  menuLinkLabel: {
    color: theme.palette.primary.dark,
    textDecoration: 'underline',
    textTransform: 'uppercase',
    fontSize:'70%',
  },
  menuItem: {
    borderBottom: '1px solid #e7e7e7',
    cursor: 'pointer',
    '&:last-child': {
      borderBottom: '0'
    },
    '&:hover': {
      background: '#f7f7f7',
      '& $dismissBtn': {
        display: 'initial'
      },
      '& $timestamp': {
        display: 'none'
      }
    }
  },
  menuPaper: {
    maxHeight: 'calc(75vh - 100px)',
    overflow: 'auto',
    [theme.breakpoints.down('xs')]: {
      maxHeight: `calc(100vh - ${theme.xsToolbarHeight + 10}px)`,
    },
    '& a': {
      color: 'inherit',
      textDecoration: 'none',
      '&:focus': {
        outline: 'none'
      }
    }
  },
  navMenu: {
    marginTop: 22,
    minWidth: 275,
    zIndex: 1000,
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
      minWidth: 0,
      width:'97vw'
    }
  },
  noNotifsMsg: {
    fontStyle: 'italic',
    padding: 2 * theme.spacing.unit,
    textAlign: 'center'
  },
  notificationBadge: {
    right: 35,
    top: 5
  },
  timestamp: {
    color: theme.palette.text.secondary,
    right: theme.spacing.unit,
    top: 10,
    transform: 'none',
  },
  userNotifications: {
    fontSize: 40,
    marginRight: 1.5 * theme.spacing.unit,
    padding: 0,
    [theme.breakpoints.down('xs')]: {
      color: 'white'
    }
  }
});

class Notifications extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    dismissNotification: PropTypes.func.isRequired,
    fetchNotifications: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    notifications: PropTypes.array,
  }
  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null
    };
    this.poll = null;
  }

  componentWillMount() {
    this.props.fetchNotifications();
    if (!this.poll) {
      this.poll = setInterval(this.props.fetchNotifications, 60e3);
    }
  }

  componentDidUpdate(prevProps) {
    // if go between zero and nonzero notifications, close and re-open menu so repositions
    if ((prevProps.notifications.length === 0) !== (this.props.notifications.length === 0)) {
      const target = this.state.anchorEl;
      this.setState({ anchorEl: null }, () => {
        this.setState({ anchorEl: target });
      });
    }
  }

  componentWillUnmount() {
    if (this.poll) {
      clearInterval(this.poll);
      this.poll = null;
    }
  }

  handleMenuClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleMenuClose = () => {
    // setTimeout is for if "clicks away" on menu button
    setTimeout(() => {
      this.setState({ anchorEl: null });
    }, 0);
  }

  onDismissNotification = (notification) => () => {
    this.props.dismissNotification(notification._id);
  }

  onClickNotification = (notification) => () => {
    this.onDismissNotification(notification)();
    this.handleMenuClose();
    if (notification.url) {
      this.context.router.history.push(notification.url);
    }
  }

  render() {
    const { classes, notifications } = this.props;
    const startOfToday = moment().startOf('day');
    return (
      <React.Fragment>
        <IconButton
          className={classes.userNotifications}
          onClick={this.handleMenuClick}>
          <Badge
            badgeContent={notifications ? notifications.length : 0}
            classes={{ badge: classes.notificationBadge }}
            color="error"
            max={99}>
            <NotificationIcon fontSize="inherit" />
          </Badge>
        </IconButton>
        <Popper
          anchorEl={this.state.anchorEl}
          disablePortal
          id="notifications-menu"
          className={classes.navMenu}
          open={Boolean(this.state.anchorEl)}
          placement="bottom-end"
          transition>
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{ transformOrigin: 'center top' }} >
              <Paper className={classes.menuPaper}>
                <ClickAwayListener onClickAway={this.handleMenuClose}>
                  <React.Fragment>
                    {notifications.length === 0 && (
                      <Typography className={classes.noNotifsMsg}>
                        You have no new notifications.
                      </Typography>
                    )}
                    {notifications.length > 0 && (
                      <List className={classes.list}>
                        {notifications
                          .sort((a, b) => a.createdAt < b.createdAt ? 1 : -1)
                          .map((notification) => {
                          // if notification has title / body, use that; else, create from action type template
                            if (!notification.title) {
                              const data = notification.data || {};
                              switch (notification.action) {
                                case 'prUnread':
                                  notification.icon = <NewMessageIcon />;
                                  notification.title = (data.tally > 1)
                                    ? `${data.tally}+ new messages in ${data.channelName}`
                                    : `New message in ${data.channelName}`;
                                  notification.body = (data.firstUnreadMessageBody || '').length > 120
                                    ? `${trim(data.firstUnreadMessageBody.substr(0, 120))}...`
                                    : data.firstUnreadMessageBody;
                                  notification.url = `/projectRoom/${data.channelSid}`;
                                  break;
                                case 'prInvite':
                                  notification.icon = <NewMemberIcon />;
                                  notification.title = `You were added to ${data.channelName}`;
                                  notification.body = `${data.inviterName} invited you to join the project.`;
                                  notification.url = `/projectRoom/${data.channelSid}`;
                                  break;
                                case 'prNewMember':
                                  notification.icon = <NewMemberIcon />;
                                  notification.title = `${data.inviteeName} was added to ${data.channelName}`;
                                  notification.body = `${data.inviterName} invited them to join the project.`;
                                  notification.url = `/projectRoom/${data.channelSid}`;
                                  break;
                                case 'prFile':
                                  notification.icon = <NewFileIcon />;
                                  notification.title = `${data.fileName} was shared`;
                                  // eslint-disable-next-line max-len
                                  notification.body = `Document shared with ${data.channelName} team by ${data.sharerName}`;
                                  notification.url = `/projectRoom/${data.channelSid}`;
                                  break;
                                default:
                                  break;
                              }
                            }
                            const timestamp = new Date(notification.createdAt);
                            const prettyTime = moment(timestamp).format(
                              timestamp > startOfToday ? 'h:mma' : 'M/D/YY'
                            );
                            return (
                              <ListItem
                                key={notification._id}
                                onClick={this.onClickNotification(notification)}
                                classes={{ container: classes.menuItem }}>
                                <ListItemIcon className={classes.iconContainer}>
                                  {notification.icon || <NotificationIcon />}
                                </ListItemIcon>
                                <ListItemText
                                  classes={{
                                    primary: classes.listItemPrimary,
                                    secondary: classes.listItemSecondary
                                  }}
                                  primary={notification.title}
                                  secondary={notification.body} />
                                <ListItemSecondaryAction className={classes.timestamp}>
                                  <Typography variant="caption">{prettyTime}</Typography>
                                </ListItemSecondaryAction>
                                <ListItemSecondaryAction className={classes.dismissBtn}>
                                  <Tooltip title="Dismiss" placement="left">
                                    <IconButton onClick={this.onDismissNotification(notification)}>
                                      <RemoveIcon />
                                    </IconButton>
                                  </Tooltip>
                                </ListItemSecondaryAction>
                              </ListItem>
                            );
                          })}
                      </List>
                    )}
                  </React.Fragment>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(withRouter(Notifications));
