import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { AuthConsumer } from 'react-check-auth';
import curry from 'lodash/curry';
import memoize from 'lodash/memoize';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import FullScreenLoader from '../shared/FullScreenLoader';
import LoggedOut from '../shared/LoggedOut';
import Subheader from '../shared/subheader/ConnectedSubheader';
import EditableAvatar from './EditableAvatar';
import EditableField from './EditableField';
import MultiField from './multiField/MultiField';

const styles = (theme) => ({
  avatar: {
    minHeight: 175,
    minWidth: 175,
    height: 175,
    maxHeight: 175,
    marginTop: -40,
    width: 175,
    marginBottom: 20,
  },
  divider: {
    margin: theme.spacing.unit * 4,
    width: '100%'
  },
  fieldDivider: {
    backgroundColor: theme.palette.primary.dark,
    borderRadius: '50%',
    height: 5,
    margin: [theme.spacing.unit, theme.spacing.unit * 5],
    width: 5
  },
  name: {
    color: theme.palette.common.black
  },
  titleGroup: {
    alignItems: 'center',
    display: 'flex',
    marginTop: theme.spacing.unit
  },
  userData: {
    marginBottom: theme.spacing.unit * 4
  },
  userDataTall: {
    minHeight: 220
  },
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%'
  }
});

class Profile extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    fetchUserProfile: PropTypes.func.isRequired,
    hasError: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool.isRequired,
    updateUserProfile: PropTypes.func.isRequired,
    user: PropTypes.object
  };

  componentDidMount() {
    this.props.fetchUserProfile();
  }

  handleAvatarUpdate = async (image) => {
    let photo = image;
    if (Array.isArray(image) && image.length) {
      photo = image[0];
    }

    await this.props.updateUserProfile({ photo });
    if (this.refreshAuth) this.refreshAuth();
  }

  handleFieldUpdate = memoize(curry((key, value) => {
    this.props.updateUserProfile({ [key]: value });
  }));

  render() {
    const { classes, hasError, isFetching, user } = this.props;
    if (hasError) {
      return <LoggedOut />;
    }
    if (isFetching) {
      return <FullScreenLoader />;
    }
    if (user) {
      // (to squash the controlled/uncontrolled element error on material-ui textfields)
      const userKeys = Object.keys(user);
      for (const k of ['name','company','title','location','phone']) {
        if (userKeys.indexOf(k) === -1) {
          user[k] = '';
        }
      }

      return (
        <AuthConsumer>
          {({ refreshAuth, userInfo }) => {
            const canEdit = userInfo && this.props.user && userInfo.id === this.props.user.id;
            this.refreshAuth = refreshAuth;

            return (
              <div className={classes.wrapper}>
                <Subheader />
                <EditableAvatar
                  className={classes.avatar}
                  editable={canEdit}
                  onUpdate={this.handleAvatarUpdate}
                  src={user.photo} />
                <EditableField
                  className={classes.name}
                  onChange={this.handleFieldUpdate('name')}
                  editable={canEdit}
                  placeholder="Name"
                  value={user.name}
                  variant="h4" />

                <Grid container>
                  <Grid
                    className={classes.userData}
                    item
                    sm={6}
                    xs={12}>
                    <EditableField
                      editable={canEdit}
                      onChange={this.handleFieldUpdate('title')}
                      placeholder="Title"
                      value={user.title}
                      variant="subtitle1" />
                  </Grid>
                  <Grid
                    className={classes.userData}
                    item
                    sm={6}
                    xs={12}>
                    <EditableField
                      editable={canEdit}
                      onChange={this.handleFieldUpdate('company')}
                      placeholder="Company"
                      value={user.company}
                      variant="subtitle1" />
                  </Grid>
                </Grid>
                <Divider className={classes.divider} />
                <Grid container>
                  <Grid
                    className={classes.userData}
                    item
                    sm={6}
                    xs={12}>
                    <EditableField
                      editable={canEdit}
                      label="Location"
                      onChange={this.handleFieldUpdate('location')}
                      placeholder="Location"
                      value={user.location} />
                  </Grid>
                  <Grid
                    className={classes.userData}
                    item
                    sm={6}
                    xs={12}>
                    <EditableField
                      editable={canEdit}
                      label="Phone"
                      onChange={this.handleFieldUpdate('phone')}
                      placeholder="Phone"
                      value={user.phone} />
                  </Grid>
                  <Grid
                    className={classes.userData}
                    item
                    sm={6}
                    xs={12}>
                    <MultiField
                      label="Industry Roles"
                      onChange={this.handleFieldUpdate('industryRoles')}
                      options={[
                        'Broker',
                        'Consultant',
                        'Developer',
                        'Investor',
                        'Tenant',
                        'Other'
                      ]}
                      value={user.industryRoles} />
                  </Grid>
                  <Grid
                    className={classNames(classes.userData, classes.userDataTall)}
                    item
                    sm={6}
                    xs={12}>
                    <MultiField
                      label="Asset Classes"
                      onChange={this.handleFieldUpdate('assetClasses')}
                      options={[
                        'Hospitality',
                        'Industrial',
                        'Institutional',
                        'Land',
                        'Multifamily',
                        'Office',
                        'Retail',
                        'Other'
                      ]}
                      value={user.assetClasses} />
                  </Grid>
                </Grid>
              </div>
            );
          }}
        </AuthConsumer>
      );
    }

    return <FullScreenLoader />;
  }
}

export default withStyles(styles)(Profile);
