import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AuthenticatedApp from './AuthenticatedApp';
import * as actions from './actions';

const mapStateToProps = ({ app, auth }) => {
  return {
    hasError: false,
    isFetching: true,
    permissionsFetched: app.permissionsFetched,
    splashDismissed: app.splashDismissed,
    ...auth
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(actions, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticatedApp);
