import {
  APP_READ_REQUEST,
  APP_READ_SUCCESS,
  APP_READ_ERROR,
  APP_CLEAR,
  USER_APP_PIN_SUCCESS,
  USER_APP_UNPIN_SUCCESS
} from '../constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case APP_CLEAR:
      return {
        ...state,
        isFetching: true,
        app: {}
      };
    case APP_READ_REQUEST:
      return {
        ...state,
        isFetching: true,
        hasError: false
      };
    case APP_READ_SUCCESS:
      return {
        ...state,
        isFetching: false,
        hasError: false,
        app: action.response
      };
    case APP_READ_ERROR:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        error: action.response
      };
    
    case USER_APP_PIN_SUCCESS:
      return {
        ...state,
        app: {
          ...state.app,
          pin: action.response
        }
      };

    case USER_APP_UNPIN_SUCCESS:
      return {
        ...state,
        app: {
          ...state.app,
          pin: null
        }
      };

    default:
      return state;
  }
};
