import React from 'react';
import PropTypes from 'prop-types';

class Permissions extends React.Component {
  static propTypes = {
    fetchUserPermissions: PropTypes.func.isRequired
  }

  componentDidMount() {
    this.props.fetchUserPermissions();
  }

  render() {
    return <React.Fragment />;
  }
}

export default Permissions;
