import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ability } from './config/ability';

export const Landing = (props, context) => {
  if (ability.can('read', 'apps', ability.defaultTenantId) && props.groupConfig.launcher) {
    context.router.history.push('/launcher');
  } else if (ability.can('read', 'projectRooms', ability.defaultTenantId) && props.groupConfig.projectRooms) {
    context.router.history.push('/projectRoom');
  } else {
    context.router.history.push('/support');
  }
  return '';
};
Landing.contextTypes = {
  router: PropTypes.object
};

export default connect(
  ({ app }) => ({ groupConfig: app.groupConfig })
)(Landing);
