export const CATEGORIES = [
  'Tools for Business', 
  'Listing Services', 
  'GIS / Mapping', 
  'Marketing Platform',
  'Research Tools', 
  'Data Services',
  'Employee Tools', 
  'Other'
];
