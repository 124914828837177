import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgComponent = (props) => (
  <SvgIcon
    viewBox="0 0 44 44" role="img" aria-labelledby="title"
    {...props}>
    <title id="title">{props.title || 'Applications icon'}</title>
    <g fill="none" fillRule="evenodd">
      <circle cx={22} cy={22} r={22} />
      <g opacity={0.5} transform="translate(7 7)" strokeWidth={2.5}>
        <circle
          stroke="#FFF"
          cx={4}
          cy={4}
          r={4} />
        <rect
          stroke="#FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          x={12}
          y={2}
          width={18}
          height={4}
          rx={1.485} />
        <circle
          stroke="#8EDAFF"
          cx={4}
          cy={15}
          r={4} />
        <rect
          stroke="#8EDAFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          x={12}
          y={13}
          width={18}
          height={4}
          rx={1.485} />
        <circle
          stroke="#FFF"
          cx={4}
          cy={26}
          r={4} />
        <rect
          stroke="#FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          x={12}
          y={24}
          width={18}
          height={4}
          rx={1.485} />
      </g>
    </g>
  </SvgIcon>
);

SvgComponent.propTypes = {
  title: PropTypes.string,
};

export default SvgComponent;
