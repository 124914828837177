import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { pinUserApp, unpinUserApp } from '../shared/actions';
import App from './AppListItem';

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ pinUserApp, unpinUserApp }, dispatch);
};

export default connect(null, mapDispatchToProps)(App);
