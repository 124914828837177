import api from '../services/myColliersApi';
import {
  USER_PROFILE_READ_REQUEST,
  USER_PROFILE_READ_SUCCESS,
  USER_PROFILE_READ_ERROR,
  USER_PROFILE_UPDATE_REQUEST,
  USER_PROFILE_UPDATE_SUCCESS,
  USER_PROFILE_UPDATE_ERROR,
} from '../constants/actionTypes';

function userProfileRequest() {
  return {
    type: USER_PROFILE_READ_REQUEST
  };
}

function userProfileSuccess(response) {
  return {
    type: USER_PROFILE_READ_SUCCESS,
    response
  };
}

function userProfileError(error) {
  return {
    type: USER_PROFILE_READ_ERROR,
    error
  };
}

export function fetchUserProfile() {
  return (dispatch) => {
    dispatch(userProfileRequest());
    return api.read('users/profile')
      .then((data) => dispatch(userProfileSuccess(data)))
      .catch((response) => {
        return dispatch(userProfileError(response));
      });
  };
}

function userProfileUpdateRequest(data) {
  return {
    type: USER_PROFILE_UPDATE_REQUEST,
    data
  };
}

function userProfileUpdateSuccess(response) {
  return {
    type: USER_PROFILE_UPDATE_SUCCESS,
    response
  };
}

function userProfileUpdateError(error) {
  return {
    type: USER_PROFILE_UPDATE_ERROR,
    error
  };
}

export function updateUserProfile(data) {
  return (dispatch) => {
    dispatch(userProfileUpdateRequest(data));
    return api.update('users/profile', data)
      .then((data) => dispatch(userProfileUpdateSuccess(data)))
      .catch((response) => {
        return dispatch(userProfileUpdateError(response));
      });
  };
}
