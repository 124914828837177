import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ImageUploader from '../shared/ImageUploader';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
});

const LogoModal = ({ classes, group, updateGroup }) => {
  const [open, setOpen] = useState(false);

  const handleUpdate = (results) => {
    if (results && results.length) {
      const originalImage = results[0];
      let smallImage = originalImage;
      if (/^https:\/\/res\.cloudinary\.com\/.+\/image\/upload\/.*$/.test(originalImage)) {
        const delim = '/image/upload/';
        const smallImageParts = originalImage.split(delim, 2);
        smallImageParts[1] = `w_40,h_40,c_fit/${smallImageParts[1]}`;
        smallImage = smallImageParts.join(delim);
      }

      // update group
      updateGroup({
        id: group._id,
        logoUrl: originalImage,
        iconUrl: smallImage
      });
    }
  };

  const actionText = `${group.logoUrl ? 'Edit' : 'Add'} ${group.isClient ? 'Client Logo' : 'Thumbnail'}`;

  return (
    <div className={classes.container}>
      <Button
        onClick={() => setOpen(true)}
        size="large"
        style={{ marginLeft: 4 }}
        variant="outlined">
        {actionText}
      </Button>
      <ImageUploader
        allowNull
        onClose={() => setOpen(false)}
        onUpdate={handleUpdate}
        maxFiles={1}
        preset={process.env.REACT_APP_CLOUDINARY_PRESET}
        show={open}
        title={actionText} />
    </div>
  );
};

LogoModal.propTypes = {
  classes: PropTypes.object.isRequired,
  group: PropTypes.object.isRequired,
  updateGroup: PropTypes.func.isRequired,
};

export default withStyles(styles)(LogoModal);
