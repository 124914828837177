export const APP_READ_REQUEST = 'APP_READ_REQUEST';
export const APP_READ_SUCCESS = 'APP_READ_SUCCESS';
export const APP_READ_ERROR = 'APP_READ_ERROR';

export const APP_CREATE_REQUEST = 'APP_CREATE_REQUEST';
export const APP_CREATE_SUCCESS = 'APP_CREATE_SUCCESS';
export const APP_CREATE_ERROR = 'APP_CREATE_ERROR';

export const APP_UPDATE_REQUEST = 'APP_UPDATE_REQUEST';
export const APP_UPDATE_SUCCESS = 'APP_UPDATE_SUCCESS';
export const APP_UPDATE_ERROR = 'APP_UPDATE_ERROR';

export const APPS_READ_REQUEST = 'APPS_READ_REQUEST';
export const APPS_READ_SUCCESS = 'APPS_READ_SUCCESS';
export const APPS_READ_ERROR = 'APPS_READ_ERROR';

export const GROUPS_CREATE_REQUEST = 'GROUPS_CREATE_REQUEST';
export const GROUPS_CREATE_SUCCESS = 'GROUPS_CREATE_SUCCESS';
export const GROUPS_CREATE_ERROR = 'GROUPS_CREATE_ERROR';
export const GROUPS_CREATE_CLEAR_ERROR = 'GROUPS_CREATE_CLEAR_ERROR';

export const GROUPS_READ_REQUEST = 'GROUPS_READ_REQUEST';
export const GROUPS_READ_SUCCESS = 'GROUPS_READ_SUCCESS';
export const GROUPS_READ_ERROR = 'GROUPS_READ_ERROR';

export const GROUP_UPDATE_REQUEST = 'GROUP_UPDATE_REQUEST';
export const GROUP_UPDATE_SUCCESS = 'GROUP_UPDATE_SUCCESS';
export const GROUP_UPDATE_ERROR = 'GROUP_UPDATE_ERROR';

export const GROUP_DELETE_REQUEST = 'GROUP_DELETE_REQUEST';
export const GROUP_DELETE_SUCCESS = 'GROUP_DELETE_SUCCESS';
export const GROUP_DELETE_ERROR = 'GROUP_DELETE_ERROR';

export const DELETE_APP_REQUEST = 'DELETE_APP_REQUEST';
export const DELETE_APP_SUCCESS = 'DELETE_APP_SUCCESS';
export const DELETE_APP_ERROR = 'DELETE_APP_ERROR';

export const USER_APP_INTERACTION = 'USER_APP_INTERACTION';
export const USER_APP_INTERACTION_ERROR = 'USER_APP_INTERACTION_ERROR';

export const USER_APP_PIN_REQUEST = 'USER_APP_PIN_REQUEST';
export const USER_APP_PIN_SUCCESS = 'USER_APP_PIN_SUCCESS';
export const USER_APP_PIN_ERROR = 'USER_APP_PIN_ERROR';

export const USER_APP_UNPIN_REQUEST = 'USER_APP_UNPIN_REQUEST';
export const USER_APP_UNPIN_SUCCESS = 'USER_APP_UNPIN_SUCCESS';
export const USER_APP_UNPIN_ERROR = 'USER_APP_UNPIN_ERROR';

export const USER_APPS_READ_REQUEST = 'USER_APPS_READ_REQUEST';
export const USER_APPS_READ_SUCCESS = 'USER_APPS_READ_SUCCESS';
export const USER_APPS_READ_ERROR = 'USER_APPS_READ_ERROR';

export const USER_APPS_FILTER_ADD = 'USER_APPS_FILTER_ADD';
export const USER_APPS_FILTER_REMOVE = 'USER_APPS_FILTER_REMOVE';
export const USER_APPS_SORT_UPDATE = 'USER_APPS_SORT_UPDATE';

export const USER_APPS_RATINGS_REQUEST = 'USER_APPS_RATINGS_REQUEST';
export const USER_APPS_RATINGS_SUCCESS = 'USER_APPS_RATINGS_SUCCESS';
export const USER_APPS_RATINGS_ERROR = 'USER_APPS_RATINGS_ERROR';

export const USER_PERMISSIONS_READ_REQUEST = 'USER_PERMISSIONS_READ_REQUEST';
export const USER_PERMISSIONS_READ_SUCCESS = 'USER_PERMISSIONS_READ_SUCCESS';
export const USER_PERMISSIONS_READ_ERROR = 'USER_PERMISSIONS_READ_ERROR';

export const USER_NOTIFICATIONS_READ_REQUEST = 'USER_NOTIFICATIONS_READ_REQUEST';
export const USER_NOTIFICATIONS_READ_SUCCESS = 'USER_NOTIFICATIONS_READ_SUCCESS';
export const USER_NOTIFICATIONS_READ_ERROR = 'USER_NOTIFICATIONS_READ_ERROR';

export const USER_NOTIFICATIONS_DISMISS_REQUEST = 'USER_NOTIFICATIONS_DISMISS_REQUEST';
export const USER_NOTIFICATIONS_DISMISS_SUCCESS = 'USER_NOTIFICATIONS_DISMISS_SUCCESS';
export const USER_NOTIFICATIONS_DISMISS_ERROR = 'USER_NOTIFICATIONS_DISMISS_ERROR';

export const USER_PROFILE_READ_REQUEST = 'USER_PROFILE_READ_REQUEST';
export const USER_PROFILE_READ_SUCCESS = 'USER_PROFILE_READ_SUCCESS';
export const USER_PROFILE_READ_ERROR = 'USER_PROFILE_READ_ERROR';

export const USER_PROFILE_UPDATE_REQUEST = 'USER_PROFILE_UPDATE_REQUEST';
export const USER_PROFILE_UPDATE_SUCCESS = 'USER_PROFILE_UPDATE_SUCCESS';
export const USER_PROFILE_UPDATE_ERROR = 'USER_PROFILE_UPDATE_ERROR';

export const SET_VIEW_AS_CLIENT = 'SET_VIEW_AS_CLIENT';
export const SET_PAGE_TITLES = 'SET_PAGE_TITLES';
export const CLOSE_NAV_DRAWER = 'CLOSE_NAV_DRAWER';
export const OPEN_NAV_DRAWER = 'OPEN_NAV_DRAWER';
export const TOGGLE_NAV_DRAWER = 'TOGGLE_NAV_DRAWER';

export const DASHBOARD_READ_REQUEST = 'DASHBOARD_READ_REQUEST';
export const DASHBOARD_READ_SUCCESS = 'DASHBOARD_READ_SUCCESS';
export const DASHBOARD_READ_ERROR = 'DASHBOARD_READ_ERROR';

export const DASHBOARD_REGISTER_REQUEST = 'DASHBOARD_REGISTER_REQUEST';
export const DASHBOARD_REGISTER_SUCCESS = 'DASHBOARD_REGISTER_SUCCESS';
export const DASHBOARD_REGISTER_ERROR = 'DASHBOARD_REGISTER_ERROR';

export const DASHBOARD_SET_QLIK_AUTH = 'DASHBOARD_SET_QLIK_AUTH';

export const APP_RATING_READ_REQUEST = 'APP_RATING_READ_REQUEST';
export const APP_RATING_READ_SUCCESS = 'APP_RATING_READ_SUCCESS';
export const APP_RATING_READ_ERROR = 'APP_RATING_READ_ERROR';

export const APP_RATING_WRITE_REQUEST = 'APP_RATING_WRITE_REQUEST';
export const APP_RATING_WRITE_SUCCESS = 'APP_RATING_WRITE_SUCCESS';
export const APP_RATING_WRITE_ERROR = 'APP_RATING_WRITE_ERROR';

export const APP_AVG_RATING_READ_REQUEST = 'APP_AVG_RATING_READ_REQUEST';
export const APP_AVG_RATING_READ_SUCCESS = 'APP_AVG_RATING_READ_SUCCESS';
export const APP_AVG_RATING_READ_ERROR = 'APP_AVG_RATING_READ_ERROR';

export const APP_REVIEW_WRITE_REQUEST = 'APP_RATING_WRITE_REQUEST';
export const APP_REVIEW_WRITE_SUCCESS = 'APP_RATING_WRITE_SUCCESS';
export const APP_REVIEW_WRITE_ERROR = 'APP_RATING_WRITE_ERROR';

export const APP_REVIEWS_READ_REQUEST = 'APP_REVIEWS_READ_REQUEST';
export const APP_REVIEWS_READ_SUCCESS = 'APP_REVIEWS_READ_SUCCESS';
export const APP_REVIEWS_READ_ERROR = 'APP_REVIEWS_READ_ERROR';

export const APP_REVIEWS_CLEAR = 'APP_REVIEWS_CLEAR';

export const APP_CLEAR = 'APP_CLEAR';

export const LEASES_READ_REQUEST = 'LEASES_READ_REQUEST';
export const LEASES_READ_SUCCESS = 'LEASES_READ_SUCCESS';
export const LEASES_READ_ERROR = 'LEASES_READ_ERROR';

export const LEASES_DELETE_REQUEST = 'LEASES_DELETE_REQUEST';
export const LEASES_DELETE_SUCCESS = 'LEASES_DELETE_SUCCESS';
export const LEASES_DELETE_ERROR = 'LEASES_DELETE_ERROR';

export const LEASES_SET_SELECTED = 'LEASES_SET_SELECTED';
export const LEASES_SET_RECORDS_PER_PAGE = 'LEASES_SET_RECORDS_PER_PAGE';
export const LEASES_SET_CURRENT_PAGE = 'LEASES_SET_CURRENT_PAGE';

export const LEASE_DETAIL_READ_REQUEST = 'LEASE_DETAIL_READ_REQUEST';
export const LEASE_DETAIL_READ_SUCCESS = 'LEASE_DETAIL_READ_SUCCESS';
export const LEASE_DETAIL_READ_ERROR = 'LEASE_DETAIL_READ_ERROR';

export const LEASE_UPLOAD_REQUEST = 'LEASE_UPLOAD_REQUEST';
export const LEASE_UPLOAD_SUCCESS = 'LEASE_UPLOAD_SUCCESS';
export const LEASE_UPLOAD_ERROR = 'LEASE_UPLOAD_ERROR';
export const LEASE_UPLOAD_VALIDATION_ERROR = 'LEASE_UPLOAD_VALIDATION_ERROR';

export const LEASES_DOWNLOAD_TEMPLATE_REQUEST = 'LEASES_DOWNLOAD_TEMPLATE_REQUEST';
export const LEASES_DOWNLOAD_TEMPLATE_SUCCESS = 'LEASES_DOWNLOAD_TEMPLATE_REQUEST';
export const LEASES_DOWNLOAD_TEMPLATE_ERROR = 'LEASES_DOWNLOAD_TEMPLATE_REQUEST';

export const DISMISS_SPLASH_PAGE = 'DISMISS_SPLASH_PAGE';

export const PROJECT_ROOM_INVITE_REQUEST = 'PROJECT_ROOM_INVITE_REQUEST';
export const PROJECT_ROOM_INVITE_SUCCESS = 'PROJECT_ROOM_INVITE_SUCCESS';
export const PROJECT_ROOM_INVITE_ERROR = 'PROJECT_ROOM_INVITE_ERROR';
