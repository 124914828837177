import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import appReducer from './app/reducer';
import appsReducer from './apps/reducer';
import authReducer from './auth/reducer';
import dashboardReducer from './dashboard/reducer';
import groupsReducer from './groups/reducer';
import launcherReducer from './launcher/reducer';
import leaseReducer from './leases/reducer';
import navDrawerReducer from './navDrawer/reducer';
import profileReducer from './profile/reducer';
import projectRoomReducer from './projectRoom/reducer';
import ratingReducer from './shared/rating/reducer';
import reviewReducer from './apps/reviews/reducer';

export default combineReducers({
  app: appReducer,
  apps: appsReducer,
  auth: authReducer,
  dashboard: dashboardReducer,
  form: formReducer,
  groups: groupsReducer,
  launcher: launcherReducer,
  leases: leaseReducer,
  navDrawer: navDrawerReducer,
  profile: profileReducer,
  projectRoom: projectRoomReducer,
  rating: ratingReducer,
  reviews: reviewReducer,
});
