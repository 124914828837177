import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  mainContainer: {},
  noGutters: {},
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100vh - 86px)',
    padding: [theme.spacing.unit * 3, theme.spacing.unit * 6],
    paddingRight: theme.spacing.unit * 4,
    '&$noGutters': {
      paddingLeft: 0,
      paddingRight: 0
    },

    [theme.breakpoints.down('xs')]: {
      padding: '40px',
    },

    '&$xsNoPadding': {
      [theme.breakpoints.down('xs')]: {
        padding: 0
      }
    }
  },
  titleContainer: {
    alignItems: 'center',
    display: 'flex',
    height: 48,
    justifyContent: 'flex-start',
    marginBottom: theme.spacing.unit * 3,
    padding: `0 ${theme.spacing.unit * 0.5}px`,
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
      height: 'auto',
      marginBottom: 0,
      padding: 0
    },
    '&$xsHideTitle': {
      [theme.breakpoints.down('xs')]: {
        alignContent: 'flex-end',
        alignItems: 'stretch',
        flexDirection: 'column-reverse',
      },
      '& $title': {
        [theme.breakpoints.down('xs')]: { display: 'none' }
      }
    }
  },
  spacer: {
    flex: 1
  },
  title: {
    color: theme.palette.text.secondary,
    fontFamily: theme.typography.fontFamily,
    fontSize: '2.5rem',
    fontWeight: 600,

    [theme.breakpoints.down('xs')]: {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      fontSize: '1.5rem',
      padding: [theme.spacing.unit * 1.5, theme.spacing.unit],
      textAlign: 'center',
      width: '100vw',
    },
  },
  xsHideTitle: {},
  xsNoPadding: {},
});

const DefaultPageLayout = ({
  children,
  classes,
  noGutters,
  title,
  titleActions,
  titleActionsLeft,
  xsNoPadding,
  xsShowTitle,
}) => (
  <div
    className={cx({
      [classes.pageContainer]: true,
      [classes.noGutters]: noGutters,
      [classes.xsNoPadding]: xsNoPadding
    })}>
    <div
      className={cx({
        [classes.titleContainer]: true,
        [classes.xsHideTitle]: !xsShowTitle
      })}>
      {title && (
        <Typography variant="h3" className={classes.title}>
          {title}
        </Typography>
      )}
      {titleActionsLeft}
      <div className={classes.spacer} />
      {titleActions}
    </div>
    <div className={classes.mainContainer}>
      {children}
    </div>
  </div>
);

DefaultPageLayout.propTypes = {
  children: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  noGutters: PropTypes.bool,
  title: PropTypes.string,
  titleActions: PropTypes.object,
  titleActionsLeft: PropTypes.object,
  xsNoPadding: PropTypes.bool,
  xsShowTitle: PropTypes.bool,
};

export default withStyles(styles)(DefaultPageLayout);
