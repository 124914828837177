import { createStore, applyMiddleware, compose } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './rootReducer';

export const history = createBrowserHistory();

const enhancers = compose(
  window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f
);

const createStoreWithMiddleware = applyMiddleware(routerMiddleware(history), thunkMiddleware)(createStore);
const store = createStoreWithMiddleware(connectRouter(history)(rootReducer), {}, enhancers);

export default store;

