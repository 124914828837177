import React from 'react';
import PropTypes from 'prop-types';
import kebabCase from 'lodash/kebabCase';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import FieldControls from '../FieldControls';
import SelectField from './SelectField';

const styles = (theme) => ({
  chip: {
    margin: '2px'
  },
  chipsWrapper: {
    alignItems: 'center',
    display: 'flex'
  },
  fieldLabel: {
    color: theme.palette.primary.main,
    fontSize: '0.75em',
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  fieldValue: {
    borderBottom: '1px solid black',
    cursor: 'pointer'
  },
  fieldWrapper: {
    padding: theme.spacing.unit,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 42
  },
  inputWrapper: {
    alignItems: 'center',
    display: 'flex'
  }
});

class MultiField extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    icon: PropTypes.node,
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    value: PropTypes.array.isRequired,
    variant: PropTypes.string
  }

  static defaultProps = {
    value: [],
    variant: 'subtitle1'
  }

  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      value: this.props.value
    };
  }

  handleCancel = () => {
    this.setState({ isEditing: false, value: this.props.value });
  }

  handleConfirm = () => {
    this.setState({ isEditing: false });
    this.props.onChange(this.state.value);
  }

  handleChange = (values) => {
    this.setState({ value: values.map((v) => v.value) });
  }

  handleClick = () => {
    this.setState({ isEditing: true });
  }

  render() {
    const { classes, label, variant } = this.props;
    let fieldComponent = (
      <div className={classes.chipsWrapper}>
        <div onClick={this.handleClick}>
          {this.state.value.map((val) => (
            <Chip
              className={classes.chip}
              color="primary"
              key={`${kebabCase(label)}-${val.toLowerCase()}`}
              label={val} />
          ))}
        </div>
        <IconButton onClick={this.handleClick}><AddIcon /></IconButton>
      </div>
    );

    if (this.state.isEditing) {
      fieldComponent = (
        <div className={classes.inputWrapper}>
          <SelectField
            label={label}
            onChange={this.handleChange}
            options={this.props.options}
            value={this.state.value.map((v) => ({ label: v, value: v }))} />
          <FieldControls
            handleCancel={this.handleCancel}
            handleConfirm={this.handleConfirm} />
        </div>
      );
    }

    return (
      <div className={classes.fieldWrapper}>
        {this.props.icon}
        {label && <Typography className={classes.fieldLabel} variant={variant}>{label}</Typography>}
        {fieldComponent}
      </div>
    );
  }
}

export default withStyles(styles)(MultiField);
