import {
  USER_PERMISSIONS_READ_SUCCESS,
  USER_PERMISSIONS_READ_ERROR,
} from '../constants/actionTypes';

export default (state = { isFetching: true }, action) => {
  switch (action.type) {
    case USER_PERMISSIONS_READ_SUCCESS:
      return {
        ...state,
        hasError: false,
        isFetching: false,
        permissions: action.response
      };

    case USER_PERMISSIONS_READ_ERROR:
      return {
        ...state,
        error: action.response,
        hasError: true,
        isFetching: false
      };

    default:
      return state;
  }
};
