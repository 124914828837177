import {
  USER_PROFILE_READ_REQUEST,
  USER_PROFILE_READ_SUCCESS,
  USER_PROFILE_READ_ERROR,
  USER_PROFILE_UPDATE_REQUEST,
  USER_PROFILE_UPDATE_SUCCESS,
  USER_PROFILE_UPDATE_ERROR,
} from '../constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case USER_PROFILE_READ_REQUEST:
      return {
        ...state,
        isFetching: true,
        hasError: false
      };

    case USER_PROFILE_READ_SUCCESS:
      return {
        ...state,
        isFetching: false,
        hasError: false,
        user: action.response
      };

    case USER_PROFILE_READ_ERROR:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        error: action.response
      };

    case USER_PROFILE_UPDATE_REQUEST:
      return {
        ...state,
        isFetching: false,
        hasError: false,
        user: {
          ...state.user,
          ...action.data
        }
      };

    case USER_PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        hasError: false,
        user: action.response
      };

    case USER_PROFILE_UPDATE_ERROR:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        error: action.response
      };

    default:
      return state;
  }
};
