import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Create from './ConnectedCreate';
import Details from './ConnectedDetails';
import Edit from './ConnectedEdit';

const routes = () => (
  <Switch>
    <Route path="/apps/create" component={Create} />
    <Route path="/apps/edit/:slug" component={Edit} />
    <Route path="/apps/:slug" component={Details} />
  </Switch>
);

export default routes;
