import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogContent, Divider, Typography, withStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const styles = (theme) => ({
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing.unit * 2,
  },
  cancelButton: {
    marginRight: theme.spacing.unit * 2,
    fontWeight: 'bold',
    borderRadius: 5
  },
  dialog: {
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing.unit * 2
    }
  },
  dialogContent: {
    width: '400px',
    maxWidth: '100%'
  },
  dialogHeader: {
    display:'flex',
    justifyContent:'space-between',
    alignItems:'top',
  },
  dialogHeaderIcon: {
    color: theme.palette.secondary.light,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.7
    }
  },
  dialogTitle: {
    color: theme.palette.primary.dark,
    fontWeight: 'bold',
    lineHeight: 1
  },
  divider: {
    margin: [theme.spacing.unit * 1.5, 0],
    width: '100%'
  },
  submitButton: {
    backgroundColor: theme.palette.primary.main,
    fontWeight: 'bold',
    borderRadius: 5
  },
});

const ConfirmDialog = ({ body, cancelButtonText, classes, confirmButtonText, onCancel, onConfirm, title }) => {
  if (!confirmButtonText) {
    confirmButtonText = 'Ok';
  }
  if (!cancelButtonText) {
    cancelButtonText = 'Cancel';
  }
  if (!onCancel) {
    onCancel = () => { /* noop */ };
  }

  return (
    <Dialog open={true} PaperProps={{ className: classes.dialog }}>
      <DialogContent className={classes.dialogContent}>
        {title && <React.Fragment>
          <div className={classes.dialogHeader}>
            <div>
              <Typography variant="h5" className={classes.dialogTitle}>
                { title }
              </Typography>
            </div>
            <CloseIcon className={classes.dialogHeaderIcon} onClick={onCancel} />
          </div>
          <Divider className={classes.divider} />
        </React.Fragment>
        }

        <Typography variant="body1">{body}</Typography>

        <div className={classes.actionButtons}>
          <Button
            className={classes.cancelButton}
            color="secondary"
            onClick={onCancel}
            size="large"
            variant="outlined">
            {cancelButtonText}
          </Button>
          <Button
            className={classes.submitButton}
            color="primary"
            onClick={onConfirm}
            size="large"
            type="submit"
            variant="contained">
            {confirmButtonText}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  body: PropTypes.string.isRequired,
  cancelButtonText: PropTypes.string,
  classes: PropTypes.object.isRequired,
  confirmButtonText: PropTypes.string,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string,
};

export default withStyles(styles)(ConfirmDialog);
