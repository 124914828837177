import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgComponent = (props) => (
  <SvgIcon
    viewBox="0 0 44 44" role="img" aria-labelledby="title"
    {...props}>
    <title id="title">{props.title || 'Projects icon'}</title>
    <g fill="none" fillRule="evenodd">
      <circle cx={22} cy={22} r={22} />
      <g opacity={0.5} strokeWidth={2.5}>
        {/* eslint-disable-next-line */}
        <path d="M24.4 22.308s.336-4.8-3.697-6.922c-1.35-.71-3.127-2.055-1.766-3.652.758-.889 1.152-1.398 1.344-2.658 1.005-6.528-10.245-6.471-9.197.352.193 1.26.629 1.417 1.387 2.306 1.36 1.597-.417 2.942-1.766 3.652-4.035 2.121-3.698 6.922-3.698 6.922H24.4z" stroke="#FFF" />
        <path
          stroke="#8EDAFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M11.547 30.48l3.807-4.307 4.1 4.04z" />
        <path
          stroke="#FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M29.418 24.245H37V36h-7.582z" />
        <path
          d="M25.462 36h-6.09c-2.207 0-3.997-1.757-3.997-3.924V26.58"
          stroke="#8EDAFF" />
      </g>
    </g>
  </SvgIcon>
);

SvgComponent.propTypes = {
  title: PropTypes.string,
};

export default SvgComponent;
