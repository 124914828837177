import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Can from '../config/ability';
import Placeholder from './Placeholder';
import ConnectedApp from './ConnectedAppCard';

const styles = (theme) => ({
  launcherContainer: {
    display: 'flex',
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      width: '100%'
    },
    [theme.breakpoints.down('md')]: {
      margin: 0,
      width: '100%'
    },
    '& > div': {
      width: '315px',
      minWidth: '315px',
    }
  },
  addCard: {
    '&': {
      alignItems: 'center',
      borderRadius: '10px',
      color: theme.palette.grey[600],
      display: 'flex',
      height: 268,
      justifyContent: 'center',
      margin: 'auto',
    },
    '&:hover': {
      color: theme.palette.primary.light,
    }
  },
  addButtonIcon: {
    height: 90,
    width: 90
  },
});

class CardView extends Component {
  static propTypes = {
    addApp: PropTypes.bool.isRequired,
    apps: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
    filteredApps: PropTypes.array.isRequired,
    handleMouseEnter: PropTypes.func.isRequired,
    handleMouseLeave: PropTypes.func.isRequired,
    inHoverState: PropTypes.bool.isRequired,
    theme: PropTypes.object.isRequired,
    view: PropTypes.bool
  }

  render() {
    const {
      theme,
      classes,
      addApp,
      filteredApps,
      apps,
      handleMouseEnter,
      handleMouseLeave,
      inHoverState,
      view
    } = this.props;
    return (
      <Grid
        container
        spacing={theme.spacing.unit * 3}
        className={classes.launcherContainer}>
        {filteredApps.map((app, index) => <ConnectedApp {...app} key={index} />)}
        {view && filteredApps.length === 0 && apps.length > 0 &&
          <Grid item xs={12}>
            <Paper className={classes.noMatches} square={false}>
              <Typography>No applications match your current search parameters.</Typography>
            </Paper>
          </Grid>
        }
        <Can do="create" on="apps">
          {addApp && <Grid
            item
            lg={3}
            md={4}
            sm={6}
            xs={12}>
            <Link to="/apps/create">
              <Paper
                className={classes.addCard}
                square={false}
                elevation={(inHoverState ? 8 : 0)}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}>
                <AddCircleIcon className={classes.addButtonIcon} />
              </Paper>
            </Link>
          </Grid>}
        </Can>
        {apps.length === 0 &&
          <Can not do="create" on="apps">
            <Placeholder />
          </Can>
        }
      </Grid>
    );
  }
}

export default withStyles(styles, { withTheme: true })(CardView);
