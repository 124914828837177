import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ReviewFormDialog from './ReviewFormDialog';
import * as actions from './actions';

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(actions, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewFormDialog);