import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { throttle } from 'throttle-debounce';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import logger from '../services/logger';

const styles = (theme) => ({
  closeButton: {
    marginTop: -10,
    marginRight: -10,
    float: 'right',
  },
  createButton: {
    width: '100%',
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 2,
    backgroundColor: '#0082c2',
    borderRadius: '5px',
    border: '1px solid #0082c2',
    fontWeight: 'bold',
    color: '#ffffff',
    '&:hover': {
      color: '#0082c2',
      border: '1px solid #0082c2',
      backgroundColor: '#ffffff'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '13px',
      lineHeight: '20px',
    }
  },
  dialog: {
    minWidth: 340,
  },
  dialogTitle: {
    marginLeft: 5,
  },
  textField: {
    width: 270,
    marginLeft: 4
  }
});

const CreateClientGroup = ({ classes, createGroup, onClose, open }) => {
  const [name, setName] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const handleSubmit = useCallback(
    throttle(500, async () => {
      try {
        if (name && !submitting) {
          await setSubmitting(true);
          createGroup({ name });
          setName('');
          onClose();
          setSubmitting(false);
        }
      } catch (err) {
        logger.error(err);
      }
    }),
    [name]
  );

  const handleClose = useCallback(() => {
    onClose();
  }, []);

  const handleNameChange = useCallback((event) => {
    event.persist();
    setName(event.target.value);
  }, []);

  return (
    <React.Fragment>
      <Dialog 
        open={open} 
        onClose={handleClose} 
        classes={{
          paper: classes.dialog
        }} 
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
          New Client
          <IconButton className={classes.closeButton} onClick={handleClose}>
            <ClearIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            classes={{
              root: classes.textField
            }}
            margin="dense"
            id="name"
            label="Name"
            type="text"
            value={name}
            onChange={handleNameChange}
            variant="outlined"
            fullWidth />
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.createButton}
            color="primary"
            disabled={submitting}
            onClick={handleSubmit}>
            CREATE CLIENT
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

CreateClientGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  createGroup: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default withStyles(styles)(CreateClientGroup);
