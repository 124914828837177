import findIndex from 'lodash/findIndex';
import {
  DELETE_APP_SUCCESS,
  USER_APP_PIN_REQUEST,
  USER_APP_UNPIN_REQUEST,
  USER_APPS_READ_REQUEST,
  USER_APPS_READ_SUCCESS,
  USER_APPS_READ_ERROR,
  USER_APPS_FILTER_ADD,
  USER_APPS_FILTER_REMOVE,
  USER_APPS_SORT_UPDATE,
} from '../constants/actionTypes';

export default (state = { isFetching: true, filterBy: [] }, action) => {
  switch (action.type) {
    case DELETE_APP_SUCCESS:
      if (!state.apps) return state;

      return {
        ...state,
        apps: state.apps.filter((app) => app.slug !== action.appSlug)
      };

    case USER_APPS_READ_REQUEST:
      return {
        ...state,
        isFetching: true,
        hasError: false
      };

    case USER_APPS_READ_SUCCESS:
      return {
        ...state,
        isFetching: false,
        hasError: false,
        apps: action.response,
        slugs: action.response.map((x) => { return x.slug; })
      };

    case USER_APPS_READ_ERROR:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        error: action.response
      };

    case USER_APPS_FILTER_ADD:
      const newFilterBy = [...state.filterBy];
      if (state.filterBy.indexOf(action.addTag) === -1) {
        newFilterBy.push(action.addTag);
        newFilterBy.sort();
      }
      return {
        ...state,
        filterBy: newFilterBy
      };

    case USER_APPS_FILTER_REMOVE:
      return {
        ...state,
        filterBy: [...state.filterBy].filter((tag) => tag !== action.removeTag)
      };

    case USER_APPS_SORT_UPDATE:
      return {
        ...state,
        sortBy: action.newSort
      };

    case USER_APP_PIN_REQUEST:
      if (!state.apps) return state;
      const newlyPinnedApp = state.apps.find((app) => app.slug === action.appSlug);
      return {
        ...state,
        apps: [
          { ...newlyPinnedApp, pinned: true },
          ...state.apps.filter((app) => app.slug !== action.appSlug)
        ]
      };

    case USER_APP_UNPIN_REQUEST:
      if (!state.apps) return state;

      const newlyUnpinnedApp = state.apps.find((app) => app.slug === action.appSlug);
      const remainingApps = state.apps.filter((app) => app.slug !== action.appSlug);

      let apps = [];
      const pinned = remainingApps.filter((app) => app.pinned);
      const featuredNotPinned = remainingApps.filter((app) => app.featured && !app.pinned);
      const notFeaturedOrPinned = remainingApps.filter((app) => !app.featured && !app.pinned);

      if (newlyUnpinnedApp.featured) {
        const featuredIndex = findIndex(featuredNotPinned, (i) => i.slug > newlyUnpinnedApp.slug);
        const insertAt = (featuredIndex < 0) ? featuredNotPinned.length : featuredIndex;
        apps = [
          ...pinned,
          ...featuredNotPinned.slice(0, insertAt),
          { ...newlyUnpinnedApp, pinned: false },
          ...featuredNotPinned.slice(insertAt),
          ...notFeaturedOrPinned
        ];
      } else {
        const unfeaturedIndex = findIndex(notFeaturedOrPinned, (i) => i.slug > newlyUnpinnedApp.slug);
        const insertAt = (unfeaturedIndex < 0) ? notFeaturedOrPinned.length : unfeaturedIndex;
        apps = [
          ...pinned,
          ...featuredNotPinned,
          ...notFeaturedOrPinned.slice(0, insertAt),
          { ...newlyUnpinnedApp, pinned: false },
          ...notFeaturedOrPinned.slice(insertAt)
        ];
      }

      return {
        ...state,
        apps
      };

    default:
      return state;
  }
};
