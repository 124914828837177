import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Sort from '@material-ui/icons/Sort';
import Tooltip from '@material-ui/core/Tooltip';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CardView from './CardView';

const styles = (theme) => ({
  aboveFold: {
    flexWrap: 'wrap',
    flexGrow: 'inherit',
    justifyContent: 'flex-start',
    width: '100%',
    '& > div': {
      width: 'initial',
      minWidth: '315px',
    },
    '& > div > div': {
      width: '315px',
      minWidth: '315px',
    }
  },
  classHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: 'none',
    boxShadow: 'none',
  },
  details: {
    paddingRight: '56px',
    flexWrap: 'wrap',
    flexGrow: 'inherit',
    justifyContent: 'flex-start',
    width: '100%',
    '& > div': {
      width: 'initial',
      minWidth: '315px',
    },
    '& > div > div': {
      width: '315px',
      minWidth: '315px',
    }
  },
  expand: {
    fontSize: 'calc(1.5em * 1)'
  },
  headerTitle: {
    display: 'flex',
    flexWrap: 'wrap',
    color: theme.palette.colliersBlue,
    fontSize: 24,
    fontWeight: 700,
    marginRight: 8,
  },
  headerCount: {
    color: '#9EA2A2',
    fontSize: 20,
    fontWeight: 600,
  },
  headerSort: {
    color: '#9EA2A2',
    marginRight: '80',
  },
  panel: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
    margin: 0,
    '&:before': {
      display: 'none'
    }
  },
  summary: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    cursor: 'default !important',
    '& > div:nth-child(2)': {
      left: '-50px !important',
      right: 'auto !important',
    },
    '& > div': {
      top: -24,
      [theme.breakpoints.down('sm')]: {
        right: -40
      },
      [theme.breakpoints.down('xs')]: {
        right: -20
      }
    },
  }
});

class CategoryApps extends React.Component {
    static propTypes = {
      alphabeticalSorted: PropTypes.bool.isRequired,
      apps: PropTypes.array.isRequired,
      classes: PropTypes.object.isRequired,
      filteredApps: PropTypes.array.isRequired,
      handleMouseEnter: PropTypes.func.isRequired,
      handleMouseLeave: PropTypes.func.isRequired,
      inHoverState: PropTypes.bool.isRequired,
      title: PropTypes.string.isRequired,
      updateSort: PropTypes.func.isRequired
    }; 

    constructor(props) {
      super(props);
      this.state = {
        expanded: false,
        filteredApps: this.props.filteredApps || [],
        numAppsPerRow: 4
      };
      this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
    }

    componentDidUpdate(prevProps) {
      if (prevProps.filteredApps !== this.props.filteredApps) {
        this.setState({ filteredApps: this.props.filteredApps });
      }
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
      let numAppsPerRow = 4;
      if (window.innerWidth < 901) {
        numAppsPerRow = 1;
      } else if (window.innerWidth > 901 && window.innerWidth < 1215) {
        numAppsPerRow = 2;
      } else if (window.innerWidth > 1215 && window.innerWidth < 1507) {
        numAppsPerRow = 3;
      } else {
        numAppsPerRow = 4;
      }
      this.setState({ numAppsPerRow });
    }

    handleToggleExpand = () => {
      this.setState((prevState) => ({ expanded: !prevState.expanded }));
    };

    render() {
      const { filteredApps, numAppsPerRow } = this.state;
      const { 
        alphabeticalSorted, 
        apps, 
        classes, 
        handleMouseEnter, 
        handleMouseLeave, 
        inHoverState, 
        title, 
        updateSort 
      } = this.props;
      const aboveFoldApps = filteredApps.slice(0, numAppsPerRow);
      const remainingApps = filteredApps.slice(numAppsPerRow);
      const headerCountText = `(${filteredApps.length || 0})`;
      const expandIcon = filteredApps.length > numAppsPerRow ? 
        <ExpandMoreIcon className={classes.expand} onClick={this.handleToggleExpand} /> : null;
      const cardViewProps = {
        addApp: false,
        filteredApps: aboveFoldApps,
        handleMouseEnter,
        handleMouseLeave,
        inHoverState,
        apps
      };
      const cardViewExpandedProps = {
        addApp: true,
        filteredApps: remainingApps,
        handleMouseEnter,
        handleMouseLeave,
        inHoverState,
        apps
      };
      return (
        <React.Fragment>
          <div className={classes.classHeader}>
            <Typography className={classes.headerTitle}>{title}</Typography>
            <Typography className={classes.headerCount}>{headerCountText}</Typography>
            <Tooltip title="Sort">
              <IconButton className={classes.headerSort} onClick={updateSort(alphabeticalSorted ? 'az' : 'za')}>
                {alphabeticalSorted ? <Sort style={{ transform: 'scaleY(-1)' }} /> : <Sort />}
              </IconButton>
            </Tooltip>
          </div>
          <ExpansionPanel className={classes.panel} expanded={this.state.expanded}>
            <ExpansionPanelSummary 
              className={classes.summary} 
              expandIcon={expandIcon}>
              <div className={classes.aboveFold}>
                <CardView
                  {...cardViewProps} />
              </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.details}>
              <CardView
                {...cardViewExpandedProps} />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </React.Fragment>
      );
    }
}

export default withStyles(styles, { withTheme: true })(CategoryApps);
