import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';

class ServiceWorker extends React.Component {
  constructor(props) {
    super(props);
    this.refreshing = false;

    this.state = {
      showMessage: false
    };
  }

  componentDidMount() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.refreshing) return;
        this.refreshing = true;
        window.location.reload();
      });

      navigator.serviceWorker.getRegistration().then((reg) => {
        this.listenForWaitingServiceWorker(reg);      
      });
    }
  }

  listenForWaitingServiceWorker = (reg) => {
    const setShowMessage = () => this.setState({ showMessage: true });

    function awaitStateChange() {
      reg.installing.addEventListener('statechange', function() {
        if (this.state === 'installed') return setShowMessage();
      });
    }

    if (!reg) return;
    if (reg.waiting) return setShowMessage();
    if (reg.installing) awaitStateChange();
    reg.addEventListener('updatefound', awaitStateChange);
  }

  handleReloadClick = () => {
    navigator.serviceWorker.getRegistration().then((reg) => {
      reg.waiting.postMessage('skipWaiting');
    });
  }

  handleDismissClick = () => {
    this.setState({ showMessage: false });
  }

  render() {
    return (
      <React.Fragment>
        <Snackbar
          action={
            <React.Fragment>
              <Button color="inherit" onClick={this.handleReloadClick}>
                Reload
              </Button>
              <Button color="inherit" onClick={this.handleDismissClick}>
                Dismiss
              </Button>
            </React.Fragment>
          }
          message={<span>A new version of the app is available.</span>}
          open={this.state.showMessage}
          onClose={this.handleClose} />
      </React.Fragment>
    );
  }
}

export default ServiceWorker;
