import { CLOSE_NAV_DRAWER, OPEN_NAV_DRAWER, TOGGLE_NAV_DRAWER } from '../constants/actionTypes';

export function closeNavDrawer() {
  return {
    type: CLOSE_NAV_DRAWER
  };
}

export function openNavDrawer() {
  return {
    type: OPEN_NAV_DRAWER
  };
}

export function toggleNavDrawer() {
  return {
    type: TOGGLE_NAV_DRAWER
  };
}
