import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setPageTitles } from '../app/actions';
import { deleteApp, logAppInteraction, pinUserApp, unpinUserApp } from '../shared/actions';
import Details from './Details';
import * as actions from './actions';

const mapStateToProps = ({ app: { groups }, apps, rating }) => {
  return {
    hasError: false,
    isFetching: true,
    ...apps,
    groups,
    ratings: {},
    averageRatings: {},
    ...rating,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { ...actions, deleteApp, logAppInteraction, pinUserApp, setPageTitles, unpinUserApp },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Details);
