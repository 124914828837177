import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

const heading = (props) => (
  <Typography
    gutterBottom
    style={{ color: 'inherit' }}
    variant={`h${props.level}`}
    {...props} />
);
heading.propTypes = { level: PropTypes.number.isRequired };

const link = (props) => (
  <Link {...props} target="_blank" />
);

const paragraph = (props) => (
  <Typography
    style={{ color: 'inherit', fontSize: 'inherit', fontWeight: 'inherit', lineHeight: 'inherit' }}
    {...props} />
);

const table = (props) => (
  <Paper>
    <Table {...props} />
  </Paper>
);

const tableBody = (props) => <TableBody {...props} />;

const tableCell = (props) => <TableCell {...props} />;

const tableHead = (props) => <TableHead {...props} />;

const tableRow = (props) => <TableRow {...props} />;

const thematicBreak = (props) => <Divider style={{ margin: '16px 0' }} {...props} />;

const MaterialMarkdown = ({ source, ...props }) => (
  <ReactMarkdown
    renderers={{
      heading,
      link,
      paragraph,
      table,
      tableBody,
      tableCell,
      tableHead,
      tableRow,
      thematicBreak,
    }}
    source={source}
    {...props} />
);

MaterialMarkdown.propTypes = {
  source: PropTypes.string
};

export default MaterialMarkdown;
