import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, Drawer, Typography, IconButton } from '@material-ui/core';
import { ArrowBack, Close } from '@material-ui/icons';
import Skin from '../config/skin';
import splashBgBig from '../assets/images/splash-bg-big.jpg';
import splashBgMedium from '../assets/images/splash-bg-medium.jpg';
import splashBgSmall from '../assets/images/splash-bg-small.jpg';
import ShareIcon from '../assets/icons/ShareIcon';
import MyColliersIcon from '../assets/icons/mycolliers icon 192.png';

const styles = (theme) => ({
  backButton: {
    color: 'white',
    fontWeight: 'bold',
    opacity: 0.5,
    [theme.breakpoints.down('xs')]: {
      marginLeft: '-1.8em'
    }
  },
  bgRect: {
    borderRadius: '7vw',
    height: '24vw',
    opacity: 0.06,
    position: 'absolute',
    width: '80vw',
    zIndex: 0,
    [theme.breakpoints.down('xs')]: {
      borderRadius: '120px',
      height: '300px',
      width: '800px',
    }
  },
  bgRectTop: {
    top: '-16vw',
    background: 'black',
    transform: 'rotate(-15deg)',
    [theme.breakpoints.down('xs')]: {
      top: '-150px',
    }
  },
  bgRectBottom: {
    bottom: '-15vw',
    background: 'white',
    transform: 'rotate(-15deg)',
    [theme.breakpoints.down('xs')]: {
      bottom: '-200px',
    }
  },
  bgRectTopLeft: {
    left: '8vw',
    [theme.breakpoints.up('sm')]: {
      animation: 'rectangle1 9.5s ease-in-out infinite',
    },
    [theme.breakpoints.down('xs')]: {
      left: '-580px',
      top: '-50px',
    }
  },
  bgRectTopRight: {
    left: '44vw',
    [theme.breakpoints.up('sm')]: {
      animation: 'rectangle2 6.5s ease-in-out infinite',
    },
    [theme.breakpoints.down('xs')]: {
      left: '5vw',
    }
  },
  bgRectBottomLeft: {
    right: '40vw',
    [theme.breakpoints.up('sm')]: {
      animation: 'rectangle2 8s ease-in-out infinite',
    }
  },
  bgRectBottomRight: {
    right: '4vw',
    [theme.breakpoints.up('sm')]: {
      animation: 'rectangle1 7.5s ease-in-out infinite',
    },
    [theme.breakpoints.down('xs')]: {
      right: '-4vw',
    }
  },
  buttonLeftIcon: {
    marginRight: theme.spacing.unit,
  },
  copyright: {
    color: 'white',
    opacity: 0.5,
    padding:'2em 2em 4em',
    textAlign:'center',
  },
  logo: {
    width: '160px'
  },
  navbar: {
    paddingBottom: '2em',
    textAlign: 'left'
  },
  promptCloseWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  promptIcon: {
    margin: '0 auto',
    height: theme.spacing.unit * 8,
    width: theme.spacing.unit * 8,
  },
  promptText: {
    padding: theme.spacing.unit,
  },
  promptWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  shareIcon: {
    paddingBottom: theme.spacing.unit,
    margin: '0 auto',
    height: theme.spacing.unit * 5,
    width: theme.spacing.unit * 5,
  },
  splashButton: {
    background: `linear-gradient(
      to right,
      ${theme.palette.primary.dark},
      ${theme.palette.primary.main}
    )`,
    borderRadius: '5px',
    boxShadow: `0 0px ${theme.spacing.unit * 3}px rgba(255,255,255,0.5)`,
    fontWeight: 'bold',
    width: '200px',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  splashOverlay: {
    background: theme.palette.primary.main,
    bottom: 0,
    left: 0,
    opacity: 0.8,
    position: 'absolute',
    right: 0,
    top: 0,
  },
  tagline: {
    color: 'inherit',
    fontWeight: 'bold',
    marginBottom: '1.5em',
    marginTop: '1.75em',
  },
  wrapperOuter: {
    backgroundImage: `url(${splashBgSmall})`,
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    minHeight: '100vh',
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      backgroundImage: `url(${splashBgMedium})`,
    },
    [theme.breakpoints.up('md')]: {
      backgroundImage: `url(${splashBgBig})`,
    }
  },
  wrapperInner: {
    backgroundSize: 'contain',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '100vh',
    padding:'1em 2em',
    position: 'relative',
    textAlign: 'center',
    width: '100%',
    zIndex: 1,
  },
});

class Splash extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    dismissSplashPage: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props);
    this.state = {
      installButton: this.isSafari() && !this.isPWA(),
      drawerOpen: false,
    };
  }

  isSafari() {
    const ua = window.navigator.userAgent;
    const iOS = !!ua.match(/iP(ad|hone)/i);
    const webkit = !!ua.match(/WebKit/i);
    const isFirefox = !!ua.match(/FxiOS/i);
    const iOSSafari = iOS && webkit && !ua.match(/CriOS/i) && !ua.match(/OPiOS/i) && !isFirefox;
    return iOSSafari;
  }

  isPWA() {
    return window.matchMedia('(display-mode: standalone)').matches;
  }

  toggleDrawer = () => {
    this.setState({
      drawerOpen: !this.state.drawerOpen,
    });
  };

  render() {
    const { dismissSplashPage, classes } = this.props;
    if (Skin.colliers360) {
      dismissSplashPage();
      return null;
    }

    const Prompt = (
      <div className={classes.promptWrapper}>
        <div className={classes.promptCloseWrapper}>
          <IconButton onClick={this.toggleDrawer}>
            <Close />
          </IconButton>
        </div>
        <img src={MyColliersIcon} className={classes.promptIcon} alt="App Logo" />
        <Typography variant="subtitle1" className={classes.promptText}>
          Find the icon below on the bottom of your browser in Safari
          and choose &quot;Add to Homescreen&quot; to download the app.
        </Typography>
        <ShareIcon className={classes.shareIcon} />
      </div>
    );

    // return the page
    return (
      <div className={classes.wrapperOuter}>
        <div className={classes.splashOverlay} />

        <div className={classes.wrapperInner}>
          <div className={classes.navbar}>
            <Button
              className={classes.backButton}
              color="primary"
              component="a"
              href={Skin.originUrl}
              size="large">
              <ArrowBack className={classes.buttonLeftIcon} />
              Colliers Home
            </Button>
          </div>

          <div>
            <img
              className={classes.logo}
              alt={`${Skin.name} Logo`}
              src={Skin.logoUrl} />

            <Typography variant="h4" className={classes.tagline}>
              Accelerate your success with our all-new technology platform.
            </Typography>

            <Button
              variant="contained"
              color="primary"
              className={classes.splashButton}
              onClick={dismissSplashPage}>
              Log In
            </Button>
            <br />
            {this.state.installButton &&
              <Button
                variant="contained"
                color="primary"
                className={classes.splashButton}
                onClick={this.toggleDrawer}
                style={{ marginTop: 8 }}>
                Download App
              </Button>
            }
          </div>

          <Typography variant="inherit" className={classes.copyright}>
            Copyright © {(new Date()).getFullYear()} Colliers International
          </Typography>
        </div>
        <Drawer
          anchor="bottom"
          open={this.state.drawerOpen}
          onClose={this.toggleDrawer}>
          <div
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer}
            onKeyDown={this.toggleDrawer}>
            {Prompt}
          </div>
        </Drawer>
      </div>
    );
  }
}

export default withStyles(styles)(Splash);
