import React from 'react';
import PropTypes from 'prop-types';
import { Ability } from '@casl/ability';
import { unpackRules } from '@casl/ability/extra';
import { createCanBoundTo } from '@casl/react';

export const ability = new Ability([]);

export const registerPermissions = (permissions) => {
  ability.update(unpackRules(permissions));
};

export const registerDefaultTenant = (defaultTenantId) => {
  ability.defaultTenantId = defaultTenantId;
};

const BoundCan = createCanBoundTo(ability);

const CustomBoundCan = (props) => {
  const { tenant, I, ...cleanProps } = props;
  cleanProps.do = `${props.do || I} ${tenant || ability.defaultTenantId}`.trim();
  return <BoundCan {...cleanProps} />;
};
CustomBoundCan.propTypes = {
  I: PropTypes.string,
  do: PropTypes.string,
  tenant: PropTypes.string,
};

export default CustomBoundCan;
