import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    minWidth: `${theme.spacing.unit * 24}px`,
  },
});

const ValueContainer = ({ classes, children }) => (
  <div className={classes.valueContainer}>
    {children}
  </div>
);

ValueContainer.propTypes = {
  children: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ValueContainer);