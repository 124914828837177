import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

const styles = (theme) => ({
  subHeader: {
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey[50],
    display: 'flex',
    height: 100,
    justifyContent: 'space-between',
    padding: theme.spacing.unit * 2,
    width: '100%'
  }
});

export class Subheader extends Component {
  static propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object.isRequired,
    navigateBack: PropTypes.func.isRequired,
    overrideBack: PropTypes.string
  }
  static contextTypes = {
    router: PropTypes.object
  }

  handleBackClick = () => {
    const { navigateBack, overrideBack } = this.props;
    const { router } = this.context;
    if (overrideBack) {
      router.history.push(overrideBack);
    } else {
      navigateBack();
    }
  };

  render() {
    const { children, classes } = this.props;
    return (
      <div className={classes.subHeader}>
        <Button color="primary" onClick={this.handleBackClick}>
          <NavigateBeforeIcon />
          {'Back'}
        </Button>
        <div>
          {children}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Subheader);
