import React from 'react';
import { components } from 'react-select';
import AddIcon from '@material-ui/icons/Add';

const DropdownIndicator = (props) => {
  const { DropdownIndicator } = components;
  return (
    <DropdownIndicator {...props}>
      <AddIcon />
    </DropdownIndicator>
  );
};

export default DropdownIndicator;
