import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import UploadIcon from '@material-ui/icons/AddBoxOutlined';
import classNames from 'classnames';
import { convertBytesToMbsOrKbs } from './helpers/helpers';
import SnackbarContentWrapper from './SnackbarContentWrapper';
import PreviewList from './PreviewList';

const styles = (theme) => ({
  '@keyframes progress': {
    '0%': {
      backgroundPosition: '0 0',
    },
    '100%': {
      backgroundPosition: '-70px 0',
    },
  },
  dropzone: {
    alignItems: 'center',
    border: '3px solid #EEE',
    borderRadius: 4,
    boxSizing: 'border-box',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '225px',
    position: 'relative',
    width: '100%',
  },
  dropzoneParagraph: {
    color: theme.palette.text.secondary,
    fontSize: 18,
    fontWeight: 'bold',
    margin: '1em 0.5em'
  },
  dropzoneTextStyle: {
    textAlign: 'center'
  },
  stripes: {
    border: 'solid',
    backgroundImage: 'repeating-linear-gradient(-45deg, #F0F0F0, #F0F0F0 25px, #C8C8C8 25px, #C8C8C8 50px)',
    animation: 'progress 2s linear infinite !important',
    backgroundSize: '150% 100%',
  },
  rejectStripes: {
    border: 'solid',
    backgroundImage: 'repeating-linear-gradient(-45deg, #fc8785, #fc8785 25px, #f4231f 25px, #f4231f 50px)',
    animation: 'progress 2s linear infinite !important',
    backgroundSize: '150% 100%',
  },
  uploadIconSize: {
    width: 51,
    height: 51,
    color: '#909090'
  }
});

const DropzoneArea = ({
  allowedFileTypes,
  maxFileSize,
  classes,
  clearOnUnmount, 
  dropzoneText,
  filesLimit,
  onChange,
  onDrop,
  onDropRejected,
  onDelete,
  getDropRejectMessage,
  getFileRemovedMessage,
  previewMessage,
  showAlerts,
  shouldShowPreviewsInDropzone,
  getFileLimitExceedMessage,
  state,
  setState,
  files,
  setFiles,
}) => {
  useEffect(() => {
    if (clearOnUnmount) {
      return () => {
        setFiles([]);
      };
    }
  }, []);

  const onDropInternal = (droppedFiles) => {
    if (files.length + droppedFiles.length > filesLimit) {
      setState({
        openSnackBar: true,
        snackbarMessage: getFileLimitExceedMessage(filesLimit),
        snackbarVariant: 'error'
      });
    } else {
      const readyFiles = [];
      droppedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onload = async (event) => {
          readyFiles.push({ file, data: event.target.result });
          if (readyFiles.length === droppedFiles.length) {
            await setFiles([...new Set([...files, ...readyFiles])]);
            if (onChange) {
              onChange(files.map((fileObject) => fileObject.file));
            }
            if (onDrop) {
              onDrop(file);
            }
          }
        };
        reader.readAsDataURL(file);
      });
    }
  };

  function handleRemove(fileIndex) {
    return function(event) {
      event.stopPropagation();

      const removedFile = files.splice(fileIndex, 1)[0].file;
    
      setFiles(files);
      if (onDelete) {
        onDelete(removedFile);
      }
      if (onChange) {
        onChange(files.map((fileObject) => fileObject.file));
      }
      setState({
        ...state,
        openSnackBar: true,
        snackbarMessage: getFileRemovedMessage(removedFile.name),
        snackbarVariant: 'info'
      });
    };
  }

  const handleDropRejected = (rejectedFiles, evt) => {
    let message = '';
    rejectedFiles.forEach((rejectedFile) => {
      message = getDropRejectMessage(
        rejectedFile, 
        allowedFileTypes,
        maxFileSize
      );
    });
    if (onDropRejected) {
      onDropRejected(rejectedFiles, evt);
    }
    setState({
      openSnackBar: true,
      snackbarMessage: message,
      snackbarVariant: 'error'
    });
  };
  
  function onCloseSnackbar() {
    setState({
      ...state,
      openSnackBar: false,
    });
  }

  const getDragStyles = (isDragActive, isDragAccept, isDragReject) => {
    if (isDragReject) {
      return classes.rejectStripes;
    }
    if (isDragAccept) {
      return classes.stripes;
    }
    return '';
  };

  const showPreviewsInDropzone = shouldShowPreviewsInDropzone && files.length > 0;

  const dropzoneConfig = {
    onDrop: onDropInternal,
    onDropRejected: handleDropRejected,
  };
  if (allowedFileTypes && allowedFileTypes.length > 0) {
    dropzoneConfig.accept = allowedFileTypes;
  }
  if (maxFileSize && maxFileSize > 0 && maxFileSize < Infinity) {
    dropzoneConfig.maxSize = maxFileSize;
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone(dropzoneConfig);
  return (
    <Fragment>
      <div 
        {...getRootProps({ 
          className: classNames(
            classes.dropzone,
            classes.dropzoneTextStyle, 
            getDragStyles(isDragActive, isDragAccept, isDragReject)
          ) 
        })} >
        <input {...getInputProps({ className: classes.dropzone })} multiple />

        {showPreviewsInDropzone && (
          <Fragment>
            <PreviewList
              fileObjects={files}
              handleRemove={handleRemove}
              previewMessage={previewMessage} />
            <div style={{ flex: 1 }} />
          </Fragment>
        )}
        {!showPreviewsInDropzone && (
          <Fragment>
            <UploadIcon className={classes.uploadIconSize} />
            <Typography className={classes.dropzoneParagraph}>
              {dropzoneText}
            </Typography>
          </Fragment>
        )}
      </div>

      {showAlerts && (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={state.openSnackBar}
          autoHideDuration={6000}
          onClose={onCloseSnackbar}>
          <SnackbarContentWrapper
            onClose={onCloseSnackbar}
            variant={state.snackbarVariant}
            message={state.snackbarMessage} />
        </Snackbar>
      )}
    </Fragment>
  );
};

DropzoneArea.defaultProps = {
  allowedFileTypes: ['application/*', 'image/*', 'text/*', 'video/*'],
  filesLimit: 3,
  maxFileSize: Infinity,
  dropzoneText: 'Drag and Drop, or click to upload from your computer',
  shouldShowPreviewsInDropzone: true,
  showAlerts: true,
  clearOnUnmount: true,
  initialFiles: [],
  getFileLimitExceedMessage: (filesLimit) => (`Only ${filesLimit} allowed at one time.`),
  getFileRemovedMessage: (fileName) => (`File ${fileName} removed.`),
  getDropRejectMessage: (rejectedFile, allowedFileTypes, maxFileSize) => {
    const fileTypeIsAllowed = allowedFileTypes.reduce((isAllowed, allowedType) => {
      if (/^[^/*]+\/\*$/.test(allowedType)) {
        if (rejectedFile.type.split('/').shift() === allowedType.split('/').shift()) {
          return true;
        }
      } else if (rejectedFile.type === allowedType) {
        return true;
      }
      return isAllowed;
    }, false);

    let message = `File ${rejectedFile.name} was rejected.`;
    if (!fileTypeIsAllowed) {
      message += ' File type is not supported.';
    } else if (rejectedFile.size > maxFileSize) {
      message += ` File is too big (${convertBytesToMbsOrKbs(maxFileSize)} limit). `;
    }
    return message;
  },
};

DropzoneArea.propTypes = {
  allowedFileTypes: PropTypes.array,
  classes: PropTypes.object,
  clearOnUnmount: PropTypes.bool,
  dropzoneClass: PropTypes.string,
  dropzoneText: PropTypes.string,
  files: PropTypes.array.isRequired,
  filesLimit: PropTypes.number,
  getDropRejectMessage: PropTypes.func,
  getFileLimitExceedMessage: PropTypes.func,
  getFileRemovedMessage: PropTypes.func,
  initialFiles: PropTypes.arrayOf(PropTypes.string),
  maxFileSize: PropTypes.number,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  onDrop: PropTypes.func,
  onDropRejected: PropTypes.func,
  previewMessage: PropTypes.string,
  setFiles: PropTypes.func.isRequired,
  setState: PropTypes.func.isRequired,
  shouldShowPreviewsInDropzone: PropTypes.bool,
  showAlerts: PropTypes.bool,
  state: PropTypes.object.isRequired,
};

export default withStyles(styles)(DropzoneArea);

