import React from 'react';
import PropTypes from 'prop-types';
import Form from './form/Form';

class Create extends React.Component {
  static propTypes = {
    createApp: PropTypes.func.isRequired,
    navigateBack: PropTypes.func.isRequired
  }

  handleCancel = () => {
    this.props.navigateBack();
  }

  handleSubmit = (values) => {
    this.props.createApp(values);
  }

  render() {
    return <Form onCancel={this.handleCancel} onSubmit={this.handleSubmit} />;
  }
}

export default Create;
