import React from 'react';
import PropTypes from 'prop-types';
import FullScreenLoader from '../shared/FullScreenLoader';
import Form, { initialValues } from './form/Form';

class Edit extends React.Component {
  static propTypes = {
    app: PropTypes.object,
    clearApp: PropTypes.func.isRequired,
    fetchApp: PropTypes.func.isRequired,
    hasError: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    updateApp: PropTypes.func.isRequired
  }
  static contextTypes = {
    router: PropTypes.object
  }

  componentDidMount() {
    const { fetchApp, match } = this.props;
    fetchApp(match.params.slug);
  }

  componentWillUnmount() {
    this.props.clearApp();
  }

  handleSubmit = (values) => {
    this.props.updateApp({ ...values, id: values.slug });
  }

  onCancel = () => {
    const { router } = this.context;
    router.history.push(`/apps/${this.props.app.slug}`);
  };

  render() {
    const { app, hasError, isFetching } = this.props;

    if (hasError) return (<div>App not found.</div>);

    if (isFetching) return <FullScreenLoader />;

    return (
      <Form
        initialValues={initialValues(app)}
        onCancel={this.onCancel}
        onSubmit={this.handleSubmit} />
    );
  }
}

export default Edit;
