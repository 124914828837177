import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import get from 'lodash/get';
import { withStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import FeaturedIcon from '@material-ui/icons/Star';
import NotFeaturedIcon from '@material-ui/icons/StarBorder';
import MoveUpIcon from '@material-ui/icons/ArrowDropUp';
import MoveDownIcon from '@material-ui/icons/ArrowDropDown';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Can from '../config/ability';
import ConfirmDialog from '../shared/ConfirmDialog';
import DefaultPageLayout from '../shared/DefaultPageLayout';
import FullScreenError from '../shared/FullScreenError';
import FullScreenLoader from '../shared/FullScreenLoader';
import ConnectedConfigModal from './ConnectedConfigModal';
import ConnectedLogoModal from './ConnectedLogoModal';
import ConnectedUserModal from './ConnectedUserModal';

const styles = (theme) => ({
  appEnabled: {
    extend: 'appRow',
  },
  appFeatured: {
    extend: 'appEnabled'
  },
  appRow: {
    borderBottom: '1px solid #eee',
    '&:not($listHeader):hover': {
      background: '#f7f7f7'
    },
    '&:last-child': {
      borderRadius: '0 0 4px 4px'
    }
  },
  checkboxContainer: {
    textAlign: 'center',
    width: 150,
    [theme.breakpoints.down('sm')]: {
      width: 100
    },
    [theme.breakpoints.down('xs')]: {
      width: 35
    }
  },
  deleteButton: {
    color: red[500],
    marginLeft: theme.spacing.unit * 0.5,
    '&:hover': {
      backgroundColor: red[100],
      borderColor: red[500],
    },
  },
  divider: {
    borderTop: '1px solid #e0e0e0',
  },
  dropdown: {
    borderRadius: 5,
    fontWeight: 'bold',
    justifyContent: 'space-between',
    textAlign: 'left',
    textTransform: 'none',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  dropdownInner: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  featuredIcon: {
    color: '#0093d0'
  },
  groupIcon: {
    marginRight: 4,
    maxHeight: 25,
    maxWidth: 32
  },
  leftActions: {
    marginLeft: theme.spacing.unit * 2,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing.unit * 2,
      width: '100%'
    }
  },
  listHeader: {
    background: '#f5fcff',
    borderRadius: '4px 4px 0 0',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  listItemTextRoot: {
    flexBasis: 0
  },
  listItemTextSecondary: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  listSubheader: {
    background: '#f5fcff',
    boxShadow: '0 2px 2px rgba(0,0,0,0.05)'
  },
  moveIcon: {
    display: 'none'
  },
  moveable: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    display: 'block'
  },
  notFeaturedIcon: {
    color: '#757575'
  },
  reorderContainer: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginLeft: -10,
    width: 30
  },
  textField: {},
  thumbnailContainer: {
    alignItems: 'center',
    background: 'white',
    borderRadius: 3,
    boxShadow: '0 0 5px rgba(0,0,0,0.2)',
    display: 'flex',
    justifyContent: 'center',
    height: 48,
    width: 96,
    '& > img': {
      maxHeight: '100%',
      maxWidth: '100%'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  }
});

class Groups extends React.Component {
  static propTypes = {
    apps: PropTypes.array,
    classes: PropTypes.object.isRequired,
    deleteGroup: PropTypes.func.isRequired,
    fetchApps: PropTypes.func.isRequired,
    fetchGroups: PropTypes.func.isRequired,
    groups: PropTypes.array,
    hasError: PropTypes.bool.isRequired,
    isFetchingApps: PropTypes.bool.isRequired,
    isFetchingGroups: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    updateGroup: PropTypes.func.isRequired,
  };
  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.grid = null;
    this.state = {
      confirmingDeletion: false,
      isDeleting: false,
      menuAnchorGroup: null,
      selectedGroupId: null
    };
  }

  componentDidMount() {
    this.props.fetchApps();
    this.fetchGroups();
  }

  fetchGroups = () => {
    this.props.fetchGroups().then(() => {
      if (this.props.groups && this.props.groups.length) {
        const selectGroupId = get(this.props.match, 'params.id');
        const selectGroup = selectGroupId ? this.props.groups.find((g) => g._id === selectGroupId) : null;
        this.selectGroup(selectGroup || this.props.groups[0]);
      }
    });
  }

  selectGroup = (group) => {
    this.setState({ selectedGroupId: group._id });
    this.context.router.history.replace(`/groups/${group._id}`);
  }

  getSelectedGroup = () => {
    return this.props.groups.find((group) => group._id === this.state.selectedGroupId);
  }

  isAppEnabled = (app) => {
    return (this.getSelectedGroup().enabledApps || []).includes(app._id);
  }

  isAppFeatured = (app) => {
    return (this.getSelectedGroup().featuredApps || []).includes(app._id);
  }

  onMoveApp = (app, direction) => () => {
    const group = this.getSelectedGroup();
    const featuredApps = group.featuredApps;
    const currentIndex = featuredApps.indexOf(app._id);
    if (currentIndex > -1) {
      const newIndex = currentIndex + direction;
      if (newIndex > -1 && newIndex < featuredApps.length) {
        const newFeaturedApps = featuredApps.filter((id) => id !== app._id);
        newFeaturedApps.splice(newIndex, 0, app._id);

        this.props.updateGroup({
          id: group._id,
          featuredApps: newFeaturedApps
        });
      }
    }
  }

  onToggleGroupApp = (app) => (event) => {
    const group = this.getSelectedGroup();
    const enableApp = event.target.checked;
    const enabledApps = enableApp
      ? group.enabledApps.concat([app._id])
      : group.enabledApps.filter((a) => a !== app._id);
    
    // init payload
    const payload = {
      id: group._id,
      enabledApps
    };

    // if disabling app, also make sure it is not featured
    if (!enableApp && this.isAppFeatured(app)) {
      payload.featuredApps = group.featuredApps.filter((a) => a._id !== app._id);
    }

    this.props.updateGroup(payload);
  }

  onToggleGroupFeaturedApp = (app) => () => {
    const group = this.getSelectedGroup();
    const featureApp = !this.isAppFeatured(app);
    const featuredApps = featureApp
      ? group.featuredApps.concat([app._id])
      : group.featuredApps.filter((a) => a !== app._id);

    // init payload
    const payload = {
      id: group._id,
      featuredApps
    };

    // if featuring app, also make sure it is enabled
    if (featureApp && !this.isAppEnabled(app)) {
      payload.enabledApps = group.enabledApps.concat([app._id]);
    }

    this.props.updateGroup(payload);
  }

  onEnableAll = (enable) => async () => {
    // if enabling all
    if (enable) {
      return this.props.updateGroup({
        id: this.state.selectedGroupId,
        enabledApps: this.props.apps.map((a) => a._id)
      });
    }

    // else, is disabling all, unfeature all first so doesn't have to do individually
    return this.props.updateGroup({
      id: this.state.selectedGroupId,
      enabledApps: [],
      featuredApps: [],
    });
  }

  onFeatureAll = (feature) => async () => {
    // if featuring all, make sure all are enabled
    if (feature) {
      return this.props.updateGroup({
        id: this.state.selectedGroupId,
        enabledApps: this.props.apps.map((a) => a._id),
        featuredApps: this.props.apps.map((a) => a._id),
      });
    }

    // else, is unfeaturing all
    return this.props.updateGroup({
      id: this.state.selectedGroupId,
      featuredApps: [],
    });
  }

  openGroupDropdown = (event) => {
    this.setState({ menuAnchorGroup: event.currentTarget });
  }
  closeGroupDropdown = () => {
    this.setState({ menuAnchorGroup: null });
  }
  selectGroupFromDropdown = (group) => () => {
    this.selectGroup(group);
    this.closeGroupDropdown();
  }

  onClickDelete = () => {
    this.setState({ confirmingDeletion: true });
  };
  onCancelDelete = () => {
    this.setState({ confirmingDeletion: false });
  };
  onConfirmDelete = async () => {
    this.setState({ confirmingDeletion: false, isDeleting: true });
    await this.props.deleteGroup(this.getSelectedGroup().id);
    this.fetchGroups(); // TODO: also need to refetch user's permissions and probably reload entire app
    this.setState({ isDeleting: false });
  };

  render() {
    if (this.props.isFetchingApps || this.props.isFetchingGroups || this.state.isDeleting) {
      return <FullScreenLoader />;
    } else if (this.props.hasError) {
      return <FullScreenError message="An error occurred while fetching Apps and Groups" />;
    } else if (!(this.props.groups && this.state.selectedGroupId)) {
      return <div style={{ paddingTop: 100, textAlign: 'center' }}>No groups found.</div>;
    }

    const { classes, groups } = this.props;
    const group = this.getSelectedGroup();
    if (!group) return null;
    
    // sort the apps and cache values for isEnabled and isFeatured
    const apps = this.props.apps
      .map((app) => {
        const featuredRank = (group.featuredApps || []).indexOf(app._id);
        return {
          ...app,
          isEnabled: (group.enabledApps || []).includes(app._id),
          isFeatured: (featuredRank > -1),
          featuredRank: featuredRank === -1 ? null : featuredRank
        };
      })
      .sort((a, b) => {
        if (a.isFeatured && b.isFeatured) {
          if (a.featuredRank !== b.featuredRank) {
            return a.featuredRank < b.featuredRank ? -1 : 1;
          }
        } else if (a.isFeatured !== b.isFeatured) {
          return a.isFeatured ? -1 : 1;
        } else if (a.isEnabled !== b.isEnabled) {
          return a.isEnabled ? -1 : 1;
        }
        return a.title < b.title ? -1 : 1;
      });
    const featuredTally = apps.filter((app) => app.isFeatured).length;

    const colliersGroups = groups.filter((g) => !g.isClient);
    const clientGroups = groups.filter((g) => g.isClient);

    return (
      <DefaultPageLayout
        title={`${group && group.isClient ? 'Client' : 'Group'} Admin`}
        titleActionsLeft={
          <div className={classes.leftActions}>
            <Button
              className={classes.dropdown}
              color="primary"
              onClick={this.openGroupDropdown}
              size="large"
              variant="contained">
              {group.isClient && group.iconUrl && (
                <img src={group.iconUrl} alt={group.name} className={classes.groupIcon} />
              )}
              <span className={classes.dropdownInner}>{group.name}</span>
              <KeyboardArrowDownIcon />
            </Button>
            <Menu
              anchorEl={this.state.menuAnchorGroup}
              open={Boolean(this.state.menuAnchorGroup)}
              onClose={this.closeGroupDropdown}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              PaperProps={{
                style: {
                  maxHeight: '50%',
                  width: 250,
                },
              }}>
              {colliersGroups.map((group, index) => (
                <MenuItem key={index} onClick={this.selectGroupFromDropdown(group)}>
                  {group.name}
                </MenuItem>
              ))}
              {colliersGroups.length > 0 && clientGroups.length > 0 && [
                <Divider key="1" />,
                <ListSubheader key="2" className={classes.listSubheader}>Clients</ListSubheader>
              ]}
              {clientGroups.map((group, index) => (
                <MenuItem key={index} onClick={this.selectGroupFromDropdown(group)}>
                  {group.iconUrl && <img src={group.iconUrl} alt={group.name} className={classes.groupIcon} />}
                  {group.name}
                </MenuItem>
              ))}
            </Menu>
          </div>
        }
        titleActions={group && (
          <React.Fragment>
            {group.isClient && <ConnectedLogoModal group={group} />}
            <ConnectedConfigModal group={group} />
            {group.isClient && <ConnectedUserModal group={group} />}
            {group.isClient && (
              <Can do="archive" on="tenants">
                <Button
                  className={classes.deleteButton}
                  color="secondary"
                  onClick={this.onClickDelete}
                  size="large"
                  variant="outlined">
                  Delete
                </Button>
              </Can>
            )}
          </React.Fragment>
        )}>
        <Paper>
          <List component="div" style={{ padding: 0 }}>
            <ListItem className={cx([classes.appRow, classes.listHeader])}>
              <div className={classes.reorderContainer} />

              <ListItemText />

              <div className={classes.checkboxContainer}>
                <Button size="small" onClick={this.onEnableAll(true)}>
                  all
                </Button>
                |
                <Button size="small" onClick={this.onEnableAll(false)}>
                  none
                </Button>
              </div>
              <div className={classes.checkboxContainer}>
                <Button size="small" onClick={this.onFeatureAll(true)}>
                  all
                </Button>
                |
                <Button size="small" onClick={this.onFeatureAll(false)}>
                  none
                </Button>
              </div>
            </ListItem>
            {apps.map((app, index) => {
              const canMoveDown = app.isFeatured && index < featuredTally - 1;
              const canMoveUp = app.isFeatured && index > 0;
              return (
                <ListItem
                  key={index}
                  className={cx([
                    classes.appRow,
                    {
                      [classes.appEnabled]: app.isEnabled > 0,
                      [classes.appFeatured]: app.isFeatured > 0,
                    },
                  ])}>
                  <div className={classes.reorderContainer}>
                    <Tooltip title="Move Up" placement="top">
                      <span
                        className={cx([classes.moveIcon, { [classes.moveable]: canMoveUp }])}
                        onClick={this.onMoveApp(app, -1)}>
                        <MoveUpIcon />
                      </span>
                    </Tooltip>
                    <Tooltip title="Move Down" placement="bottom">
                      <span
                        className={cx([classes.moveIcon, { [classes.moveable]: canMoveDown }])}
                        onClick={this.onMoveApp(app, 1)}>
                        <MoveDownIcon />
                      </span>
                    </Tooltip>
                  </div>

                  <div className={classes.thumbnailContainer}>
                    {app.thumbnail && <img src={app.thumbnail} alt={app.title} />}
                  </div>

                  <ListItemText
                    primary={app.title}
                    secondary={app.url}
                    classes={{
                      root: classes.listItemTextRoot,
                      secondary: classes.listItemTextSecondary,
                    }} />

                  <div className={classes.checkboxContainer}>
                    <Tooltip title="Include this App in Launcher" placement="top">
                      <Checkbox checked={app.isEnabled} color="primary" onChange={this.onToggleGroupApp(app)} />
                    </Tooltip>
                  </div>

                  <div className={classes.checkboxContainer}>
                    <Tooltip title="Feature this App" placement="top">
                      <span style={{ cursor: 'pointer' }} onClick={this.onToggleGroupFeaturedApp(app)}>
                        {app.isFeatured && <FeaturedIcon className={classes.featuredIcon} />}
                        {!app.isFeatured && <NotFeaturedIcon className={classes.notFeaturedIcon} />}
                      </span>
                    </Tooltip>
                  </div>
                </ListItem>
              );
            })}
          </List>
          {this.state.confirmingDeletion && (
            <ConfirmDialog
              title={'Are you sure?'}
              body={`Are you sure you want to delete ${group.name}? All users will lose access to the client.`}
              onConfirm={this.onConfirmDelete}
              confirmButtonText={'Delete'}
              onCancel={this.onCancelDelete} />
          )}
        </Paper>
      </DefaultPageLayout>
    );
  }
}

export default withStyles(styles)(Groups);
