import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { KeyboardArrowRight, Settings } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import SupportIconSelected from '../assets/icons/SupportIconSelected';
import Skin from '../config/skin';

const styles = (theme) => ({
  container: {
    paddingBottom: 50,
    [theme.breakpoints.down('xs')]: {
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
      minHeight: '100vh',
      alignContent: 'flex-start'
    }
  },
  title: {
    marginTop: 20,
    textAlign: 'center',
    '& svg': {
      width: 60,
      height: 60,
      background: theme.palette.primary.light,
      borderRadius: 30,
      [theme.breakpoints.down('xs')]: {
        background: 'transparent',
        borderRadius: 0
      }
    }
  },
  channelContainer: {
    padding: '20px 15px 0',
    justifyContent: 'center'
  },
  channelInner: {
    borderRadius: 20,
    padding: '15px 30px',
    color: theme.palette.common.white,
    background: theme.palette.primary.light,
    [theme.breakpoints.down('xs')]: {
      background: 'rgba(255,255,255,0.1)'
    }
  },
  channelInfo: {
    display: 'flex',
    alignItems: 'center',
    color: 'inherit',
    textDecoration: 'none',
    whiteSpace: 'nowrap'
  },
  channelValue: {
    flex: 1,
    paddingLeft: theme.spacing.unit,
    '@media (max-width: 354px)': {
      fontSize: 16
    },
    '@media (min-width: 355px) and (max-width: 400px)': {
      fontSize: 20
    },
    '@media (min-width: 600px) and (max-width: 690px)': {
      fontSize: 12
    },
    '@media (min-width: 691px) and (max-width: 1100px)': {
      fontSize: 16
    },
    '@media (min-width: 1101px) and (max-width: 1500px)': {
      fontSize: 20
    },
  },
  channelIcon: {
    width: 24,
    height: 24
  },
  channelArrow: {
    opacity: 0.75,
    marginRight: -15
  }
});

const Support = ({ classes }) => (
  <Grid
    container
    className={classes.container}>
    <Grid
      item
      xs={12}
      className={classes.title}>
      <SupportIconSelected />
      <Typography variant="h5" color="inherit">Got a question?</Typography>
      <Typography variant="h5" color="inherit">We can help.</Typography>
    </Grid>

    { Skin.myColliers &&
      <Grid container className={classes.channelContainer}>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          className={classes.channelInner}>
          <Typography variant="subtitle1" color="inherit">Technical Support</Typography>
          <a
            className={classes.channelInfo}
            href={Skin.supportTechnical}
            target="_blank"
            rel="noopener noreferrer">
            <Settings className={classes.channelIcon} />
            <Typography
              variant="h5"
              color="inherit"
              className={classes.channelValue}>
              Contact the Service Desk
            </Typography>
            <KeyboardArrowRight className={classes.channelArrow} />
          </a>
        </Grid>
      </Grid>
    }

  </Grid>
);

Support.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(Support);
