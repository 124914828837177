import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { CATEGORIES } from '../../constants/categories';

const styles = () => ({
  chipDiv: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chips: {
    marginRight: '8px', 
    marginBottom: '8px'
  },
  error: {
    color: 'red',
    margin: '8px 12px 0',
    fontSize: '0.75rem',
    textAlign: 'left',
    minHeight: '1em',
    lineHeight: '1em',
    font: 'Open Sans, Arial, sans-serif'
  },
  formControl: {
    width: '100%'
  }
});

class CategoryChips extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    input: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      value: PropTypes.array.isRequired
    }),
    meta: PropTypes.shape({
      error: PropTypes.string,
      touched: PropTypes.bool
    })
  }

  constructor(props) {
    super(props);
    this.state = {
      value: props.input.value || []
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.input.value !== this.props.input.value) {
      this.setState({ value: this.props.input.value });
    }
  }
  
  handleChange = (event) => {
    const val = event.target.value;
    this.setState({ value: val });
    this.props.input.onChange(val);
  };

  handleDelete = (chip) => {
    const index = this.state.value.indexOf(chip);
    const newSelected = [...this.state.value];
    newSelected.splice(index, 1);
    this.props.input.onChange(newSelected);
  };

  render() {
    const { classes, meta: { error, touched } } = this.props;
    return (
      <FormControl className={classes.formControl}>
        <Select
          multiple
          name="category"
          value={this.state.value}
          error={touched && error}
          onChange={this.handleChange}
          input={<Input />}
          renderValue={(selected) => (
            <div className={classes.chipDiv}>
              {selected.map((value) => (
                <Chip 
                  key={value} 
                  label={value}
                  onDelete={this.handleDelete}
                  className={classes.chips} />
              ))}
            </div>
          )}>
          {CATEGORIES.map((category) => (
            <MenuItem key={category} value={category}>
              <Checkbox checked={this.state.value.indexOf(category) > -1} />
              {category}
            </MenuItem>
          ))}
        </Select>
        {touched && error && <span className={classes.error}>{error}</span>}
      </FormControl>
    );
  }
}

export default withStyles(styles)(CategoryChips);
