import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setPageTitles } from '../app/actions';
import skin from '../config/skin';
import ProjectRoom from './ProjectRoom';
import * as actions from './actions';

const mapStateToProps = () => {
  return {
    logoUrl: `${window.location.origin}${skin.logoUrl}`
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ ...actions, setPageTitles }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectRoom);
