import { CLOSE_NAV_DRAWER, OPEN_NAV_DRAWER, TOGGLE_NAV_DRAWER } from '../constants/actionTypes';

export default (state = { navDrawerOpen: false }, action) => {
  switch (action.type) {
    case CLOSE_NAV_DRAWER:
      return {
        ...state,
        navDrawerOpen: false
      };
    case OPEN_NAV_DRAWER:
      return {
        ...state,
        navDrawerOpen: true
      };
    case TOGGLE_NAV_DRAWER:
      return {
        ...state,
        navDrawerOpen: !state.navDrawerOpen
      };
    default:
      return state;
  }
};
