import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';

const Option = ({ innerRef, isFocused, isSelected, innerProps, children }) => (
  <MenuItem
    buttonRef={innerRef}
    selected={isFocused}
    component="div"
    style={{ fontWeight: isSelected ? 500 : 400 }}
    {...innerProps}>
    {children}
  </MenuItem>
);

Option.propTypes = {
  children: PropTypes.string.isRequired,
  innerProps: PropTypes.object.isRequired,
  innerRef: PropTypes.func,
  isFocused: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

export default Option;