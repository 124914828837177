import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Chip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/core/styles';
import { emphasize } from '@material-ui/core/styles/colorManipulator';

const styles = (theme) => ({
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },  
});

class MultiValue extends React.Component {
  handleDelete = (event) => {
    this.props.removeProps.onClick();
    this.props.removeProps.onMouseDown(event);
  }

  render() {
    const { children, isFocused, classes } = this.props;
    return (
      <Chip
        tabIndex={-1}
        label={children}
        color="primary"
        className={classNames(classes.chip, {
          [classes.chipFocused]: isFocused,
        })}
        onDelete={this.handleDelete} />
    );
  }
}

MultiValue.propTypes = {
  children: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  isFocused: PropTypes.bool.isRequired,
  removeProps: PropTypes.object.isRequired,
};

export default withStyles(styles)(MultiValue);