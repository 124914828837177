import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Subheader from './Subheader';
import * as actions from './actions';

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(actions, dispatch);
};

export default connect(null, mapDispatchToProps)(Subheader);
