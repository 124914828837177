import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { AuthConsumer } from 'react-check-auth';
import { RegisterUser } from '@colliers-international/usage-tracker';
import { Route, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { ProjectRoomProvider } from '@colliers-international/project-room';
import { privateRoutes, publicRoutes } from '../routes';
import ConnectedNavDrawer from '../navDrawer/ConnectedNavDrawer';
import { auth } from '../auth/auth';
import logger from '../services/logger';
import FullScreenError from '../shared/FullScreenError';
import FullScreenLoader from '../shared/FullScreenLoader';
import ConnectedSplash from './ConnectedSplash';
import Permissions from './Permissions';

const styles = (theme) => ({
  routes: {
    width: '100%',
    overflowX: 'hidden',
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      overflowX: 'visible',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.xsToolbarHeight
    }
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    height: '100vh',
    marginLeft: 96,
    minHeight: '100vh',
    width: 'calc(100% - 96px)',
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      width: '100vw',
    },
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      width: 'calc(100% - 96px)',
      minHeight: 'calc(100% - 85px)',
    }
  }
});

function AuthenticatedApp({ 
  classes, 
  fetchUserPermissions, 
  history, 
  permissions, 
  permissionsFetched, 
  splashDismissed 
}) {
  const isAuthenticated = auth.isAuthenticated();
  const splashEnabled = (history.location.pathname === '/' && !splashDismissed);
  if (permissionsFetched) {
    return (
      <React.Fragment>
        {
          !isAuthenticated && splashEnabled && (
            <ConnectedSplash />
          )
        }
        {
          !isAuthenticated && !splashEnabled &&
            auth.login()
        }
        {
          isAuthenticated && (
            <React.Fragment>
            
              <AuthConsumer>
                {({ isLoading, userInfo }) => {
                  if (userInfo) {
                    // identify to fullstory with userinfo
                    // configure projectRoom provider with userinfo
                    const projectRoomConfig = {
                      apiUrl: process.env.REACT_APP_PROJECT_ROOM_API_URL,
                      token: localStorage.getItem('id_token'),
                      logger
                    };
                    const projectRoomUser = {
                      abilities: get(permissions, 'rules') || [],
                      identity: userInfo.email
                    };
                    return (
                      <ProjectRoomProvider config={projectRoomConfig} user={projectRoomUser}> 
                        <div className={classes.wrapper}>
                          <RegisterUser
                            enableTracking={process.env.REACT_APP_TRACKING}
                            matomoSiteId={process.env.REACT_APP_MATOMO_SITE_ID}
                            fullstoryOrgId={process.env.REACT_APP_FULLSTORY_ORG}
                            user={{
                              userId: userInfo.providerId,
                              displayName: userInfo.name,
                              email: userInfo.email,
                              userVars: userInfo }}>
                            <Route path="/" component={ConnectedNavDrawer} />
                            <div className={classes.routes}>
                              {publicRoutes}
                              {privateRoutes}
                            </div>
                          </RegisterUser>
                        </div> 
                      </ProjectRoomProvider>

                    );
                  } else if (isLoading) {
                    return <FullScreenLoader />;
                  }
                  return (
                    <FullScreenError message="An authentication error has occured." />
                  );
                }}
              </AuthConsumer>
            </React.Fragment>
          )
        }
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Permissions fetchUserPermissions={fetchUserPermissions} />
      <FullScreenLoader />
    </React.Fragment>
  );
}

AuthenticatedApp.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchUserPermissions: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  permissions: PropTypes.object,
  permissionsFetched: PropTypes.bool.isRequired,
  splashDismissed: PropTypes.bool.isRequired,
};

export const StyledAuthenticatedApp = withStyles(styles)(AuthenticatedApp);
export default withRouter(StyledAuthenticatedApp);