import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  formControlWrapper: {
    paddingLeft: theme.spacing.unit,
    minWidth: theme.spacing.unit * 14
  },
});

const FormControls = ({ handleCancel, handleConfirm, classes }) => (
  <div className={classes.formControlWrapper}>
    <IconButton onClick={handleConfirm}><DoneIcon /></IconButton>
    <IconButton onClick={handleCancel}><ClearIcon /></IconButton>
  </div>
);

FormControls.propTypes = {
  classes: PropTypes.object.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired
};

export default withStyles(styles)(FormControls);
