import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  placeholder: {
    position: 'absolute',    
    left: 2,
    bottom: 5,
    fontSize: 16,
  },
};

const Placeholder = ({ classes, innerProps, children }) => (
  <Typography
    color="textSecondary"
    className={classes.placeholder}
    {...innerProps}>
    {children}
  </Typography>
);

Placeholder.propTypes = {
  children: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  innerProps: PropTypes.object,
};

export default withStyles(styles)(Placeholder);