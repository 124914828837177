import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

const styles = {
  fieldset: {
    alignItems: 'center',
    border: [1, 'solid', 'rgba(0, 0, 0, 0.23)'],
    borderRadius: 4,
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    margin: 0,
    padding: 8,
    width: '100%',
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      borderRadius: 0,
    }
  },
  formControl: {
    width: '100%'
  },
  label: {
    transformOrigin: ['top', 'left']
  },
  legend: {
    height: 2,
    width: 68
  },
  wrapper: {
    width: '100%'
  }
};

const OutlinedWrapper = ({ children, classes, label, labelWidth }) => (
  <FormControl className={classes.formControl} variant="outlined">
    <InputLabel className={classes.label} shrink variant="outlined">{label}</InputLabel>
    <div>
      <fieldset className={classes.fieldset}>
        <legend className={classes.legend} style={{ width: labelWidth }} />
        <div className={classes.wrapper}>
          {children}
        </div>
      </fieldset>
    </div>
  </FormControl>
);

OutlinedWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  labelWidth: PropTypes.number
};

export default withStyles(styles)(OutlinedWrapper);
