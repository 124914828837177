import React from 'react';
import PropTypes from 'prop-types';
import Default from './Default';
import Doc from './Doc';
import Pdf from './Pdf';
import Ppt from './Ppt';
import Xls from './Xls';

const FileIcon = ({ contentType, fileExt, size }) => {
  switch (contentType) {
    case 'application/pdf':
      return <Pdf size={size} />;

    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.template':
    case 'application/vnd.ms-word.document.macroEnabled.12':
    case 'application/vnd.ms-word.template.macroEnabled.12':
      return <Doc size={size} />;

    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.template':
    case 'application/vnd.ms-excel.sheet.macroEnabled.12':
    case 'application/vnd.ms-excel.template.macroEnabled.12':
    case 'application/vnd.ms-excel.addin.macroEnabled.12':
    case 'application/vnd.ms-excel.sheet.binary.macroEnabled.12':
      return <Xls size={size} />;

    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    case 'application/vnd.openxmlformats-officedocument.presentationml.template':
    case 'application/vnd.openxmlformats-officedocument.presentationml.slideshow':
    case 'application/vnd.ms-powerpoint.addin.macroEnabled.12':
    case 'application/vnd.ms-powerpoint.presentation.macroEnabled.12':
    case 'application/vnd.ms-powerpoint.template.macroEnabled.12':
    case 'application/vnd.ms-powerpoint.slideshow.macroEnabled.12':
      return <Ppt size={size} />;
      
    default:
      return <Default fileExt={fileExt} size={size} />;
  }
};

FileIcon.propTypes = {
  contentType: PropTypes.string.isRequired,
  fileExt: PropTypes.string.isRequired,
  size: PropTypes.number
};

export default FileIcon;
