import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { compose } from 'redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { TextField } from 'redux-form-material-ui';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import MaterialMarkdown from '../../shared/MaterialMarkdown';
import OutlinedWrapper from '../../shared/OutlinedWrapper';
import Subheader from '../../shared/subheader/ConnectedSubheader';
import CategoryChips from './CategoryChips';
import ChipsField from './ChipsField';
import GalleryField from './GalleryField';
import ImageField from './ImageField';

const styles = (theme) => ({
  actionButtons: {
    padding: theme.spacing.unit * 4
  },
  avatarField: {
    alignItems: 'center',
    border: [1, 'solid', 'rgba(0, 0, 0, 0.23)'],
    borderRadius: 4,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      borderRadius: 0,
    }
  },
  basic: {
    display: 'flex',
    flexDirection: 'column'
  },
  basicText: {
    display: 'flex',
    flex: 2,
    flexDirection: 'column',
    marginRight: theme.spacing.unit * 2
  },
  basicWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing.unit * 2
  },
  cancelButton: {
    marginRight: theme.spacing.unit * 2
  },
  category: {
    marginBottom: theme.spacing.unit * 2
  },
  contactText: {
    display: 'flex',
    flex: 2,
    flexDirection: 'column',
    marginRight: theme.spacing.unit * 2
  },
  description: {
    flexDirection: 'column'
  },
  divider: {
    margin: [theme.spacing.unit * 3, 0]
  },
  featuredWrapper: {
    marginTop: theme.spacing.unit * 2
  },
  field: {
    marginTop: theme.spacing.unit * 2,
  },
  galleryWrapper: {
    display: 'flex',
    flex: 1,
    marginLeft: theme.spacing.unit
  },
  longDescription: {
    flex: 1,
    flexBasis: 333,
  },
  preview: {
    flex: 1,
    flexBasis: 333,
    marginLeft: theme.spacing.unit,
    '& *': {
      fontFamily: [theme.typography.fontFamily, '!important']
    }
  },
  price: {
    flex: 1
  },
  section: {
    display: 'flex',
    maxWidth: 1100,
    padding: [0, theme.spacing.unit * 4]
  },
  split: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  submitButton: {
    backgroundColor: theme.palette.primary.main,
  },
  switch: {
    marginTop: 0
  },
  title: {
    margin: [theme.spacing.unit * 2, theme.spacing.unit * 4]
  }
});

const required = (val) => val ? undefined : 'Required';

const validate = (val) => {
  const errors = {};
  if (!val.category || !val.category.length) {
    errors.category = 'Please select a category';
  }
  return errors;
};

export const AppsForm = ({
  app = { contact: {} },
  classes,
  onCancel,
  handleSubmit,
  rawDescription = '',
  reset,
  showHeader = true
}) => {
  function handleCancel() {
    reset();
    if (onCancel) onCancel();
  }

  const actionButtons = (
    <div className={classes.actionButtons}>
      <Button
        className={classes.cancelButton}
        color="secondary"
        onClick={handleCancel}
        variant="outlined">
        Cancel
      </Button>
      <Button
        className={classes.submitButton}
        color="primary"
        type="submit"
        variant="contained">
        Submit
      </Button>
    </div>
  );

  return (
    <React.Fragment>
      <form autoComplete="off" onSubmit={handleSubmit}>
        {showHeader &&
          <Subheader>
            {actionButtons}
          </Subheader>
        }
        <Typography className={classes.title} variant="button">Basic Info</Typography>
        <div className={classnames(classes.section, classes.basic)}>
          <div className={classes.basicWrapper}>
            <div className={classes.basicText}>
              <Field
                className={classnames(classes.field)}
                component={TextField}
                fullWidth
                label="Name of App"
                name="title"
                type="text"
                validate={[required]}
                value={app.title}
                variant="outlined" />
              <Field
                className={classnames(classes.field)}
                component={TextField}
                fullWidth
                label="Link To Book Training"
                name="trainingUrl"
                type="text"
                value={app.trainingUrl}
                variant="outlined" />
              <Field
                className={classnames(classes.field)}
                component={TextField}
                fullWidth
                label="Link To External Launch"
                name="url"
                type="text"
                value={app.url}
                variant="outlined" />
            </div>
            <div className={classnames(classes.avatarField, classes.field)}>
              <Field name="thumbnail" component={ImageField} />
            </div>
          </div>
          <div className={classes.category}>
            <OutlinedWrapper label="Category" labelWidth={60}>
              <Field
                name="category"
                component={CategoryChips} />
            </OutlinedWrapper>
          </div>
          <div>
            <OutlinedWrapper label="Tags" labelWidth={38}>
              <Field name="tags" component={ChipsField} />
            </OutlinedWrapper>
          </div>
        </div>
        <Divider className={classes.divider} />
        <Typography className={classes.title} variant="button">Description</Typography>
        <div className={classnames(classes.section, classes.description)}>
          <div className={classes.descriptionText}>
            <Field
              className={classnames(classes.field)}
              component={TextField}
              fullWidth
              label="Short Summary"
              multiline
              name="summary"
              type="text"
              value={app.summary}
              variant="outlined" />
            <div className={classes.split}>
              <Field
                className={classnames(classes.field, classes.longDescription)}
                component={TextField}
                label="Long Description"
                multiline
                name="description"
                type="text"
                value={app.description}
                variant="outlined" />
              <div className={classnames(classes.field, classes.preview)}>
                <OutlinedWrapper label="Preview" labelWidth={50}>
                  <MaterialMarkdown skipHtml source={rawDescription || '<br />'} />
                </OutlinedWrapper>
              </div>
            </div>
          </div>
          <div className={classes.split}>
            <div className={classes.price}>
              <Field
                className={classnames(classes.field)}
                component={TextField}
                fullWidth
                label="Price"
                name="cost"
                type="text"
                value={app.cost}
                variant="outlined" />
            </div>
            <div className={classnames(classes.field, classes.galleryWrapper)}>
              <OutlinedWrapper label="Add Image" labelWidth={68}>
                <Field name="gallery" component={GalleryField} value={app.gallery} />
              </OutlinedWrapper>
            </div>
          </div>
        </div>
        <Divider className={classes.divider} />
        <Typography className={classes.title} variant="button">Contact</Typography>
        <div className={classes.section}>
          <div className={classes.contactText}>
            <Field
              className={classnames(classes.field)}
              component={TextField}
              fullWidth
              label="Full Name"
              name="contact.name"
              type="text"
              validate={[required]}
              value={app.contact.name}
              variant="outlined" />
            <Field
              className={classnames(classes.field)}
              component={TextField}
              fullWidth
              label="Title"
              name="contact.title"
              type="text"
              value={app.contact.title}
              variant="outlined" />
            <Field
              className={classnames(classes.field)}
              component={TextField}
              fullWidth
              label="Phone Number"
              name="contact.phone"
              type="text"
              value={app.contact.phone}
              variant="outlined" />
            <Field
              className={classnames(classes.field)}
              component={TextField}
              fullWidth
              label="Email Address"
              name="contact.email"
              type="text"
              value={app.contact.email}
              variant="outlined" />
          </div>
          <div className={classnames(classes.avatarField, classes.field)}>
            <Field name="contact.thumbnail" component={ImageField} />
          </div>
        </div>
        <Divider className={classes.divider} />
        {actionButtons}
      </form>
    </React.Fragment>
  );
};

AppsForm.propTypes = {
  app: PropTypes.shape({
    title: PropTypes.string
  }),
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  rawDescription: PropTypes.string,
  reset: PropTypes.func.isRequired,
  showHeader: PropTypes.bool
};

export const initialValues = (values) => {
  return {
    category: [],
    gallery: [],
    tags: [],
    ...values
  };
};

const selector = formValueSelector('apps');

export default compose(
  withStyles(styles),
  reduxForm({
    form: 'apps',
    initialValues: initialValues(),
    validate
  }),
  connect((state) => ({
    rawDescription: selector(state, 'description')
  }))
)(AppsForm);
