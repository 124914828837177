import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from 'react-router-dom';
import { AuthConsumer } from 'react-check-auth';
import CloseIcon from '@material-ui/icons/KeyboardArrowLeft';
import OpenIcon from '@material-ui/icons/KeyboardArrowRight';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Can from '../config/ability';
import ApplicationsIconSelected from '../assets/icons/ApplicationsIconSelected';
import ApplicationsIconUnselected from '../assets/icons/ApplicationsIconUnselected';
import ProjectsIconSelected from '../assets/icons/ProjectsIconSelected';
import ProjectsIconUnselected from '../assets/icons/ProjectsIconUnselected';
import SupportIconSelected from '../assets/icons/SupportIconSelected';
import SupportIconUnselected from '../assets/icons/SupportIconUnselected';
import NavBackground from '../assets/images/building_side_nav_illustration_white.png';
import ConnectedLogo from '../header/ConnectedLogo';
import Skin from '../config/skin';

const styles = (theme) => ({
  fullHeight: {
    height: '100%'
  },
  drawerPaper: {
    background: `url("${NavBackground}"), linear-gradient(
      to right,
      ${theme.palette.primary.main} 0%,
      ${theme.palette.primary.darkish} 85%,
      ${theme.palette.primary.dark} 100%)`,
    backgroundColor: theme.palette.primary.main,
    backgroundPosition: 'bottom left',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '375px auto',
    borderRight: 0,
    height: '100%',
    minHeight: '100vh',
    position: 'fixed',
    whiteSpace: 'nowrap',
    width: '375px',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      backgroundSize: '100% auto',
    }
  },
  centerLogo: {
    margin: '0 auto'
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 12,
    },
  },
  drawerToggle: {
    bottom: theme.spacing.unit * 2,
    color: theme.palette.common.white,
    opacity: 0.75,
    position: 'absolute',
    right: '19px',
    '&:hover': {
      backgroundColor: 'transparent',
      opacity: 1,
    },
    '& svg': {
      height: '32px',
      width: '32px'
    }
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    height: 85,
    ...theme.mixins.toolbar,
    [theme.breakpoints.down('xs')]: {
      height: 72
    },
    color: theme.palette.common.white,
  },
  headerDrawerToggle: {
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: 'transparent',
      opacity: 1,
    },
    '& svg': {
      height: '36px',
      width: '36px',
    }
  },
  drawerDivider: {
    backgroundColor: theme.palette.primary.main,
  },
  list: {
    marginTop: theme.spacing.unit * 8,
  },
  listItem: {
    padding: [18, 0, 18, 0],
    '& svg': {
      height: '40px',
      width: '44px',
    },
    '& div': {
      opacity: 0.5,
      paddingLeft: '25px',
    },
  },
  listItemActive: {
    backgroundColor: theme.palette.primary.light,
    borderLeft: `5px solid ${theme.palette.colliersYellow}`,
    '& div': {
      opacity: 1,
      paddingLeft: '20px',
    },
  },
  navItemText: {
    color: theme.palette.common.white,
    fontSize: '1.3em',
  }
});

class NavDrawer extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    closeNavDrawer: PropTypes.func.isRequired,
    groupConfig: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    navDrawerOpen: PropTypes.bool.isRequired,
    toggleNavDrawer: PropTypes.func.isRequired,
  }

  renderResponsively(variant) {
    const {
      classes,
      groupConfig,
      location,
      navDrawerOpen,
      toggleNavDrawer,
      closeNavDrawer
    } = this.props;
    const isApplicationsActive = location.pathname === '/launcher';
    const isProjectsActive = location.pathname === '/projectRoom';
    const isSupportActive = location.pathname === '/support';

    function onClickLink(e) {
      // permanent should not toggle on link click (so stop propagation)
      if (variant === 'permanent') {
        e.stopPropagation();

      // temporary should close when link clicked
      } else if (variant === 'temporary' && navDrawerOpen) {
        closeNavDrawer();
      }
    }

    // if click on permanent drawer (other than link), should toggle
    function onClickDrawer() {
      if (variant === 'permanent') {
        toggleNavDrawer();
      }
    }

    function getTooltipProps(title, isActive) {
      return {
        title: navDrawerOpen ? '' : `${title}${isActive ? ' (selected)' : ''}`,
        placement: 'right'
      };
    }

    return (
      <AuthConsumer>
        {({ userInfo }) => {
          if (userInfo) {
            return (
              <Drawer
                anchor="left"
                variant={variant}
                open={navDrawerOpen}
                onClick={onClickDrawer}
                ModalProps={{ hideBackdrop: true }}
                classes={{
                  paper: classNames(
                    classes.drawerPaper,
                    !navDrawerOpen && classes.drawerPaperClose,
                  ),
                }}>

                <Hidden xsDown>
                  <div className={classes.drawerHeader} />
                </Hidden>
                <Hidden smUp implementation="css">
                  <div className={classes.drawerHeader}>
                    <IconButton
                      className={classes.headerDrawerToggle}
                      disableRipple={true}
                      onClick={toggleNavDrawer}>
                      <ArrowBackIcon color="inherit" />
                    </IconButton>
                  </div>
                  <ConnectedLogo className={classes.centerLogo} />
                </Hidden>

                <Divider className={classes.drawerDivider} />

                <List className={classes.list}>
                  {groupConfig.launcher && (
                    <Can do="read" on="apps">
                      <Tooltip {...getTooltipProps('Applications', isApplicationsActive)}>
                        <ListItem
                          className={classNames(classes.listItem, isApplicationsActive && classes.listItemActive)}
                          button
                          component={Link}
                          to="/launcher"
                          onClick={onClickLink}>
                          <ListItemIcon>
                            {
                              isApplicationsActive ?
                                <ApplicationsIconSelected /> :
                                <ApplicationsIconUnselected />
                            }
                          </ListItemIcon>
                          <ListItemText
                            className={classes.navItemText}
                            primary="Applications"
                            disableTypography={true} />
                        </ListItem>
                      </Tooltip>
                    </Can>
                  )}

                  {groupConfig.projectRooms && !Skin.colliers360 && (
                    <Can do="read" on="projectRooms">
                      <Tooltip {...getTooltipProps('Projects', isProjectsActive)}>
                        <ListItem
                          className={classNames(classes.listItem, isProjectsActive && classes.listItemActive)}
                          button
                          component={Link}
                          to="/projectRoom"
                          onClick={onClickLink}>
                          <ListItemIcon>
                            {
                              isProjectsActive ?
                                <ProjectsIconSelected /> :
                                <ProjectsIconUnselected />
                            }
                          </ListItemIcon>
                          <ListItemText
                            className={classes.navItemText}
                            primary="Projects"
                            disableTypography={true} />
                        </ListItem>
                      </Tooltip>
                    </Can>
                  )}

                  <Tooltip {...getTooltipProps('Support', isSupportActive)}>
                    <ListItem
                      className={classNames(classes.listItem, isSupportActive && classes.listItemActive)}
                      button
                      component={Link}
                      to="/support"
                      onClick={onClickLink}>
                      <ListItemIcon>
                        {
                          isSupportActive ?
                            <SupportIconSelected /> :
                            <SupportIconUnselected />
                        }
                      </ListItemIcon>
                      <ListItemText
                        className={classes.navItemText}
                        primary="Support"
                        disableTypography={true} />
                    </ListItem>
                  </Tooltip>
                </List>

                <Hidden xsDown>
                  <IconButton
                    className={classes.drawerToggle}
                    disableRipple={true}>
                    {
                      navDrawerOpen ?
                        <CloseIcon /> :
                        <OpenIcon />
                    }
                  </IconButton>
                </Hidden>
              </Drawer>
            );
          }
        }}
      </AuthConsumer>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Hidden xsDown implementation="js" className={classes.fullHeight}>
          {this.renderResponsively('permanent')}
        </Hidden>
        <Hidden smUp implementation="js" className={classes.fullHeight}>
          {this.renderResponsively('temporary')}
        </Hidden>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(NavDrawer);
