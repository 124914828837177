import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
});

const NoOptionsMessage = ({ classes, innerProps, children }) => (
  <Typography
    color="textSecondary"
    className={classes.noOptionsMessage}
    {...innerProps}>
    {children}
  </Typography>
);

NoOptionsMessage.propTypes = {
  children: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  innerProps: PropTypes.object,
};

export default withStyles(styles)(NoOptionsMessage);
