import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toggleNavDrawer } from '../navDrawer/actions';
import Logo from './Logo';

const mapStateToProps = ({ app, navDrawer }) => {
  return {
    navDrawerOpen: navDrawer.navDrawerOpen,
    permissionsFetched: app.permissionsFetched
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ toggleNavDrawer }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Logo);
