import api from '../services/myColliersApi';
import {
  USER_APPS_READ_REQUEST,
  USER_APPS_READ_SUCCESS,
  USER_APPS_READ_ERROR,
  USER_APPS_RATINGS_REQUEST,
  USER_APPS_RATINGS_SUCCESS,
  USER_APPS_RATINGS_ERROR,
  USER_APPS_FILTER_ADD,
  USER_APPS_FILTER_REMOVE,
  USER_APPS_SORT_UPDATE,
} from '../constants/actionTypes';

function userAppsRatingsRequest(slugs) {
  return {
    type: USER_APPS_RATINGS_REQUEST,
    slugs
  };
}

function userAppsRatingsSuccess(response) {
  return {
    type: USER_APPS_RATINGS_SUCCESS,
    response
  };
}

function userAppsRatingsError(error) {
  return {
    type: USER_APPS_RATINGS_ERROR,
    error
  };
}

export function fetchUserAppsRatings(slugs) {
  return (dispatch) => {
    dispatch(userAppsRatingsRequest(slugs));
    return api.read(`apps/ratings/${slugs}`)
      .then((data) => dispatch(userAppsRatingsSuccess(data)))
      .catch((response) => {
        return dispatch(userAppsRatingsError(response));
      });
  };
}

function userAppsRequest() {
  return {
    type: USER_APPS_READ_REQUEST
  };
}

function userAppsSuccess(response) {
  return {
    type: USER_APPS_READ_SUCCESS,
    response
  };
}

function userAppsError(error) {
  return {
    type: USER_APPS_READ_ERROR,
    error
  };
}

export function fetchUserApps() {
  return (dispatch) => {
    dispatch(userAppsRequest());
    return api.read('apps')
      .then((data) => {
        dispatch(userAppsSuccess(data));
        /* NOTE (not currently displaying ratings, so no need for this call)
        dispatch(fetchUserAppsRatings(
          data.map(
            (x) => { return x.slug; }
          )
        ));
        */
      })
      .catch((response) => {
        return dispatch(userAppsError(response));
      });
  };
}

export function addTagToFilterBy(addTag) {
  return {
    type: USER_APPS_FILTER_ADD,
    addTag
  };
}

export function removeTagFromFilterBy(removeTag) {
  return {
    type: USER_APPS_FILTER_REMOVE,
    removeTag
  };
}

export function updateSort(newSort) {
  return {
    type: USER_APPS_SORT_UPDATE,
    newSort
  };
}

