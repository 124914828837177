import {
  APP_REVIEW_WRITE_REQUEST,
  APP_REVIEW_WRITE_SUCCESS,
  APP_REVIEW_WRITE_ERROR,
  APP_REVIEWS_READ_SUCCESS,
  APP_REVIEWS_READ_REQUEST,
  APP_REVIEWS_READ_ERROR,
  APP_REVIEWS_CLEAR,
} from '../../constants/actionTypes';
import api from '../../services/myColliersApi';

export function clearReviews() {
  return {
    type: APP_REVIEWS_CLEAR
  };
}

function reviewWriteRequest(data) {
  return {
    type: APP_REVIEW_WRITE_REQUEST,
    review: data.review
  };
}

function reviewWriteSuccess(response) {
  return {
    type: APP_REVIEW_WRITE_SUCCESS,
    response
  };
}

function reviewWriteError(error) {
  return {
    type: APP_REVIEW_WRITE_ERROR,
    error
  };
}

export function writeReview(data) {
  const slug = data.slug;
  return (dispatch) => {
    dispatch(reviewWriteRequest(data));
    return api.update(`apps/${slug}/ratings`, data, true)
      .then((data) => {
        dispatch(reviewWriteSuccess(data));
      })
      .catch((response) => {
        dispatch(reviewWriteError(response));
      });
  };
}

function readReviewsRequest(data) {
  return {
    type: APP_REVIEWS_READ_REQUEST,
    appSlug: data.slug,
    amount: data.amount,
  };
}

function readReviewsSuccess(response) {
  return {
    type: APP_REVIEWS_READ_SUCCESS,
    response
  };
}

function readReviewsError(error) {
  return {
    type: APP_REVIEWS_READ_ERROR,
    error
  };
}

export function fetchReviews(data) {
  const { slug, page, limit } = data;
  return (dispatch) => {
    dispatch(readReviewsRequest(data));
    return api.read(`apps/${slug}/ratings`, null, { page, limit })
      .then((data) => {
        dispatch(readReviewsSuccess(data));
      })
      .catch((response) => {
        return dispatch(readReviewsError(response));
      });
  };
}
