import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import get from 'lodash/get';
import * as actions from '../profile/actions';
import Legalese from './Legalese';

const mapStateToProps = ({ profile }) => {
  return {
    fetchingProfile: profile.isFetching,
    hasAcceptedTerms: get(profile, 'user.hasAcceptedTerms', false)
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(actions, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Legalese);
