import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import PlaceholderImage from '../assets/images/placeholder skyline.png';

const styles = (theme) => ({
  paper: {
    margin: 'auto',
  },
  image: {
    margin: '30px auto',
    width: theme.spacing.unit * 24,
    height: theme.spacing.unit * 12
  },
  text: {
    padding: theme.spacing.unit * 2,
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    margin: 'auto',
    width: '100%'
  }
});

const Placeholder = (props) => {
  const { classes } = props;
  return (
    <Paper className={classes.paper}>
      <div className={classes.wrapper}>
        <img
          className={classes.image}
          alt="No application logo available"
          src={PlaceholderImage} />
      </div>
      <Divider />
      <Typography className={classes.text}>
        There are currently no applications available to you. To fix this issue,
        please contact an admin to have your access level increased.
      </Typography>
    </Paper>
  );
};

Placeholder.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Placeholder);
