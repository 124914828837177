import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import appRoutes from './apps/routes';
import ConnectedGroups from './groups/ConnectedGroups';
import ConnectedHeader from './header/ConnectedHeader';
import Landing from './Landing';
import ConnectedLauncher from './launcher/ConnectedLauncher';
import ConnectedProfile from './profile/ConnectedProfile';
import Support from './support/Support';
import ProjectRooms from './projectRoom/ProjectRooms';
import ConnectedProjectRoom from './projectRoom/ConnectedProjectRoom';

export const publicRoutes = (
  <React.Fragment>
    <Route path="/" component={ConnectedHeader} />
  </React.Fragment>
);

const Redirector = () => (<Redirect to="/" />)

export const privateRoutes = (
  <React.Fragment>
    <Route exact path="/" component={Landing} />
    <Route exact path="/dashboard" component={Redirector} />
    <Route path="/groups/:id?" component={ConnectedGroups} />
    <Route path="/launcher" component={ConnectedLauncher} />
    <Route path="/apps" component={appRoutes} />
    <Route path="/profile" component={ConnectedProfile} />
    <Route path="/support" component={Support} />
    <Route exact path="/leases" component={Redirector} />
    <Route path="/leases/*" component={Redirector} />
    <Route exact path="/setQlikAuth" component={Redirector} />
    <Route exact path="/projectRoom" component={ProjectRooms} />
    <Route exact path="/projectRoom/:id" component={ConnectedProjectRoom} />
  </React.Fragment>
);
