import React from 'react';
import PropTypes from 'prop-types';
import FileIcon, { defaultStyles } from 'react-file-icon';

const Doc = ({ size = 36 }) => (
  <FileIcon extension="docx" {...defaultStyles.docx} size={size} />
);

Doc.propTypes = {
  size: PropTypes.number
};

export default Doc;

