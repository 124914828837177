import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles, Typography } from '@material-ui/core';
import RatingStar from './RatingStar';

const styles = {
  centered: {
    textAlign: 'center'
  },
  clickable: {
    cursor: 'pointer',
  },
  disabled: {
    pointerEvents: 'none'
  },
  rating: {
    maxWidth: 120,
    whiteSpace: 'nowrap'
  },
};

class Rating extends Component {
  static propTypes = {
    averageRatings: PropTypes.object,
    classes: PropTypes.object,
    createAppRating: PropTypes.func,
    fetchRating: PropTypes.func,
    fixedRating: PropTypes.number,
    max: PropTypes.number,
    onClick: PropTypes.func,
    ratings: PropTypes.object,
    readOnly: PropTypes.bool,
    shouldFetch: PropTypes.bool,
    showAverage: PropTypes.bool,
    slug: PropTypes.string,
  }

  static defaultProps = {
    hoverValue: 0,
    disabled: false,
    max: 5,
    readOnly: false,
    style: {},
  }

  constructor(props) {
    super(props);
    this.state = {
      hoverValue: 0
    };
  }

  componentDidMount() {
    const { fetchRating, slug, shouldFetch } = this.props;
    if (shouldFetch) {
      fetchRating(slug);
    }
  }

  handleMouseEnter = (i) => {
    if (!this.props.readOnly) {
      this.setState({
        hoverValue: i
      });
    }
  }

  handleClick = (i) => {
    if (!this.props.readOnly) {
      // if onClick is set, overrides the createAppRating func
      if (this.props.onClick) {
        this.props.onClick(i);
      } else {
        this.props.createAppRating({
          slug: this.props.slug,
          rating: i
        });
      }
    }
  }

  handleMouseLeave = () => {
    if (!this.props.readOnly) {
      this.setState({
        hoverValue: 0
      });
    }
  }

  render() {
    const {
      classes,
      max,
      readOnly,
      showAverage,
      averageRatings,
      fixedRating,
      ratings,
      slug
    } = this.props;

    let averageRatingRecord;
    let averageRating = null;
    let ratingRecord;
    let rating = null;

    if (fixedRating) {
      rating = fixedRating;
    } else if (averageRatings && ratings) {
      averageRatingRecord = averageRatings[slug];
      if (averageRatingRecord) {
        averageRating = averageRatingRecord.average;
      }
      ratingRecord = ratings[slug];
      if (ratingRecord) {
        rating = ratingRecord.rating;
      }
    }

    const ratingStars = [];

    for (let i = 1; i <= max; i++) {
      ratingStars.push(
        <RatingStar
          key={i}
          index={i}
          readOnly={readOnly}
          averageRating={averageRating}
          rating={rating}
          hoverValue={this.state.hoverValue}
          handleMouseEnter={this.handleMouseEnter}
          handleMouseLeave={this.handleMouseLeave}
          handleClick={this.handleClick} />
      );
    }
    let averageRatingText = null;

    if (averageRating > 0 && showAverage) {
      averageRatingText = (
        <Typography variant="caption" className={classes.centered}>
          {`Average: ${averageRating.toFixed(1)}`}
        </Typography>);
    }
    return (
      <React.Fragment>
        <div
          className={classnames(classes.rating,{ [classes.clickable]:!readOnly })}>
          {ratingStars}
        </div>
        {averageRatingText}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Rating);
