import 'react-app-polyfill/ie11';
import 'core-js/es/string';
import 'core-js/es/array';
import 'url-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Root from './Root';
import registerServiceWorker from './registerServiceWorker';

ReactDOM.render(
  <Root />,
  document.getElementById('root')
);

registerServiceWorker();
