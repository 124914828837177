import { goBack, push } from 'connected-react-router';
import api from '../services/myColliersApi';
import {
  APP_READ_REQUEST,
  APP_READ_SUCCESS,
  APP_READ_ERROR,
  APP_CREATE_REQUEST,
  APP_CREATE_SUCCESS,
  APP_CREATE_ERROR,
  APP_UPDATE_REQUEST,
  APP_UPDATE_SUCCESS,
  APP_UPDATE_ERROR,
  APP_CLEAR,
} from '../constants/actionTypes';

export function navigateBack() {
  return (dispatch) => {
    return dispatch(goBack());
  };
}

export function clearApp() {
  return {
    type: APP_CLEAR
  };
}

function appReadRequest() {
  return {
    type: APP_READ_REQUEST
  };
}

function appReadSuccess(response) {
  return {
    type: APP_READ_SUCCESS,
    response
  };
}

function appReadError(error) {
  return {
    type: APP_READ_ERROR,
    error
  };
}

export function fetchApp(slug) {
  return (dispatch) => {
    dispatch(appReadRequest(slug));
    return api.read(`apps/${slug}`)
      .then((data) => dispatch(appReadSuccess(data)))
      .catch((response) => {
        return dispatch(appReadError(response));
      });
  };
}

function appCreateRequest() {
  return {
    type: APP_CREATE_REQUEST
  };
}

function appCreateSuccess(response) {
  return {
    type: APP_CREATE_SUCCESS,
    response
  };
}

function appCreateError(error) {
  return {
    type: APP_CREATE_ERROR,
    error
  };
}

export function createApp(data) {
  return (dispatch) => {
    dispatch(appCreateRequest(data));
    return api.create('apps', data)
      .then((data) => {
        dispatch(appCreateSuccess(data));
        dispatch(push(`/apps/${data.slug}`));
      })
      .catch((response) => {
        return dispatch(appCreateError(response));
      });
  };
}

function appUpdateRequest() {
  return {
    type: APP_UPDATE_REQUEST
  };
}

function appUpdateSuccess(response) {
  return {
    type: APP_UPDATE_SUCCESS,
    response
  };
}

function appUpdateError(error) {
  return {
    type: APP_UPDATE_ERROR,
    error
  };
}

export function updateApp(data) {
  return (dispatch) => {
    dispatch(appUpdateRequest(data));
    return api.update('apps', data)
      .then((data) => {
        dispatch(appUpdateSuccess(data));
        dispatch(push(`/apps/${data.slug}`));
      })
      .catch((response) => {
        return dispatch(appUpdateError(response));
      });
  };
}
