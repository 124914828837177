import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import Can from '../config/ability';
import ConfirmDialog from '../shared/ConfirmDialog';

const styles = (theme) => ({
  button: {
    padding: '14px 35px',
    fontWeight: 'bold',
    borderRadius: 0,
    marginLeft: theme.spacing.unit,
    [theme.breakpoints.down('xs')]: {
      padding: '14px',
    }
  },
  container: {
    display: 'flex'
  },
  pinButton: {
    color: theme.palette.grey[500],
    textTransform: 'none',
    '& svg': {
      fontSize: 20
    }
  },
  pinned: {
    color: theme.palette.primary.main
  },
  xsHide: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
});

class ActionButtons extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    className: PropTypes.string,
    classes: PropTypes.object.isRequired,
    deleteApp: PropTypes.func.isRequired,
    logAppInteraction: PropTypes.func.isRequired,
    pinUserApp: PropTypes.func.isRequired,
    unpinUserApp: PropTypes.func.isRequired
  };
  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = { confirmingDeletion: false };
  }

  handleDeleteClick = () => {
    this.setState({ confirmingDeletion: true });
  };

  onConfirmDelete = () => {
    const { app, deleteApp } = this.props;
    const { router } = this.context;
    
    this.setState({ confirmingDeletion: true });
    deleteApp(app.slug).then(() => router.history.push('/launcher'));
  }

  handleLaunchClick = (url) => () => {
    if (url) {
      this.props.logAppInteraction(this.props.app._id, this.props.app.title, 'launch');
      window.open(url, '_blank');
    }
  }

  handlePinButtonClick = () => {
    const { app } = this.props;
    if (app.pin) {
      this.props.unpinUserApp(app.slug);
    } else {
      this.props.pinUserApp(app.slug);
    }
    return false;
  };

  handleCancel = () => {
    this.setState({ confirmingDeletion: false });
  };

  render() {
    const { app, classes, className } = this.props;

    return (
      <div className={classNames([classes.container, className])}>
        <Can do="delete" on="apps">
          <Button
            className={classNames(classes.button, classes.editButton, classes.xsHide)}
            onClick={this.handleDeleteClick}
            variant="outlined">
            Delete
          </Button>
        </Can>
        <Can do="update" on="apps">
          <Button
            className={classNames(classes.button, classes.editButton, classes.xsHide)}
            color="primary"
            component={Link}
            to={`/apps/edit/${app.slug}`}
            variant="outlined">
            Edit
          </Button>
        </Can>
        {app.trainingUrl && (
          <Button
            className={classNames(classes.button, classes.trainingButton)}
            color="primary"
            component="a"
            href={app.trainingUrl}
            target={/^mailto:/i.test(app.trainingUrl) ? '_self' : '_blank'}
            variant="outlined">
            <span className={classes.xsHide}>Book&nbsp;</span>Training
          </Button>
        )}
        {app.pin ?
          <Button
            className={classNames(classes.button, classes.pinButton, classes.pinned)}
            color="primary"
            onClick={this.handlePinButtonClick}
            variant="outlined">
            <BookmarkIcon />
            <span>Pinned!</span>
          </Button>
          :
          <Button
            className={classNames(classes.button, classes.pinButton)}
            onClick={this.handlePinButtonClick}
            variant="outlined">
            <BookmarkIcon />
            <span>Pin</span>
          </Button>
        }
        <Button
          className={classNames(classes.button, classes.launchButton)}
          color="primary"
          disabled={!app.url}
          onClick={this.handleLaunchClick(app.url)}
          variant="contained">
          Launch
        </Button>

        {this.state.confirmingDeletion && (
          <ConfirmDialog
            title={'Oops!'}
            body={`Are you sure you want to delete ${app.title}? This cannot be undone.`}
            onConfirm={this.onConfirmDelete}
            confirmButtonText={'Delete'}
            onCancel={this.handleCancel} />
        )}
      </div>
    );
  }
}

export default withStyles(styles)(ActionButtons);
