// this returns the effective group config (reconciling either a selected "view as" client or the combined Colliers groups)
import get from 'lodash/get';

export const getGroupConfig = (groups, viewAsClientId) => {
  const config = {
    // sidenav tab display
    dashboard: false,
    launcher: false,
    leases: false,
    projectRooms: true,
    // other configs
    dashboardMashupConfiguration: null,
    launcherGroupIds: [],
    leasesClientId: null,
    leasesDocumentConfiguration: null,
    tenantId: null,
  };

  if (groups) {
    const selectedTenant = groups.find((g) => g.id === viewAsClientId);
    if (selectedTenant) {
      config.tenantId = selectedTenant.tenantId;

      if (selectedTenant.isClient) {
        // if has dashboard mashup url, then show dashboard and configure
        if (selectedTenant.dashboardMashupUrl) {
          config.dashboard = true;
          config.dashboardMashupConfiguration = {
            mashupUrl: selectedTenant.dashboardMashupUrl,
            externalUrl: selectedTenant.dashboardExternalUrl,
            userEmailKey: selectedTenant.dashboardUserEmailKey,
          };
        }

        // EDW lease clients
        if (selectedTenant.leasesClientId) {
          config.leases = true;
          config.leasesClientId = selectedTenant.leasesClientId;
          config.leasesDocumentConfiguration = {
            layer: get(selectedTenant, 'axxerionLayer', ''),
            auth: get(selectedTenant, 'axxerionAuth', ''),
          };

        // API lease clients (self-serve)
        } else {
          config.leases = true;
          config.dashboard = true;
        }
      }

      // launcher config
      // if tenant is selected, use it's enabled apps; else, use composite from all internal groups
      // (only show tab if one of the launcherGroupIds has > 0 enabled apps)
      config.launcherGroupIds = [selectedTenant.id];
    } else {
      config.launcherGroupIds = groups.filter(({ isClient }) => !isClient).map(({ id }) => id);
    }
    config.launcher = !!groups.find((g) => config.launcherGroupIds.includes(g.id) && g.enabledApps.length > 0);
  }

  return config;
};

export const strip = (text) => {
  return text.replace(/^#+\s*/, '')
    .replace(/\[(.*?)\]\(.*?\)/g, '$1')
    .replace(/^#+\s*/gm, '')
    .replace(/\*\*(.*?)\*\*/g, '$1')
    .replace(/\*(.*?)\*/g, '$1')
    .replace(/!\[.*?\]\(.*?\)/g, '');
};

