import React from 'react';
import PropTypes from 'prop-types';
import FileIcon, { defaultStyles } from 'react-file-icon';

const fallbackStyles = {
  glyphColor: '#2ba5d8',
  stroke: '#2ba5d8',
  type: 'document'
};

const Default = ({ fileExt, size = 36 }) => {
  return (
    <FileIcon
      {...fallbackStyles}
      {...(fileExt && defaultStyles[fileExt] ? defaultStyles[fileExt] : {})}
      size={size} />
  );
};

Default.propTypes = {
  fileExt: PropTypes.string,
  size: PropTypes.number
};

export default Default;

