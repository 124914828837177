import {
  DASHBOARD_READ_REQUEST,
  DASHBOARD_READ_SUCCESS,
  DASHBOARD_READ_ERROR,
  DASHBOARD_REGISTER_REQUEST,
  DASHBOARD_REGISTER_SUCCESS,
  DASHBOARD_REGISTER_ERROR,
  DASHBOARD_SET_QLIK_AUTH,
} from '../constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case DASHBOARD_READ_REQUEST:
      return {
        ...state,
        isFetching: true,
        hasError: false
      };

    case DASHBOARD_READ_SUCCESS:
      return {
        ...state,
        isFetching: false,
        hasError: false,
        dashboard: action.response
      };

    case DASHBOARD_READ_ERROR:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        error: action.response
      };

    case DASHBOARD_REGISTER_REQUEST:
      return {
        ...state,
        isRegistered: false,
        hasError: false
      };

    case DASHBOARD_REGISTER_SUCCESS:
      return {
        ...state,
        isRegistered: true,
        iv: action.response.iv,
        hasError: false,
        userHash: action.response.userHash
      };

    case DASHBOARD_REGISTER_ERROR:
      return {
        ...state,
        isRegistered: false,
        hasError: true,
        error: action.response
      };

    case DASHBOARD_SET_QLIK_AUTH:
      return {
        ...state,
        isQlikAuthed: action.isQlikAuthed,
      };

    default:
      return state;
  }
};
