import React from 'react';
import { Provider } from 'react-redux';
import JssProvider from 'react-jss/lib/JssProvider';
import { create } from 'jss';
import jssExpand from 'jss-expand';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createGenerateClassName, createMuiTheme, jssPreset, MuiThemeProvider } from '@material-ui/core/styles';
import App from './app/ConnectedApp';
import ServiceWorker from './ServiceWorker';
import store from './store';
import defaultTheme from './defaultTheme';

const generateClassName = createGenerateClassName();
const jss = create({ plugins: [...jssPreset().plugins, jssExpand()] });

export const theme = createMuiTheme(defaultTheme);

const Root = () => (
  <React.Fragment>
    <Provider store={store}>
      <JssProvider jss={jss} generateClassName={generateClassName}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <ServiceWorker />
          <App />
        </MuiThemeProvider>
      </JssProvider>
    </Provider>
  </React.Fragment>
);

export default Root;
