import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete'; 
import FileIcon from '../fileIcon/FileIcon';
import { isImage } from './helpers/helpers.js';

const styles = (theme) => ({
  listItem: {
    border: '1px solid #EEE',
    marginBottom: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingTop: theme.spacing.unit,
    '&:hover $removeBtn': {
      opacity: 1
    }
  },
  listItemText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  list: {
    padding: theme.spacing.unit,
    width: '100%'
  },
  messageItem: {
    background: '#EEE',
  },
  messageItemText: {
    fontSize: 13,
    fontWeight: 'bold'
  },
  removeBtn: {
    opacity: 0
  },
  smallPreviewImg: {
    maxWidth: 24,
    maxHeight: 24,
    boxShadow: 'rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px'
  }
});

function PreviewList(props) {
  const { classes, fileObjects, handleRemove, previewMessage } = props;
  return (
    <List className={classes.list}>
      {
        fileObjects.map((fileObject, i) => {
          const file = fileObject.file;
          const fileName = file.name || file.path;
          const fileExt = fileName.substr(fileName.indexOf('.') + 1).toLowerCase();
          const img = isImage(file)
            ? <img className={classes.smallPreviewImg} alt={`${fileName} preview`} src={fileObject.data} />
            : <FileIcon contentType={file.type} fileExt={fileExt} size={24} />;
          return (
            <ListItem key={i} classes={{ container: classes.listItem }}>
              <ListItemIcon>
                {img}
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.listItemText }} primary={fileName} />
              <ListItemSecondaryAction>
                <IconButton className={classes.removeBtn} onClick={handleRemove(i)} aria-label="delete">
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })
      }
      {previewMessage && (
        <ListItem classes={{ root: classes.messageItem }}>
          <ListItemText classes={{ primary: classes.messageItemText }} primary={previewMessage} />
        </ListItem>
      )}
    </List>
  );
}

PreviewList.propTypes = {
  classes: PropTypes.object.isRequired,
  fileObjects: PropTypes.array.isRequired,
  handleRemove: PropTypes.func,
  previewMessage: PropTypes.string,
};

export default withStyles(styles)(PreviewList);
