import { FullStoryAPI as fullStoryAPI } from 'react-fullstory';
import api from '../services/myColliersApi';
import {
  DELETE_APP_REQUEST,
  DELETE_APP_SUCCESS,
  DELETE_APP_ERROR,
  USER_APP_INTERACTION,
  USER_APP_INTERACTION_ERROR,
  USER_APP_PIN_REQUEST,
  USER_APP_PIN_SUCCESS,
  USER_APP_PIN_ERROR,
  USER_APP_UNPIN_REQUEST,
  USER_APP_UNPIN_SUCCESS,
  USER_APP_UNPIN_ERROR
} from '../constants/actionTypes';

function deleteAppRequest(appSlug) {
  return {
    appSlug,
    type: DELETE_APP_REQUEST
  };
}

function deleteAppSuccess(appSlug) {
  return {
    appSlug,
    type: DELETE_APP_SUCCESS,
  };
}

function deleteAppError(appSlug, error) {
  return {
    appSlug,
    type: DELETE_APP_ERROR,
    error
  };
}
export function deleteApp(appSlug) {
  return (dispatch) => {
    dispatch(deleteAppRequest(appSlug));
    return api.destroy('apps', appSlug)
      .then(() => {
        return dispatch(deleteAppSuccess(appSlug));
      })
      .catch((response) => {
        return dispatch(deleteAppError(appSlug, response));
      });
  };
}

function userAppPinRequest(appSlug) {
  return {
    appSlug,
    type: USER_APP_PIN_REQUEST
  };
}

function userAppPinSuccess(response) {
  return {
    type: USER_APP_PIN_SUCCESS,
    response
  };
}

function userAppPinError(error) {
  return {
    type: USER_APP_PIN_ERROR,
    error
  };
}

export function pinUserApp(appSlug) {
  return (dispatch) => {
    dispatch(userAppPinRequest(appSlug));
    return api.update('apps', { id: `${appSlug}/pins` }, true)
      .then((data) => {
        dispatch(userAppPinSuccess(data));
      })
      .catch((response) => {
        return dispatch(userAppPinError(response));
      });
  };
}

function userAppUnpinRequest(appSlug) {
  return {
    appSlug,
    type: USER_APP_UNPIN_REQUEST
  };
}

function userAppUnpinSuccess(response) {
  return {
    type: USER_APP_UNPIN_SUCCESS,
    response
  };
}

function userAppUnpinError(error) {
  return {
    type: USER_APP_UNPIN_ERROR,
    error
  };
}

export function unpinUserApp(appSlug) {
  return (dispatch) => {
    dispatch(userAppUnpinRequest(appSlug));
    return api.destroy('apps', `${appSlug}/pins`)
      .then((data) => {
        dispatch(userAppUnpinSuccess(data));
      })
      .catch((response) => {
        return dispatch(userAppUnpinError(response));
      });
  };
}

export function logAppInteraction(appId, appTitle, action) {
  return (dispatch) => {
    // record fullstory event
    if (process.env.REACT_APP_FULLSTORY_ORG) {
      fullStoryAPI('event', action, { appId, appTitle });
    }

    // record with api
    return api.create('apps/interaction', { id: appId, action })
      .then((data) => {
        return dispatch({
          type: USER_APP_INTERACTION,
          id: appId,
          data
        });
      })
      .catch((response) => {
        return dispatch({
          type: USER_APP_INTERACTION_ERROR,
          id: appId,
          response
        });
      });
  };
}

