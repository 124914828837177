import api from '../../services/myColliersApi';
import {
  APP_RATING_READ_REQUEST,
  APP_RATING_READ_SUCCESS,
  APP_RATING_READ_ERROR,
  APP_RATING_WRITE_REQUEST,
  APP_RATING_WRITE_SUCCESS,
  APP_RATING_WRITE_ERROR,
  APP_AVG_RATING_READ_REQUEST,
  APP_AVG_RATING_READ_SUCCESS,
  APP_AVG_RATING_READ_ERROR,
} from '../../constants/actionTypes';

function appRatingReadRequest(slug) {
  return {
    type: APP_RATING_READ_REQUEST,
    slug
  };
}

function appRatingReadSuccess(response) {
  return {
    type: APP_RATING_READ_SUCCESS,
    response
  };
}

function appRatingReadError(error) {
  return {
    type: APP_RATING_READ_ERROR,
    error
  };
}

function appRatingWriteRequest(data) {
  return {
    type: APP_RATING_WRITE_REQUEST,
    rating: data.rating,
  };
}

function appRatingWriteSuccess(response) {
  return {
    type: APP_RATING_WRITE_SUCCESS,
    response,
  };
}

function appRatingWriteError(error) {
  return {
    type: APP_RATING_WRITE_ERROR,
    error,
  };
}

function appAvgRatingReadRequest(slug) {
  return {
    type: APP_AVG_RATING_READ_REQUEST,
    slug
  };
}

function appAvgRatingReadSuccess(response) {
  return {
    type: APP_AVG_RATING_READ_SUCCESS,
    response
  };
}

function appAvgRatingReadError(error) {
  return {
    type: APP_AVG_RATING_READ_ERROR,
    error
  };
}

export function fetchAvgRating(slug) {
  return (dispatch) => {
    dispatch(appAvgRatingReadRequest(slug));
    return api.read(`apps/${slug}/ratings/avg`)
      .then((data) => {
        return dispatch(appAvgRatingReadSuccess(data));
      })
      .catch((response) => {
        return dispatch(appAvgRatingReadError(response));
      });
  };
}

export function createAppRating(data) {
  const slug = data.slug;
  return (dispatch) => {
    dispatch(appRatingWriteRequest(data));
    return api.update(`apps/${slug}/ratings`, data, true)
      .then((data) => {
        dispatch(appRatingWriteSuccess(data));
        dispatch(fetchAvgRating(slug));
      })
      .catch((response) => {
        return dispatch(appRatingWriteError(response));
      });
  };
}

export function fetchRating(slug) {
  return (dispatch) => {
    dispatch(appRatingReadRequest(slug));
    return api.read(`apps/${slug}/ratings`)
      .then((data) => {
        dispatch(appRatingReadSuccess(data));
        dispatch(fetchAvgRating(slug));
      })
      .catch((response) => {
        return dispatch(appRatingReadError(response));
      });
  };
}
