import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'redux-form-material-ui';
import { Dialog, DialogContent, Divider, Typography, withStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Rating from '../../shared/rating/ConnectedRating';
import ActionButtons from './ActionButtons';

const styles = (theme) => ({
  dialog: {
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing.unit * 2
    }
  },
  dialogContent: {
    width: '400px',
    maxWidth: '100%'
  },
  dialogHeader: {
    display:'flex',
    justifyContent:'space-between',
    alignItems:'top',
  },
  dialogHeaderIcon: {
    color: theme.palette.secondary.light,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.7
    }
  },
  dialogTitle: {
    color: theme.palette.primary.dark,
    fontWeight: 'bold',
    lineHeight: 1
  },
  dialogSubtitle: {
    color: theme.palette.secondary.light,
    fontSize: '0.9rem',
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  dialogSectionHeader: {
    color: '#777',
    fontSize: '120%',
    fontWeight: 'bold'
  },
  divider: {
    margin: [theme.spacing.unit * 1.5, 0],
    width: '100%'
  },
  textField: {
    color: theme.palette.secondary.light,
    fontSize: '90%',
    fontWeight: 'bold',
  }
});

class ReviewFormDialog extends React.Component {
  static propTypes = {
    appSlug: PropTypes.string.isRequired,
    appTitle: PropTypes.string.isRequired,
    classes: PropTypes.object.isRequired,
    myReview: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    writeReview: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props);
    this.state = {
      rating: props.myReview ? props.myReview.rating : 0,
      review: props.myReview ? props.myReview.review : '',
    };
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value
    });
  }

  handleRatingChange = (rating) => {
    this.setState({ rating });
  }

  onSubmit = (event) => {
    event.preventDefault();

    // require a rating (don't require a review)
    if (this.state.rating > 0 && this.state.rating <= 5) {
      // save review
      this.props.writeReview({
        ...this.state,
        slug: this.props.appSlug
      });

      // close dialog
      this.props.onClose();
    }
  };

  render() {
    const { appTitle, classes, onClose } = this.props;
    return (
      <Dialog open={true} PaperProps={{ className: classes.dialog }}>
        <DialogContent className={classes.dialogContent}>
          <form
            autoComplete="off"
            noValidate
            onSubmit={this.onSubmit}>

            <div className={classes.dialogHeader}>
              <div>
                <Typography variant="h5" className={classes.dialogTitle}>
                  {this.state.rating > 0 ? 'Edit Your' : 'Create a'} Review
                </Typography>
                <Typography variant="caption" className={classes.dialogSubtitle}>
                  for {appTitle}
                </Typography>
              </div>
              <CloseIcon className={classes.dialogHeaderIcon} onClick={onClose} />
            </div>

            <Divider className={classes.divider} />

            <Typography variant="h6" className={classes.dialogSectionHeader}>Overall Rating</Typography>
            <Rating 
              fixedRating={this.state.rating}
              onClick={this.handleRatingChange} />

            <Divider className={classes.divider} />

            <Typography variant="h6" className={classes.dialogSectionHeader}>Write Your Review</Typography>
            <TextField
              inputProps={{ className: classes.textField }}
              fullWidth
              multiline
              onChange={this.handleChange('review')}
              rows="4"
              rowsMax="8"
              value={this.state.review}
              variant="outlined" />

            <ActionButtons />
          </form>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles)(ReviewFormDialog);