import React from 'react';
import PropTypes from 'prop-types';
import { AuthConsumer } from 'react-check-auth';
import queryString from 'query-string';
import { withStyles } from '@material-ui/core/styles';
import FullScreenError from '../shared/FullScreenError';
import FullScreenLoader from '../shared/FullScreenLoader';
import { auth } from '../auth/auth';
import VerifyEmailNotification from './VerifyEmailNotification';

const styles = (theme) => ({
  wrapper: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    height: '100vh',
    width: '100vw',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: theme.palette.common.white,
  }
});

class Callback extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null
    };
  }

  async componentDidMount() {
    if (/access_token|id_token|error/.test(this.props.location.hash)) {
      try {
        let redirect;
        if (this.props.location.search) {
          const search = queryString.parse(this.props.location.search);
          redirect = search.next;
        }
        await auth.handleAuthentication(redirect);
      } catch (err) {
        if (typeof(err) === 'function') return err();

        let error = 'Authentication failed. Please refresh and try again.';
        if (err.type === 'verify' || typeof(err) === 'string') {
          error = err;
        }

        this.setState({ error });
      }
    }
  }

  componentWillUnmount() {
    this.refreshAuth();
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.wrapper}>
        {this.state.error && this.state.error.type === 'verify' &&
          <VerifyEmailNotification redirect={this.state.error.redirect} />}
        {this.state.error && this.state.error.type !== 'verify' && <FullScreenError message={this.state.error} />}
        {!this.state.error && <FullScreenLoader />}
        <AuthConsumer>
          {({ refreshAuth }) => {
            this.refreshAuth = refreshAuth;
          }}
        </AuthConsumer>
      </div>
    );
  }
}

export default withStyles(styles)(Callback);
