export const COLLIERS_BLUE = '#25408f';
export const COLLIERS_CYAN = '#0c9ed9';
export const COLLIERS_RED = '#ed1a35';
export const COLLIERS_YELLOW = '#ffd400';

const defaultTheme = {
  overrides: {
    MuiTableCell: {
      body: {
        fontSize: '0.9rem',
      },
    },
    MuiTableRow: {
      root: {
        '&$selected': {
          borderBottom: 'none',
        },
      },
      selected: {},
    },
    MuiButton: {
      root: {
        borderRadius: 0
      }
    },
    MuiCardMedia: {
      root: {
        backgroundSize: 'contain'
      }
    },
    MuiChip: {
      clickableColorPrimary: {
        '&:hover span, &:active span, &:focus span': {
          color: 'white'
        }
      },
      colorPrimary: {
        backgroundColor: '#eef3ff',
        color: 'white'
      },
      deleteIcon: {
        color: 'rgba(37, 64, 143, 0.65) !important',
        '&:hover, &:active': {
          color: `${COLLIERS_BLUE} !important`
        }
      },
      label: {
        color: COLLIERS_BLUE,
        fontWeight: 'bold',
        textTransform: 'uppercase'
      },
      root: {
        height: 24,
        borderRadius: 6
      }
    },
  },
  typography: {
    fontFamily: '"Open Sans",Arial,sans-serif',
    useNextVariants: true,
    suppressWarning: true,
  },
  palette: {
    primary: {
      main: COLLIERS_BLUE,
      darkish: '#22387C',
    },
    secondary: {
      main: '#999999'
    },
    error: {
      main: COLLIERS_RED
    },
    warning: {
      main: COLLIERS_YELLOW
    },
    colliersBlue: COLLIERS_BLUE,
    colliersRed: COLLIERS_RED,
    colliersYellow: COLLIERS_YELLOW,
  },
  xsToolbarHeight: 72,
};

export default defaultTheme;
