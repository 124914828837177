import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Launcher from './Launcher';
import * as actions from './actions';

const mapStateToProps = ({ launcher, app: { groups, groupConfig }, rating }) => {
  return {
    hasError: false,
    isFetching: false,
    apps: [],
    ...launcher,
    ratings: {},
    averageRatings: {},
    ...rating,
    groups,
    groupConfig
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(actions, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Launcher);
