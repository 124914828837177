/* eslint-disable react/prop-types */

import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import NoSsr from '@material-ui/core/NoSsr';
import Option from './Option';
import Control from './Control';
import MultiValue from './MultiValue';
import NoOptionsMessage from './NoOptionsMessage';
import Placeholder from './Placeholder';
import ValueContainer from './ValueContainer';
import DropdownIndicator from './DropdownIndicator';

const styles = (theme) => ({
  root: {
    display: 'block',
    flexGrow: 1,
  },
  singleValue: {
    fontSize: 16,
  },
  paper: {
    marginTop: theme.spacing.unit,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
});

const selectComponents = {
  Option,
  Control,
  NoOptionsMessage,
  Placeholder,
  MultiValue,
  ValueContainer,
  DropdownIndicator
};

class SelectField extends React.Component {
  render() {
    const { classes, theme } = this.props;

    const selectStyles = {
      input: (base) => ({
        ...base,
        color: theme.palette.text.primary,
      }),
    };

    return (
      <div className={classes.root}>
        <NoSsr>
          <Select
            classes={classes}
            styles={selectStyles}
            options={this.props.options.map((opt) => ({ label: opt, value: opt }))}
            components={selectComponents}
            value={this.props.value}
            onChange={this.props.onChange}
            placeholder={`Select ${this.props.label}`}
            isClearable={false}
            isMulti />
        </NoSsr>
      </div>
    );
  }
}

SelectField.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(SelectField);
