import React from 'react';
import PropTypes from 'prop-types';
import FileIcon, { defaultStyles } from 'react-file-icon';

const Xls = ({ size = 36 }) => (
  <FileIcon extension="xls" {...defaultStyles.xlsx} size={size} />
);

Xls.propTypes = {
  size: PropTypes.number
};

export default Xls;

