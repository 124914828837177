import {
  APP_RATING_READ_REQUEST,
  APP_RATING_READ_SUCCESS,
  APP_RATING_READ_ERROR,
  APP_RATING_WRITE_REQUEST,
  APP_RATING_WRITE_SUCCESS,
  APP_RATING_WRITE_ERROR,
  APP_AVG_RATING_READ_REQUEST,
  APP_AVG_RATING_READ_SUCCESS,
  APP_AVG_RATING_READ_ERROR,
  USER_APPS_RATINGS_REQUEST,
  USER_APPS_RATINGS_SUCCESS,
  USER_APPS_RATINGS_ERROR,
} from '../../constants/actionTypes';

export default (state = { ratings : {}, averageRatings : {} }, action) => {
  switch (action.type) {
    case APP_RATING_READ_REQUEST: {
      return {
        ...state,
        areRatingsFetching: true,
        hasRatingsError: false,
      };
    }

    case APP_RATING_READ_SUCCESS: {
      if (JSON.stringify(action.response) === '{}') {
        return {
          ...state,
          areRatingsFetching: false,
          hasRatingsError: false,
        };
      }
      return {
        ...state,
        areRatingsFetching: false,
        hasRatingsError: false,
        ratings: {
          ...state.ratings,
          [action.response.appSlug]: {
            rating: action.response.rating,
            review: action.response.review
          }
        }
      };
    }

    case APP_RATING_READ_ERROR: {
      return {
        ...state,
        areRatingsFetching: false,
        hasRatingsError: true,
        ratingsError: action.response,
      };
    }

    case APP_RATING_WRITE_REQUEST: { 
      return {
        ...state,
        areRatingsFetching: true,
        hasRatingsError: false,
      };
    }

    case APP_RATING_WRITE_SUCCESS: {
      return {
        ...state,
        areRatingsFetching: false,
        hasRatingsError: false,
        ratings: {
          ...state.ratings,
          [action.response.appSlug]: {
            rating: action.response.rating,
            review: action.response.review
          }
        }
      };
    }

    case APP_RATING_WRITE_ERROR: { 
      return {
        ...state,
        areRatingsFetching: false,
        hasRatingsError: true,
        ratingsError: action.response,
      };
    }

    case APP_AVG_RATING_READ_REQUEST: { 
      return {
        ...state,
        areRatingsFetching: true,
        hasRatingsError: false,
      };
    }

    case APP_AVG_RATING_READ_SUCCESS: {
      if (Array.isArray(action.response) && action.response[0].appSlug) {
        return {
          ...state,
          areRatingsFetching: false,
          hasRatingsError: false,
          averageRatings: {
            ...state.averageRatings,
            [action.response[0].appSlug]: {
              average: action.response[0].average
            }
          }
        };
      }
      return {
        ...state,
        areRatingsFetching: false,
        hasRatingsError: false,
      };
    }

    case APP_AVG_RATING_READ_ERROR: { 
      return {
        ...state,
        areRatingsFetching: false,
        hasRatingsError: true,
        ratingsError: action.response,
      };
    }

    case USER_APPS_RATINGS_REQUEST: { 
      return {
        ...state,
        areRatingsFetching: true,
        hasRatingsError: false,
      };
    }

    case USER_APPS_RATINGS_SUCCESS: {
      const newRatings = {};
      const newAverageRatings = {};
      if (Array.isArray(action.response.ratings)) {
        action.response.ratings.forEach((x) => {
          newRatings[x.appSlug] = {
            rating: x.rating,
            review: x.review,
          };
        });
      }
      if (Array.isArray(action.response.averageRatings)) {
        action.response.averageRatings.forEach((x) => {
          newAverageRatings[x.appSlug] = {
            average: x.average
          };
        });
      }
      return {
        ...state,
        areRatingsFetching: false,
        hasRatingsError: false,
        ratings: {
          ...state.ratings,
          ...newRatings
        },
        averageRatings: {
          ...state.averageRatings,
          ...newAverageRatings
        }
      };
    }

    case USER_APPS_RATINGS_ERROR: { 
      return {
        ...state,
        areRatingsFetching: false,
        hasRatingsError: true,
        error: action.response
      };
    }

    default:
      return state;
  }
};
