import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { ChevronRight, ChevronLeft } from '@material-ui/icons';
import { CarouselProvider, Slider, Slide, Dot, ButtonNext, ButtonBack } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

const styles = (theme) => ({
  carousel: {
    height: '100%',
    '& > div': {
      overflowY: 'visible',
      overflowX: 'hidden',
      // gallery with dynamic slide heights
      height: '100%',
      '& > div': {
        height: '100%',
        '& > ul': {
          height: '100%',
          '& > li': {
            height: '100%',
            paddingBottom: '0 !important',
            '& > div': {
              position: 'static'
            }
          }
        }
      },
    },
    [theme.breakpoints.down('xs')]: {
      '& > div': {
        overflow: 'visible',
      },
      '&:after': {
        content: '""',
        clear: 'both',
        display: 'table'
      }
    }
  },
  dots: {
    display: 'flex',
    height: [90, '!important'],
    overflowX: ['scroll', '!important'],
    paddingBottom: theme.spacing.unit,
    '& .carousel__dot': {
      background: 'transparent',
      border: 'none',
      margin: theme.spacing.unit,
      minWidth: 80,
      overflow: 'hidden',
      padding: theme.spacing.unit,
      width: 80
    },
    '& .carousel__dot:focus': {
      outline: 'none'
    },
    '& .carousel__dot--selected': {
      border: [2, 'solid', theme.palette.primary.main]
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  slideContainer: {
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      overflow: 'visible'
    }
  },
  slidePaper: {
    background: 'transparent',
    margin:'0 10px',
    marginBottom: 1.5 * theme.spacing.unit,
    position: 'relative',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      margin: '0 5px',
    },
    '&:hover > div': {
      '& button': {
        color: '#777',
        boxShadow: '0 0 8px rgba(0,0,0,0.33)',
        background: 'rgba(250,250,250,0.75)',
      }
    },
  },
  thumbnail: {
    width: `calc(80px - ${theme.spacing.unit * 2 + 4}px)`
  },
  thumbnailWrapper: {
    maxHeight: `calc(80px - ${theme.spacing.unit * 4 + 4}px)`,
    overflow: 'hidden'
  },
  image: {
    maxWidth: '100%',
    maxHeight: 'calc(80vh - 100px)',
    height: 'auto',
    width: 'auto',
    margin: '0 auto',
    verticalAlign: 'top',
    [theme.breakpoints.down('xs')]: {
      maxHeight: 'calc(80vh - 100px)',
    }
  },
  imagePaper: {
    display: 'inline-block',
  },
  galleryArrow: {
    position: 'absolute',
    top: '50%',
    marginTop: '-22px',
    cursor: 'pointer',
    '& button': {
      color: 'white',
      background: 'rgba(50,50,50,0.5)',
      border: 'none',
      borderRadius: 44,
      padding: 0,
      '&:hover': {
        backgroundColor: 'rgba(250,250,250,1) !important',
        color: '#444 !important'
      },
      transition: theme.transitions.create(['background','box-shadow','color'], {
        easing: theme.transitions.easing.easeOut,
        duration: 250
      }),
      width: 44,
      height: 44,
    },
    '& svg': {
      width: 32,
      height: 32,
    },
    transition: theme.transitions.create(['opacity'], {
      easing: theme.transitions.easing.easeOut,
      duration: 250
    }),
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  leftArrow: {
    left: theme.spacing.unit
  },
  rightArrow: {
    right: theme.spacing.unit
  }
});

// added visibleSlides, hid dot group
const Gallery = ({ classes, images }) => (
  <CarouselProvider
    className={classes.carousel}
    naturalSlideHeight={3}
    naturalSlideWidth={4}
    totalSlides={images.length}>
    {images.length > 1 &&
      <div className={classes.dots}>
        {images.map((image, index) => (
          <Dot key={`thumbnail-${index}`} slide={index}>
            <div className={classes.thumbnailWrapper}>
              <img className={classes.thumbnail} src={image} alt={`thumbnail-${index}`} />
            </div>
          </Dot>
        ))}
      </div>
    }
    <Slider>
      {images.map((image, index) => (
        <Slide index={index} key={`gallery-image-${index}`} className={classes.slideContainer}>
          <Paper elevation={0} square className={classes.slidePaper}>
            <Paper elevation={6} square className={classes.imagePaper}>
              <img alt={`screenshot #${index}`} src={image} className={classes.image} />
            </Paper>
            {index !== 0 && (

              <div className={classNames(classes.galleryArrow, classes.leftArrow)}>
                <ButtonBack><ChevronLeft /></ButtonBack>
              </div>
            )}
            {index !== images.length - 1 && (
              <div className={classNames(classes.galleryArrow, classes.rightArrow)}>
                <ButtonNext><ChevronRight /></ButtonNext>
              </div>
            )}
          </Paper>
        </Slide>
      ))}
    </Slider>
  </CarouselProvider>
);

Gallery.propTypes = {
  classes: PropTypes.object.isRequired,
  images: PropTypes.array.isRequired
};

export default withStyles(styles)(Gallery);
