import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Create from './Create';
import * as actions from './actions';

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(actions, dispatch);
};

export default connect(null, mapDispatchToProps)(Create);
