import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Edit from './Edit';
import * as actions from './actions';

const mapStateToProps = ({ apps }) => {
  return {
    hasError: false,
    isFetching: true,
    ...apps
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(actions, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
