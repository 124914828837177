import myColliersLogo from '../assets/images/myColliers-logo-200.png';
import colliers360Logo from '../assets/images/colliers360-logo-200.png';

const Skin = {};
if (window.location.hostname === 'app.colliers360.com' || process.env.REACT_APP_SKIN === 'colliers360') {
  Skin.colliers360 = true;
  Skin.name = 'Colliers360';
  Skin.logoUrl = colliers360Logo;
  Skin.originUrl = 'http://colliers360.com';
  Skin.supportTechnical = 'https://colliers.service-now.com/css?id=sc_cat_item&sys_id=c780d7b01b0c9550ffe9535b234bcb4d&sysparm_category=a97414da1bc63190dbda0e16dc4bcb30';
  Skin.dashboardTitle = 'Home';
} else {
  Skin.myColliers = true;
  Skin.name = 'MyColliers';
  Skin.logoUrl = myColliersLogo;
  Skin.originUrl = 'http://colliers.com';
  Skin.supportTechnical = 'https://colliers.service-now.com/css?id=sc_cat_item&sys_id=c780d7b01b0c9550ffe9535b234bcb4d&sysparm_category=a97414da1bc63190dbda0e16dc4bcb30';
  Skin.dashboardTitle = 'Dashboard';
}
export default Skin;
