import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import red from '@material-ui/core/colors/red';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const styles = (theme) => ({
  error: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing.unit * 8,
    textAlign: 'center'
  },
  icon: {
    color: red[400]
  },
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    height: 'calc(100vh - 100px)',
    justifyContent: 'center',
    width: '100%',
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    }
  }
});

const FullScreenError = ({ classes, message }) => (
  <div className={classes.wrapper}>
    <Paper className={classes.error}>
      <ErrorOutlineIcon className={classes.icon} fontSize="large" />
      <Typography component="p">{typeof message === 'string' ? message : ''}</Typography>
    </Paper>
  </div>
);

FullScreenError.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.string
};

export default withStyles(styles)(FullScreenError);
