import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  avatar: {
    margin: 'auto',
    height: 115,
    minHeight: 115,
    width: 115,
    minWidth: 115,
    [theme.breakpoints.down('xs')]: {
      height: 75,
      width: 75,
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: 0
    },
  },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginRight: 20,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 20,
    },
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      marginLeft: theme.spacing.unit * 3,
    }
  },
  contactConnect: {
    justifyContent: 'space-between',
    '& > *:first-child': {
      paddingRight: theme.spacing.unit,
    },
    '& a': {
      color: theme.palette.primary.dark
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing.unit,
      fontSize: '75%'
    }
  },
  contactWrapper: {
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    margin: [0, theme.spacing.unit * 5],
    overflow: 'hidden',
    padding: theme.spacing.unit * 4,
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      justifyContent: 'flex-start',
      margin: 0,
    }
  },
  ellipsis: {
    display: 'inline-block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    maxHeight: '1.4em'
  },
  infoContainer: {
    flex: 1,
    maxWidth: 400,
  },
  instructions: {
    fontSize: '1rem',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    textTransform: 'uppercase'
  },
  name: {
    fontWeight: 'bold',
    color: theme.palette.primary.dark,
    lineHeight: '1em',
    fontSize: '1.7rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.3rem'
    }
  },
  noThumbnail: {
    paddingLeft: theme.spacing.unit * 2,
  },
  title: {
    color: theme.palette.grey[500],
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.9rem'
    }
  },
  contactTextGridItem: {
    padding: [theme.spacing.unit * 2.5, 0, 0, 0],
    [theme.breakpoints.down('xs')]: {
      padding: [theme.spacing.unit * 0.5, 0, 0, 0],
    }
  },
});

const Contact = ({ classes, contact }) => (
  <Grid className={classes.contactWrapper} container wrap={'nowrap'}>
    {contact.thumbnail && <Grid item xs={'auto'} className={classes.avatarContainer}>
      <Avatar className={classes.avatar} src={contact.thumbnail} />
    </Grid>}
    <Grid
      item
      xs={'auto'}
      className={classNames({
        [classes.infoContainer]:true,
        [classes.noThumbnail]:!contact.thumbnail
      })}>
      <Grid container>
        <Grid item xs={12}>
          <Typography className={classes.instructions}>
            <Hidden smUp>Information Contact</Hidden>
            <Hidden xsDown>For more information, please contact:</Hidden>
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.contactTextGridItem}>
          <Typography className={classes.name} variant="h5">{contact.name}</Typography>
          <Typography className={classes.title} variant="subtitle1">{contact.title}</Typography>
        </Grid>
        <Grid item xs={12} className={classes.contactTextGridItem}>
          <Grid container className={classes.contactConnect}>
            {contact.phone && <Grid item xs={'auto'}>
              <a href={`tel:${contact.phone}`} className={classes.ellipsis}>
                {contact.phone}
              </a>
            </Grid>}
            {contact.email && <Grid item xs={'auto'}>
              <a href={`mailto:${contact.email}`} className={classes.ellipsis}>
                {contact.email}
              </a>
            </Grid>}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

Contact.propTypes = {
  classes: PropTypes.object.isRequired,
  contact: PropTypes.object.isRequired
};

export default withStyles(styles)(Contact);
