import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = {
  loadingWrapper: {
    alignItems: 'center',
    display: 'flex',
    height: 'calc(100vh - 100px)',
    justifyContent: 'center',
    width: '100%'
  }
};

const FullScreenLoader = ({ classes }) => (
  <div className={classes.loadingWrapper}>
    <CircularProgress />
  </div>
);

FullScreenLoader.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(FullScreenLoader);
