import {
  APP_UPDATE_REQUEST,
  APPS_READ_REQUEST,
  APPS_READ_SUCCESS,
  APPS_READ_ERROR,
  GROUP_DELETE_REQUEST,
  GROUP_UPDATE_REQUEST,
  GROUPS_CREATE_REQUEST,
  GROUPS_CREATE_SUCCESS,
  GROUPS_CREATE_ERROR,
  GROUPS_CREATE_CLEAR_ERROR,
  GROUPS_READ_REQUEST,
  GROUPS_READ_SUCCESS,
  GROUPS_READ_ERROR
} from '../constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case APP_UPDATE_REQUEST: {
      if (state.apps) {
        return {
          ...state,
          apps: [
            ...state.apps.map((app) => {
              if (app.slug === action.data.id) {
                return {
                  ...app,
                  ...action.data
                };
              }
              return app;
            })
          ]
        };
      }
      return state;
    }
    case APPS_READ_REQUEST:
      return {
        ...state,
        isFetchingApps: true,
        hasError: false
      };
    case APPS_READ_SUCCESS:
      if (action.response) {
        action.response.sort((a,b) => (a.title.toLowerCase() < b.title.toLowerCase()) ? -1 : 1);
      }
      return {
        ...state,
        isFetchingApps: false,
        hasError: false,
        apps: action.response
      };
    case APPS_READ_ERROR:
      return {
        ...state,
        isFetchingApps: false,
        hasError: true,
        error: action.response
      };
    case GROUP_UPDATE_REQUEST:
      if (state.groups) {
        return {
          ...state,
          groups: [
            ...state.groups.map((group) => {
              if (group._id === action.data.id) {
                return {
                  ...group,
                  ...action.data
                };
              }
              return group;
            })
          ]
        };
      }
      return state;
    case GROUP_DELETE_REQUEST:
      if (state.groups) {
        return {
          ...state,
          groups: state.groups.filter(({ id }) => id !== action.id)
        };
      }
      return state;
    case GROUPS_CREATE_REQUEST:
      return {
        ...state,
        isCreatingGroup: true,
        hasCreateError: false
      };
    case GROUPS_CREATE_SUCCESS:
      return {
        ...state,
        isCreatingGroup: false,
        hasCreateError: false,
        groups: ([...(state.groups || []), action.response]).sort((a, b) => {
          if (!!a.isClient === !!b.isClient) {
            return (a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : 1;
          } else if (a.isClient) {
            return 1;
          }
          return -1;
        })
      };
    case GROUPS_CREATE_ERROR:
      const createErrorMessage = (action.error.status === 409) ?
        'That Client name already exists. Please try again.'
        : 'Client could not be created.';
      return {
        ...state,
        isCreatingGroup: false,
        hasCreateError: true,
        error: createErrorMessage
      };
    case GROUPS_CREATE_CLEAR_ERROR:
      return {
        ...state,
        isCreatingGroup:false,
        hasCreateError: false,
        error: null
      };
    case GROUPS_READ_REQUEST:
      return {
        ...state,
        isFetchingGroups: true,
        hasError: false
      };
    case GROUPS_READ_SUCCESS:
      return {
        ...state,
        isFetchingGroups: false,
        hasError: false,
        groups: (action.response || []).sort((a, b) => {
          if (!!a.isClient === !!b.isClient) {
            return (a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : 1;
          } else if (a.isClient) {
            return 1;
          }
          return -1;
        })
      };
    case GROUPS_READ_ERROR:
      return {
        ...state,
        isFetchingGroups: false,
        hasError: true,
        error: action.response
      };
    default:
      return state;
  }
};
