import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import AddUserIcon from '@material-ui/icons/PersonAdd';
import AdminIcon from '@material-ui/icons/Person';
import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Clear';
import UserIcon from '@material-ui/icons/PersonOutline';
import { Typography } from '@material-ui/core';
import api from '../services/myColliersApi';

const styles = (theme) => ({
  buttonWrapper: {
    flexGrow: 1,
    borderTop: '1px solid #c4c4c4',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing.unit * 4,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing.unit * 4,
  },
  resultsMenu: {
    maxHeight: 'calc(100% - 232px)'
  },
  searchResult: {
    height: 45,
    paddingBottom: theme.spacing.unit / 2,
    paddingTop: theme.spacing.unit / 2,
    width: 504
  },
  submitButton: {
    marginTop: theme.spacing.unit * 2.5,
  },
});

class UserModal extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    group: PropTypes.object.isRequired,
    logoUrl: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      loading: false,
      searching: false,
      search: '',
      searchAnchor: null,
      searchResults: [],
      users: []
    };
  }

  loadUsers = () => {
    this.setState({ loading: true });
    api.read(`groups/${this.props.group.id}/users`).then((users) => {
      this.setState({
        users: users.sort((a, b) => {
          if (a.isAdmin === b.isAdmin) {
            return a.email < b.email ? -1 : 1;
          }
          return a.isAdmin ? -1 : 1;
        }),
        loading: false
      });
    });
  };

  handleOpen = () => {
    this.setState({ open: true });
    this.loadUsers();
  };

  handleClose = () => {
    this.setState({ open: false, searchAnchor: null });
  };

  onAdd = (user, isAdmin) => () => {
    this.clearSearch();
    return api.create(`groups/${this.props.group.id}/users`, {
      user,
      logoUrl: this.props.logoUrl,
      isAdmin
    }).then(this.loadUsers)
      .catch(() => {
        this.setState({ hasAddUserError: true });
      });
  };

  onRemove = (user) => () => {
    return api.destroy(`groups/${this.props.group.id}/users`, user.id).then(() => {
      this.loadUsers();
    });
  };

  clearSearch = () => {
    this.setState({ 
      search: '',
      hasAddUserError: false
    });
    this.handleSearchClose();
  };

  handleSearchEnter = (event) => {
    if (event.key === 'Enter') {
      this.setState({
        searching: true, 
        searchAnchor: event.currentTarget
      });
      return api.read(`groups/${this.props.group.id}/users/search?term=${this.state.search}`)
        .then((data) => {
          const existingUserIds = this.state.users.map(({ id }) => id);
          this.setState({
            searching: false,
            searchResults: data.filter((user) => !existingUserIds.includes(user.id))
          });
        })
        .catch(() => {
          this.setState({
            searching: false, 
            searchAnchor: null
          });
        });
    }
  };
  handleSearchChange = (event) => {
    this.setState({
      search: event.target.value,
      hasAddUserError: false,
      searchResults: []
    });
  };
  handleSearchClose = () => {
    this.setState({
      searching: false,
      searchAnchor: null
    });
  };

  getSearchResultActions = (user) => (
    <ListItemSecondaryAction>
      <Tooltip title="Add as User" placement="left">
        <IconButton onClick={this.onAdd(user, false)}>
          <AddUserIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Add as Admin" placement="left">
        <IconButton onClick={this.onAdd(user, true)}>
          <AddUserIcon color="primary" />
        </IconButton>
      </Tooltip>
    </ListItemSecondaryAction>
  );

  render() {
    const { classes, group } = this.props;
    const { loading, searchResults, search, searching, searchAnchor, users } = this.state;
    const searchLooksLikeEmail = /^[^@]+@[^@.]+\.[^@]+$/.test(search);
    const resultsContainEmailMatch = searchResults && searchResults.find(({ email }) =>
      email.toLowerCase().trim() === search.toLowerCase().trim()
    );

    return (
      <div className={classes.form}>
        <Button
          onClick={this.handleOpen}
          size="large"
          style={{ marginLeft: 4 }}
          variant="outlined">
          Edit Users
        </Button>
        <Dialog
          aria-labelledby="config-modal-title"
          aria-describedby="config-modal-description"
          fullWidth={true}
          maxWidth={'sm'}
          open={this.state.open}
          onClose={this.handleClose}>
          <div className={classes.paper}>
            <Typography variant="h5" style={{ marginBottom: 16 }}>
              &quot;{group.name}&quot; Users
            </Typography>

            <TextField
              autoFocus
              fullWidth
              error={this.state.hasAddUserError}
              helperText={this.state.hasAddUserError ? 'There was a problem adding that user' : null}
              label="Search for Users to Add"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
              onChange={this.handleSearchChange}
              onKeyPress={this.handleSearchEnter}
              size="small"
              value={search}
              variant="outlined" />

            <Menu
              anchorEl={searchAnchor}
              anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
              classes={{ paper: classes.resultsMenu }}
              disableAutoFocusItem={true}
              getContentAnchorEl={null}
              open={Boolean(searchAnchor)}
              onClose={this.handleSearchClose}>
              {searching && <MenuItem><CircularProgress /></MenuItem>}
              {!searching && searchResults.length === 0 && (
                <MenuItem className={classes.searchResult}>
                  {searchLooksLikeEmail ? (
                    <>
                      <ListItemText
                        primary="No results found."
                        secondary={`Create a new user "${search}"`} />
                      {this.getSearchResultActions({ email: search })}
                    </>
                  ) : (
                    <ListItemText
                      primary="No results found."
                      secondary="Enter a valid email to create a new user." />
                  )}
                </MenuItem>
              )}
              {!searching && searchResults.length > 0 && (
                <>
                  {searchResults.map((user, x) => (
                    <MenuItem key={x} className={classes.searchResult}>
                      <ListItemText
                        primary={user.name}
                        secondary={user.email} />
                      {this.getSearchResultActions(user)}
                    </MenuItem>
                  ))}
                  {searchLooksLikeEmail && !resultsContainEmailMatch && (
                    <MenuItem className={classes.searchResult} style={{ borderTop: '1px dashed #c4c4c4' }}>
                      <ListItemText
                        primary="+ Create a New User..."
                        secondary={search} />
                      {this.getSearchResultActions({ email: search })}
                    </MenuItem>
                  )}
                </>
              )}
            </Menu>
            
            {loading && (
              <div className={classes.loadingContainer}>
                <CircularProgress />
              </div>
            )}
            {!loading && users.length > 0 && (
              <List style={{ maxHeight: '50vh', overflow: 'auto' }}>
                {users.map((user, x) => (
                  <ListItem key={x}>
                    <ListItemIcon>
                      {user.isAdmin
                        ? (<Tooltip title="Admin" placement="right"><AdminIcon color="primary" /></Tooltip>)
                        : (<Tooltip title="User" placement="right"><UserIcon color="disabled" /></Tooltip>)
                      }
                    </ListItemIcon>
                    <ListItemText primary={user.name} secondary={user.email} />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" onClick={this.onRemove(user)}>
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            )}
            
            <div className={classes.buttonWrapper}>
              <Button
                className={classes.submitButton}
                color="primary"
                onClick={this.handleClose}
                type="button"
                variant="contained">
                Close
              </Button>
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(UserModal);
