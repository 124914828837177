import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setViewAsClient } from '../app/actions';
import { clearGroupCreateError } from '../groups/actions';
import { toggleNavDrawer } from '../navDrawer/actions';
import Header from './Header';

const mapStateToProps = ({ app, groups, navDrawer }) => {
  return {
    createError: groups.hasCreateError ? groups.error : undefined,
    groups: app.groups,
    navDrawerOpen: navDrawer.navDrawerOpen,
    permissionsFetched: app.permissionsFetched,
    mobileTitle: app.mobileTitle,
    viewAsClientId: app.viewAsClientId
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ clearGroupCreateError, setViewAsClient, toggleNavDrawer }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
