import {
  PROJECT_ROOM_INVITE_REQUEST,
  PROJECT_ROOM_INVITE_SUCCESS,
  PROJECT_ROOM_INVITE_ERROR
} from '../constants/actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case PROJECT_ROOM_INVITE_REQUEST:
      return {
        ...state,
        isFetching: true,
        hasError: false
      };

    case PROJECT_ROOM_INVITE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        hasError: false
      };

    case PROJECT_ROOM_INVITE_ERROR:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        error: action.response
      };
    default:
      return state;
  }
};

