import {
  LEASES_READ_SUCCESS,
  LEASES_READ_REQUEST,
  LEASES_READ_ERROR,
  LEASE_DETAIL_READ_SUCCESS,
  LEASE_DETAIL_READ_REQUEST,
  LEASE_DETAIL_READ_ERROR,
  LEASES_SET_SELECTED,
  LEASES_SET_RECORDS_PER_PAGE,
  LEASES_SET_CURRENT_PAGE,
  LEASES_DELETE_REQUEST,
  LEASES_DELETE_SUCCESS,
  LEASES_DELETE_ERROR,
  LEASE_UPLOAD_REQUEST,
  LEASE_UPLOAD_SUCCESS,
  LEASE_UPLOAD_ERROR,
  LEASE_UPLOAD_VALIDATION_ERROR,
} from '../constants/actionTypes';

export default (
  state = {
    currentPage: 1,
    disableMore: false,
    isUploading: false,
    hasUploadError: false,
    hasValidationError: false,
    leases: {
      count: 0,
      leasesInformation: [],
      numberOfPages: 0,
    },
    recordsPerPage: 10,
    selected: [],
    isFetching: true,
    hasError: false,
  },
  action,
) => {
  switch (action.type) {
    case LEASES_SET_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.currentPage,
      };
    }
    case LEASES_SET_RECORDS_PER_PAGE: {
      return {
        ...state,
        recordsPerPage: action.recordsPerPage,
      };
    }
    case LEASE_UPLOAD_REQUEST: {
      return {
        ...state,
        isUploading: true,
        hasUploadError: false,
        hasValidationError: false,
      };
    }
    case LEASE_UPLOAD_SUCCESS: {
      return {
        ...state,
        isUploading: false,
        hasUploadError: false,
        hasValidationError: false,
        errors: null,
      };
    }
    case LEASE_UPLOAD_ERROR: {
      return {
        ...state,
        isUploading: false,
        hasUploadError: true,
        hasValidationError: false,
      };
    }
    case LEASE_UPLOAD_VALIDATION_ERROR: {
      return {
        ...state,
        isUploading: false,
        hasUploadError: false,
        hasValidationError: true,
        errors: action.error,
      };
    }
    case LEASES_READ_REQUEST: {
      const newState = {
        ...state,
        isFetching: true,
        hasError: false,
        lastSearchParams: action.searchParams
      };
      if (action.shouldReset) {
        newState.leases = {
          leasesInformation: [],
          numberOfPages: 0,
        };
        newState.leaseDetails = {};
        newState.selected = [];
      }
      return newState;
    }
    case LEASES_READ_SUCCESS: {
      if (JSON.stringify(action.response) === '{}') {
        return {
          ...state,
          isFetching: false,
          hasError: false,
          disableMore: true,
        };
      }

      const numberOfPages = Math.ceil(action.response.count / state.recordsPerPage);
      return {
        ...state,
        currentPage: Math.max(1, Math.min(state.currentPage, numberOfPages)),
        isFetching: false,
        hasError: false,
        disableMore: (action.response.leasesInformation.length < state.recordsPerPage)
          || (state.currentPage >= numberOfPages),
        leases: {
          ...action.response,
          leasesInformation: [...(state.leases.leasesInformation || []), ...action.response.leasesInformation],
        }
      };
    }
    case LEASES_READ_ERROR: {
      return {
        ...state,
        isFetching: false,
        hasError: true,
        error: action.response,
        disableMore: true,
      };
    }
    case LEASES_DELETE_REQUEST: {
      const newState = { ...state };
      const lease = newState.leases.leasesInformation.find((l) => l.systemId === action.id);
      if (lease) {
        lease.isDeleting = true;
        lease.hasDeleteError = false;
      }
      return newState;
    }
    case LEASES_DELETE_SUCCESS: {
      const newState = {
        ...state,
        leases: {
          ...state.leases,
          leasesInformation: state.leases.leasesInformation.filter((l) => l.systemId !== action.id)
        }
      };
      return newState;
    }
    case LEASES_DELETE_ERROR: {
      const newState = { ...state };
      const lease = newState.leases.leasesInformation.find((l) => l.systemId === action.id);
      if (lease) {
        lease.isDeleting = false;
        lease.hasDeleteError = true;
      }
      return newState;
    }
    case LEASE_DETAIL_READ_REQUEST: {
      const newState = { ...state };
      const lease = newState.leases.leasesInformation.find((l) => l.guid === action.id);
      if (lease) {
        lease.isFetchingDetails = true;
        lease.hasDetailsError = false;
      }
      return newState;
    }
    case LEASE_DETAIL_READ_SUCCESS: {
      const newState = { ...state };
      const lease = newState.leases.leasesInformation.find((l) => l.guid === action.id);
      if (lease) {
        lease.isFetchingDetails = false;
        lease.hasDetailsError = false;
      }
      if (JSON.stringify(action.response) !== '{}') {
        newState.leaseDetails = {
          ...newState.leaseDetails,
          [action.response.guid]: {
            ...action.response,
          },
        };
      }
      return newState;
    }
    case LEASE_DETAIL_READ_ERROR: {
      const newState = {
        ...state,
        error: action.response,
      };
      const lease = newState.leases.leasesInformation.find((l) => l.guid === action.id);
      if (lease) {
        lease.isFetchingDetails = false;
        lease.hasDetailsError = true;
      }
      return newState;
    }
    case LEASES_SET_SELECTED: {
      return {
        ...state,
        selected: action.selected,
      };
    }
    default:
      return state;
  }
};
