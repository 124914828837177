import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { AuthProvider } from 'react-check-auth';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import Callback from '../callback/Callback';
import { history } from '../store';
import Logout from '../auth/Logout';
import Skin from '../config/skin';
import AuthenticatedApp from './ConnectedAuthenticatedApp';

// eslint-disable-next-line
let authUrl = `${process.env.REACT_APP_API_URL}/users/profile${process.env.REACT_APP_BUST_PERM_CACHE ? '?bustPermissionsCache=1' : ''}`;
authUrl = authUrl.replace(/(https?:\/\/)|(\/){2,}/g, '$1$2');
const reqOptions = () => ({
  headers: {
    Authorization: `Bearer ${localStorage.getItem('id_token')}`,
    'X-App-Skin': Skin.colliers360 ? 'colliers360' : 'myColliers',
  }
});

class App extends React.Component {
  static propTypes = {
    appTitle: PropTypes.string,
    routeTitle: PropTypes.string,
    setPageTitles: PropTypes.func.isRequired,
  };

  componentDidMount() {
    // listen for route changes so can update page title
    history.listen((location) => {
      this.updateHeaders(location);
    });
    this.updateHeaders(history.location);
  }

  updateHeaders(location) {
    const pageTitles = {
      app: Skin.name
    };

    // this will run before the routed component's componentDidMount, so can
    // also use setPageTitles from the component for more granular control
    const routeParts = location.pathname.split('/');
    routeParts.shift();
    switch (routeParts[0]) {
      case '':
        pageTitles.route = 'Welcome';
        break;
      case 'apps':
        switch (routeParts[1]) {
          case 'create':
            pageTitles.route = pageTitles.mobile = 'Create Application';
            break;
          case 'edit':
            pageTitles.route = pageTitles.mobile = 'Edit Application';
            break;
          default:
            pageTitles.mobile = 'Application';
            break;
        }
        break;
      case 'dashboard':
        pageTitles.route = pageTitles.mobile = Skin.dashboardTitle;
        break;
      case 'groups':
        pageTitles.route = pageTitles.mobile = 'Admin';
        break;
      case 'launcher':
        pageTitles.route = pageTitles.mobile = 'Applications';
        break;
      case 'leases':
        pageTitles.route = pageTitles.mobile = 'Leases';
        break;
      case 'profile':
        pageTitles.route = pageTitles.mobile = 'My Profile';
        break;
      case 'projectRoom':
        pageTitles.route = pageTitles.mobile = routeParts[1] ? 'Project Room' : 'Projects';
        break;
      case 'support':
        pageTitles.route = pageTitles.mobile = 'Support';
        break;
      default:
        pageTitles.route = pageTitles.mobile = '';
        break;
    }
    this.props.setPageTitles(pageTitles);
  }

  render() {
    const { appTitle, routeTitle } = this.props;
    return (
      <AuthProvider authUrl={authUrl} reqOptions={reqOptions}>
        {appTitle && <Helmet><title>{appTitle}{routeTitle ? ` | ${routeTitle}` : ''}</title></Helmet>}
        <ConnectedRouter history={history}>
          <Switch>
            <Route path="/callback" component={Callback} />
            <Route path="/logout" component={Logout} />
            <Route path="/" component={AuthenticatedApp} />
          </Switch>
        </ConnectedRouter>
      </AuthProvider>
    );
  }
}

export default App;
