import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgComponent = (props) => (
  <SvgIcon
    viewBox="0 0 44 44" role="img" aria-labelledby="title"
    {...props}>
    <title id="title">{props.title || 'Applications icon'}</title>
    <g fill="none" fillRule="evenodd">
      <circle cx={22} cy={22} r={22} />
      <circle
        stroke="#FFF"
        strokeWidth={2.5}
        cx={11}
        cy={11}
        r={4} />
      <rect
        stroke="#FFF"
        strokeWidth={2.5}
        strokeLinecap="round"
        strokeLinejoin="round"
        x={19}
        y={9}
        width={18}
        height={4}
        rx={1.485} />
      <circle
        stroke="#FFC605"
        strokeWidth={2.5}
        cx={11}
        cy={22}
        r={4} />
      <rect
        stroke="#FFC605"
        strokeWidth={2.5}
        strokeLinecap="round"
        strokeLinejoin="round"
        x={19}
        y={20}
        width={18}
        height={4}
        rx={1.485} />
      <circle
        stroke="#FFF"
        strokeWidth={2.5}
        cx={11}
        cy={33}
        r={4} />
      <rect
        stroke="#FFF"
        strokeWidth={2.5}
        strokeLinecap="round"
        strokeLinejoin="round"
        x={19}
        y={31}
        width={18}
        height={4}
        rx={1.485} />
    </g>
  </SvgIcon>
);

SvgComponent.propTypes = {
  title: PropTypes.string,
};

export default SvgComponent;
