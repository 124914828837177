import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import ImageUploader from '../../shared/ImageUploader';

const styles = (theme) => ({
  button: {
    margin: [theme.spacing.unit / 2, 0],
  },
  image: {
    alignItems: 'center',
    border: [1, 'solid', 'rgba(0, 0, 0, 0.23)'],
    display: 'flex',
    justifyContent: 'space-between',
    margin: [theme.spacing.unit / 2, 0],
    padding: [theme.spacing.unit, theme.spacing.unit * 2]
  }
});

export function GalleryField({ classes, input }) {
  const [open, setOpen] = useState(false);

  const removeImage = (image) => () => {
    input.onChange(input.value.filter((val) => val !== image));
  };

  const handleUpdate = (results) => {
    if (results && results.length) {
      input.onChange(input.value.concat(results));
    }
  };

  return (
    <React.Fragment>
      {input.value.map((image) => (
        <div
          className={classes.image}
          key={`image-list-${image}`}>
          <Typography variant="button">{image.split('/').slice(-1)[0]}</Typography>
          <IconButton onClick={removeImage(image)}>
            <CloseIcon />
          </IconButton>
        </div>
      ))}
      <Button
        className={classes.button}
        fullWidth={true}
        onClick={() => setOpen(true)}
        variant="outlined">
        <AddIcon />
        Add Another
      </Button>
      <ImageUploader
        onClose={() => setOpen(false)}
        onUpdate={handleUpdate}
        maxFiles={999}
        preset={process.env.REACT_APP_CLOUDINARY_GALLERY_PRESET}
        show={open}
        title="Upload Gallery Image" />
    </React.Fragment>
  );
}

GalleryField.propTypes = {
  classes: PropTypes.object.isRequired,
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.array.isRequired
  })
};

export default withStyles(styles)(GalleryField);
