import get from 'lodash/get';
import {
  SET_PAGE_TITLES,
  SET_VIEW_AS_CLIENT,
  USER_PERMISSIONS_READ_ERROR,
  USER_PERMISSIONS_READ_SUCCESS,
  USER_NOTIFICATIONS_READ_SUCCESS,
  USER_NOTIFICATIONS_READ_ERROR,
  USER_NOTIFICATIONS_DISMISS_REQUEST,
  DISMISS_SPLASH_PAGE,
  GROUP_DELETE_SUCCESS,
  GROUP_UPDATE_SUCCESS
} from '../constants/actionTypes';
import Skin from '../config/skin';
import { getGroupConfig } from './utils';

const defaultState = {
  appTitle: Skin.name,
  defaultTenantId: null,
  groupConfig: {},
  notifications: [],
  permissionsFetched: false,
  splashDismissed: false
};
export default (state = defaultState, action) => {
  switch (action.type) {
    case USER_PERMISSIONS_READ_ERROR:
    case USER_PERMISSIONS_READ_SUCCESS:
      // split groups into Colliers groups and client groups
      const groups = get(action, 'response.groups', []);
      const colliersGroups = groups.filter((g) => !g.isClient);
      const clientGroups = groups.filter((g) => g.isClient);

      // if have valid localStorage view_as_client_id, use that
      // otherwise, if only has client groups, select first client group by default
      // (if no client group is selected, will use mashup of internal groups)
      let viewAsClient = null;
      let viewAsClientId = localStorage.getItem('view_as_client_id');
      if (viewAsClientId) {
        viewAsClient = clientGroups.find((cl) => cl._id === viewAsClientId);
        viewAsClientId = (viewAsClient || {})._id;
      }
      if (!viewAsClient && colliersGroups.length === 0 && clientGroups.length > 0) {
        viewAsClient = clientGroups[0];
        viewAsClientId = viewAsClient._id;
      }
      if (viewAsClient) {
        localStorage.setItem('view_as_client_id', viewAsClientId);
      } else {
        localStorage.removeItem('view_as_client_id');
      }

      return {
        ...state,
        defaultTenantId: get(action, 'response.defaultTenantId'),
        groups,
        groupConfig: getGroupConfig(groups, viewAsClientId),
        permissionsFetched: true,
        viewAsClientId,
      };

    case SET_VIEW_AS_CLIENT:
      if (action.clientGroupId) {
        localStorage.setItem('view_as_client_id', action.clientGroupId);
      } else {
        localStorage.removeItem('view_as_client_id');
      }
      return {
        ...state,
        groupConfig: getGroupConfig((state.groups || []), action.clientGroupId),
        viewAsClientId: action.clientGroupId
      };

    // if I am an admin updating groups, should also update my app-state's value of groups
    case GROUP_UPDATE_SUCCESS:
      if (state.groups) {
        const newGroups = [
          ...state.groups.map((group) => {
            if (group._id === action.response._id) {
              return {
                ...group,
                ...action.response
              };
            }
            return group;
          })
        ];
        return {
          ...state,
          groups: newGroups,
          groupConfig: getGroupConfig(newGroups, state.viewAsClientId),
        };
      }
      return state;

    case GROUP_DELETE_SUCCESS:
      if (state.groups) {
        const newGroups = state.groups.filter(({ id }) => id !== action.id);
        const newViewAsClientId = state.viewAsClientId === action.id ? null : state.viewAsClientId;
        return {
          ...state,
          groups: newGroups,
          groupConfig: getGroupConfig(newGroups, newViewAsClientId),
          viewAsClientId: newViewAsClientId
        };
      }
      return state;

    case SET_PAGE_TITLES:
      const newState = { ...state };
      if (typeof action.titles.app === 'string') {
        newState.appTitle = action.titles.app;
      }
      if (typeof action.titles.route === 'string') {
        newState.routeTitle = action.titles.route;
      }
      if (typeof action.titles.mobile === 'string') {
        newState.mobileTitle = action.titles.mobile;
      }
      return newState;

    case DISMISS_SPLASH_PAGE:
      return {
        ...state,
        splashDismissed: true
      };

    case USER_NOTIFICATIONS_READ_ERROR:
    case USER_NOTIFICATIONS_READ_SUCCESS:
      const notifications = get(action, 'response.notifications', []);
      return {
        ...state,
        notifications
      };

    case USER_NOTIFICATIONS_DISMISS_REQUEST:
      const updatedNotifs = [...state.notifications];
      const updateNotif = updatedNotifs.find((n) => n._id === action.id);
      if (updateNotif) {
        updateNotif.dismissedAt = new Date();
      }
      return {
        ...state,
        notifications: updatedNotifs
      };

    default:
      return state;
  }
};
