import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Chip from '@material-ui/core/Chip';
import color from 'color';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import BookmarkIcon from '../assets/icons/BookmarkIcon';
import Placeholder from '../assets/images/placeholder skyline.png';
import { strip } from '../app/utils';

const styles = (theme) => ({
  imageWrapper: {
    height: 48,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    width: 100,
  },
  thumbnail: {
    maxHeight: theme.spacing.unit * 5,
    maxWidth: theme.spacing.unit * 20,
  },
  buttonContainer: {
    padding: 32
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  launchButton: {
    background: `linear-gradient(
      to right,
      ${theme.palette.primary.dark},
      ${theme.palette.primary.main}
    )`,
    borderRadius: '5px',
    fontWeight: 'bold',
    width: '50%',
    '&:hover': {
      background: `linear-gradient(
        to right,
        ${theme.palette.primary.dark},
        ${theme.palette.primary.main}
      )`,
    },
  },
  launchButtonDisabled: {
    borderRadius: 5,
    width: '50%',
  },
  learnMoreButton: {
    borderRadius: 5,
    marginLeft: theme.spacing.unit,
    whiteSpace: 'nowrap',
    width: '50%',
  },
  tags: {
    width: 300,
    height: 48,
    overflowX: 'auto',
    overflowY: 'hidden',
    whiteSpace: 'nowrap',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  description: {
    maxHeight: 40,
    paddingBottom: 0,
    marginBottom: 8,
    marginTop: 8,
    overflowY: 'hidden',
    wordBreak: 'break-word',
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical'
  },
  descriptionWrapper: {
    height: 48,
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  tag: {
    marginRight: theme.spacing.unit
  },
  tagWrapper: {
    padding: '4px 12px 4px 24px'
  },
  featured: {
    display: ['inline-flex', '!important'],
    height: 30,
    marginLeft: theme.spacing.unit,
    marginTop: -3,
    width: 30,
    zIndex: 999,
    '&:hover': {
      backgroundColor: 'transparent',
    }
  },
  pin: {
    paddingTop: 5,
    zIndex: 999,
    '&:hover': {
      backgroundColor: 'transparent',
    }
  },
  pinIcon: {
    color: theme.palette.grey[300],
    '&:hover': {
      color: color(theme.palette.primary.main).darken(0.3).hex()
    }
  },
  pinned: {
    color: theme.palette.primary.main,
  },
  pinnedButton: {
    display: ['inline-flex', '!important']
  },
  splitColumns: {
    padding: 0
  },
  title: {
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
    width: '100%',
    fontSize: '1rem'
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 200,
    height: '100%',
  }
});

class AppListItem extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    description: PropTypes.string,
    featured: PropTypes.bool,
    pinUserApp: PropTypes.func.isRequired,
    pinned: PropTypes.bool,
    slug: PropTypes.string.isRequired,
    tags: PropTypes.array,
    thumbnail: PropTypes.string,
    title: PropTypes.string,
    unpinUserApp: PropTypes.func.isRequired,
    url: PropTypes.string,
  }

  constructor(props) {
    super(props);
    this.state = {
      inHoverState: false
    };
  }

  handleLaunchClick = () => {
    if (this.props.url) {
      window.open(this.props.url, '_blank');
    }
  }
  handleMouseEnter = () => {
    this.setState({ inHoverState: true });
  }

  handleMouseLeave = () => {
    this.setState({ inHoverState: false });
  }

  handlePinClick = () => {
    if (this.props.pinned) {
      this.props.unpinUserApp(this.props.slug);
    } else {
      this.props.pinUserApp(this.props.slug);
    }

    return false;
  }

  render() {
    const { classes, tags, featured, thumbnail, title, pinned, slug, description, url, } = this.props;
    return (
      <TableRow className={classes.tableRow}>
        <TableCell>
          <div className={classes.imageWrapper}>
            <img
              className={classes.thumbnail}
              alt="Application logo"
              src={thumbnail ? thumbnail : Placeholder} />
          </div>
        </TableCell>
        <TableCell>
          <div className={classes.titleWrapper}>
            <Typography
              className={classes.title}
              variant="h6">
              {title}
            </Typography>
          </div>
        </TableCell>
        <TableCell className={classes.splitColumns}>
          {featured && <Tooltip title="Featured" aria-label="Featured" placement="top">
            <Avatar
              className={classes.featured}
              src="/images/featuredApp.png" />
          </Tooltip>}
        </TableCell>
        <TableCell className={classes.splitColumns}>
          <Tooltip
            title={pinned ? 'Remove bookmark' : 'Bookmark app'}
            aria-label={pinned ? 'Remove bookmark' : 'Bookmark app'}
            placement="top">
            <IconButton
              className={classNames(classes.pin, { [classes.pinnedButton]: pinned })}
              disableRipple
              onClick={this.handlePinClick}
              onMouseEnter={this.handleMouseEnter}
              onMouseLeave={this.handleMouseLeave}>
              <BookmarkIcon className={classNames(classes.pinIcon, { [classes.pinned]: pinned })} />
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell className={classes.tagWrapper}>
          <div className={classes.tags}>
            {tags.map((tag) => (
              <Chip
                className={classes.tag}
                clickable={false}
                color="primary"
                key={`${slug}-tag-${tag}`}
                label={tag} />
            ))}
          </div>
        </TableCell>
        <TableCell>
          <div className={classes.descriptionWrapper}>
            <Typography className={classes.description} paragraph={true} variant="caption">
              {strip(description || '')}
            </Typography>
          </div>
        </TableCell>
        <TableCell style={{ paddingRight: 32 }}>
          <div className={classes.buttonWrapper}>
            <Button
              className={url ? classes.launchButton : classes.launchButtonDisabled}
              color={url ? 'primary' : 'secondary'}
              onClick={this.handleLaunchClick}
              disabled={!url}
              variant="contained">
              Launch
            </Button>
            <Button
              className={classes.learnMoreButton}
              component={Link}
              color="primary"
              to={`/apps/${slug}`}
              variant="outlined">
              Learn More
            </Button>
          </div>
        </TableCell>
      </TableRow>
    );
  }
}

export default withStyles(styles)(AppListItem);
