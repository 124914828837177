import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import AddBoxIcon from '@material-ui/icons/AddBox';
import {
  ArchiveFilter,
  CreateProjectRoom,
  ProjectRoomList,
  withProjectRooms
} from '@colliers-international/project-room';
import DefaultPageLayout from '../shared/DefaultPageLayout';
import { ability } from '../config/ability';

const styles = (theme) => ({
  actionButton: {
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.75
    },
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  addFab: {
    position: 'fixed',
    bottom: theme.spacing.unit * 1.5,
    right: theme.spacing.unit * 1.5,
    zIndex: 5,
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  roomListWrapper: {
    height: 'calc(100vh - 86px - 96px - 24px)',
    [theme.breakpoints.down('xs')]: {
      height: 'calc(100vh - 73px)',
    }
  }
});

const ProjectRooms = ({ classes, chatContext, history }) => {
  if (!ability.can('read', 'projectRooms', ability.defaultTenantId)) {
    history.push('/');
    return null;
  }

  const [showArchived, setShowArchived] = useState(false);
  const [rooms, setRooms] = useState();

  async function reloadRooms() {
    setRooms(await chatContext.util.fetchRooms());
  }
  function onRoomOpened(url) {
    history.push(`/projectRoom/${url}`);
  }
  function onRoomCreated(room) {
    onRoomOpened(get(room, 'url', ''));
  }
  
  useEffect(() => { reloadRooms(); }, []);

  return (
    <DefaultPageLayout
      title="Project Rooms"
      titleActions={(
        <React.Fragment>
          {(rooms || []).find((room) => get(room, 'attributes.archive')) && (
            <ArchiveFilter
              className={classes.actionButton}
              showArchived={showArchived}
              setShowArchived={setShowArchived} />
          )}
          <CreateProjectRoom onCreate={onRoomCreated}>
            {(onClick) => (
              <Tooltip title="Create New Project Room" aria-label="Create New Project Room">
                <AddBoxIcon
                  className={classes.actionButton}
                  color="primary" fontSize="large"
                  onClick={onClick} />
              </Tooltip>
            )}
          </CreateProjectRoom>
        </React.Fragment>
      )}
      xsNoPadding={true}>
      <div className={classes.roomListWrapper}>
        <CreateProjectRoom onCreate={onRoomCreated}>
          {(onClick) => (
            <Fab color="primary" className={classes.addFab} onClick={onClick}>
              <AddIcon fontSize="large" />
            </Fab>
          )}
        </CreateProjectRoom>

        <ProjectRoomList
          onOpenRoom={onRoomOpened}
          reloadRooms={reloadRooms}
          rooms={rooms}
          showArchived={showArchived} />
      </div>
    </DefaultPageLayout>
  );
};

ProjectRooms.propTypes = {
  chatContext: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withStyles(styles)(withProjectRooms(ProjectRooms, true));
