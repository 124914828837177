import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import NavDrawer from './NavDrawer';
import * as actions from './actions';

const mapStateToProps = ({ app: { groupConfig }, navDrawer }) => {
  return {
    groupConfig,
    navDrawerOpen: navDrawer.navDrawerOpen
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(actions, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(NavDrawer);
