import { FullStoryAPI as fullStoryAPI } from 'react-fullstory';
import get from 'lodash/get';
import api from '../services/myColliersApi';
import { registerPermissions, registerDefaultTenant } from '../config/ability';
import {
  SET_PAGE_TITLES,
  SET_VIEW_AS_CLIENT,
  USER_NOTIFICATIONS_READ_REQUEST,
  USER_NOTIFICATIONS_READ_SUCCESS,
  USER_NOTIFICATIONS_READ_ERROR,
  USER_NOTIFICATIONS_DISMISS_REQUEST,
  USER_NOTIFICATIONS_DISMISS_SUCCESS,
  USER_NOTIFICATIONS_DISMISS_ERROR,
  USER_PERMISSIONS_READ_REQUEST,
  USER_PERMISSIONS_READ_SUCCESS,
  USER_PERMISSIONS_READ_ERROR,
  DISMISS_SPLASH_PAGE,
} from '../constants/actionTypes';
import { getGroupConfig } from './utils';

function fullStoryLogClient(groups, clientGroupId) {
  const groupConfig = getGroupConfig(groups, clientGroupId);
  if (process.env.REACT_APP_FULLSTORY_ORG && groupConfig.dashboard) {
    if (groupConfig.dashboardMashupConfiguration && groupConfig.dashboardMashupConfiguration.externalUrl) {
      fullStoryAPI('event', 'Loaded NextGen', { clientId: clientGroupId });
    } else {
      fullStoryAPI('event', 'Loaded 360 Lite', { clientId: clientGroupId });
    }
  }
}

function userPermissionsRequest() {
  return {
    type: USER_PERMISSIONS_READ_REQUEST
  };
}

function userPermissionsSuccess(response) {
  return {
    type: USER_PERMISSIONS_READ_SUCCESS,
    response
  };
}

function userPermissionsError(error) {
  return {
    type: USER_PERMISSIONS_READ_ERROR,
    error
  };
}

export function fetchUserPermissions(nonce) {
  return (dispatch, getState) => {
    dispatch(userPermissionsRequest());
    return api.read('users/permissions', null, { c: (nonce ? nonce : Date.now()) })
      .then((data) => {
        registerPermissions(data.rules);
        registerDefaultTenant(data.defaultTenantId);
        dispatch(userPermissionsSuccess(data));
        const appState = get(getState(), 'app', {});
        if (appState.viewAsClientId && appState.groups) {
          fullStoryLogClient(appState.groups, appState.viewAsClientId);
        }
      })
      .catch((response) => {
        return dispatch(userPermissionsError(response));
      });
  };
}

export function setViewAsClient(clientGroupId, groups) {
  fullStoryLogClient(groups, clientGroupId);

  return {
    type: SET_VIEW_AS_CLIENT,
    clientGroupId
  };
}

export function setPageTitles(titles) {
  return {
    type: SET_PAGE_TITLES,
    titles
  };
}

export function dismissSplashPage() {
  return {
    type: DISMISS_SPLASH_PAGE
  };
}

function fetchNotificationsRequest() {
  return {
    type: USER_NOTIFICATIONS_READ_REQUEST
  };
}

function fetchNotificationsSuccess(response) {
  return {
    type: USER_NOTIFICATIONS_READ_SUCCESS,
    response
  };
}

function fetchNotificationsError(error) {
  return {
    type: USER_NOTIFICATIONS_READ_ERROR,
    error
  };
}

export function fetchNotifications() {
  return (dispatch) => {
    dispatch(fetchNotificationsRequest());
    return api.read('notifications')
      .then((data) => {
        dispatch(fetchNotificationsSuccess(data));
      })
      .catch((response) => {
        return dispatch(fetchNotificationsError(response));
      });
  };
}

function dismissNotificationRequest(id) {
  return {
    type: USER_NOTIFICATIONS_DISMISS_REQUEST,
    id
  };
}

function dismissNotificationSuccess(response) {
  return {
    type: USER_NOTIFICATIONS_DISMISS_SUCCESS,
    response
  };
}

function dismissNotificationError(error) {
  return {
    type: USER_NOTIFICATIONS_DISMISS_ERROR,
    error
  };
}

export function dismissNotification(id) {
  return (dispatch) => {
    dispatch(dismissNotificationRequest(id));
    return api.destroy(`notifications/${id}`)
      .then((data) => {
        dispatch(dismissNotificationSuccess(data));
      })
      .catch((response) => {
        return dispatch(dismissNotificationError(response));
      });
  };
}
