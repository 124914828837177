import React from 'react';
import PropTypes from 'prop-types';
import pick from 'lodash/pick';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';

const styles = (theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing.unit * 4,
  },
  submitButton: {
    marginRight: theme.spacing.unit * 3,
    marginTop: theme.spacing.unit * 3,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  field: {
    width: '100%',
  },
  buttonWrapper: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  resetButton: {
    marginTop: theme.spacing.unit * 3,
  },
  buttonIcon: {
    marginRight: theme.spacing.unit
  }
});

class ConfigModal extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    group: PropTypes.object.isRequired,
    updateGroup: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      submitting: false,
      form: '',
    };
  }

  componentDidMount() {
    this.reset();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.group || prevProps.group._id !== this.props.group._id) {
      this.reset();
    }
  }

  groupKeys = [
    'leasesClientId',
    'dashboardMashupUrl',
    'dashboardExternalUrl',
    'dashboardUserEmailKey',
    'axxerionLayer',
    'axxerionAuth',
    'isClient',
    'tenantId'
  ];

  isPristine = () => {
    return this.groupKeys.reduce((acc, cur) => {
      return acc && (this.props.group[cur] || '') === (this.state.form[cur] || '');
    }, true);
  };

  reset = () => {
    this.setState({
      form: pick(this.props.group || {}, this.groupKeys),
      submitting: false,
    });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  onChange = (name) => (event) => {
    this.setState({
      form: {
        ...this.state.form,
        [name]: Object.keys(event.target).includes('checked') ? event.target.checked : event.target.value,
      },
    });
  };

  onSubmit = () => {
    this.props.updateGroup({
      id: this.props.group._id,
      ...this.state.form,
    });
    this.setState({ open: false, submitting: false });
  };

  render() {
    const { classes, group } = this.props;
    const { submitting } = this.state;
    const pristine = this.isPristine();

    return (
      <div className={classes.form}>
        <Button
          onClick={this.handleOpen}
          size="large"
          style={{ marginLeft: 4 }}
          variant="outlined">
          Edit Config
        </Button>
        <Dialog
          aria-labelledby="config-modal-title"
          aria-describedby="config-modal-description"
          fullWidth={true}
          maxWidth={'sm'}
          open={this.state.open}
          onClose={this.handleClose}>
          <div className={classes.paper}>
            <Typography variant="h5" style={{ marginBottom: 8 }}>
              {group.name}
            </Typography>

            <TextField
              disabled={submitting}
              className={classes.field}
              fullWidth
              label="Dashboard Mashup URL"
              value={this.state.form.dashboardMashupUrl}
              onChange={this.onChange('dashboardMashupUrl')}
              margin="normal"
              variant="outlined"
              InputLabelProps={{ shrink: true }} />

            <TextField
              disabled={submitting}
              className={classes.field}
              fullWidth
              label="Dashboard External URL"
              value={this.state.form.dashboardExternalUrl}
              onChange={this.onChange('dashboardExternalUrl')}
              margin="normal"
              variant="outlined"
              InputLabelProps={{ shrink: true }} />

            <TextField
              disabled={submitting}
              className={classes.field}
              fullWidth
              label="Dashboard Email Key"
              value={this.state.form.dashboardUserEmailKey}
              onChange={this.onChange('dashboardUserEmailKey')}
              margin="normal"
              variant="outlined"
              InputLabelProps={{ shrink: true }} />
              
            <TextField
              disabled={submitting}
              className={classes.field}
              fullWidth
              label="Leases API Id"
              value={this.state.form.leasesClientId}
              onChange={this.onChange('leasesClientId')}
              margin="normal"
              variant="outlined"
              InputLabelProps={{ shrink: true }} />

            <TextField
              disabled={submitting}
              className={classes.field}
              fullWidth
              label="Axxerion Layer"
              value={this.state.form.axxerionLayer}
              onChange={this.onChange('axxerionLayer')}
              margin="normal"
              variant="outlined"
              InputLabelProps={{ shrink: true }} />

            <TextField
              disabled={submitting}
              className={classes.field}
              fullWidth
              label="Axxerion Auth"
              value={this.state.form.axxerionAuth}
              onChange={this.onChange('axxerionAuth')}
              margin="normal"
              variant="outlined"
              InputLabelProps={{ shrink: true }} />

            <FormControlLabel
              control={
                <Checkbox checked={this.state.form.isClient} color="primary" onChange={this.onChange('isClient')} />
              }
              label="Is a Client" />

            <div className={classes.buttonWrapper}>
              <Button
                className={classes.submitButton}
                color="primary"
                disabled={pristine || submitting}
                onClick={this.onSubmit}
                type="button"
                variant="contained">
                Submit
              </Button>
              <Button
                className={classes.resetButton}
                color="secondary"
                disabled={pristine || submitting}
                onClick={this.reset}
                type="button"
                variant="outlined">
                Reset
              </Button>
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styles)(ConfigModal);
