import React from 'react';
import PropTypes from 'prop-types';
import SvgIcon from '@material-ui/core/SvgIcon';

const SvgComponent = (props) => (
  <SvgIcon
    viewBox="0 0 32 32" role="img" aria-labelledby="title"
    {...props}>
    <title id="title">{props.title || 'Bookmark icon'}</title>
    <g fill="currentColor" fillRule="evenodd">
      <path d="M28,32 L16,20.988609 L4,32 L4,0 L28,0 L28,32 Z" id="Combined-Shape" />
    </g>
  </SvgIcon>
);

SvgComponent.propTypes = {
  title: PropTypes.string,
};

export default SvgComponent;
