import React from 'react';
import PropTypes from 'prop-types';
import ChipInput from 'material-ui-chip-input';

class ChipsField extends React.Component {
  static propTypes = {
    input: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      value: PropTypes.array.isRequired
    })
  }

  constructor(props) {
    super(props);
    this.state = {
      value: props.input.value || []
    };
  }

  handleAddChip = (chip) => {
    const newValue = this.state.value.concat([chip]);
    this.setState({ value: newValue });
    this.props.input.onChange(newValue);
  }

  handleDeleteChip = (chip) => {
    const newValue = this.state.value.filter((val) => val !== chip);
    this.setState({ value: newValue });
    this.props.input.onChange(newValue);
  }

  render() {
    return (
      <ChipInput
        blurBehavior="add"
        fullWidth
        onAdd={this.handleAddChip}
        onDelete={this.handleDeleteChip}
        value={this.state.value} />
    );
  }
}

export default ChipsField;
