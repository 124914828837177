/* eslint-disable max-len */
import React from 'react';

const SvgComponent = (props) => (
  <svg
    width={160} height={230.667} viewBox="0 0 120 173"
    {...props}>
    <path d="M45.7 13.8C32.9 26.6 30.3 30 33.2 30c.6 0 2 .8 3 1.7 1.9 1.7 2.3 1.4 11-7.5 5-5.1 9.2-9.2 9.4-9.2.2 0 .4 20.2.4 45v45h7V14.5l9.4 9.4c6 6 9.6 8.9 10.1 8.1.4-.6 1.8-1.5 3.2-2.1 2.4-.9 2-1.4-11.4-15.4C67.7 6.6 61 0 60.5 0c-.6 0-7.2 6.2-14.8 13.8z" />
    <path d="M0 109v60h120V49H74.7l.7 3.1c.3 1.7.6 3.5.6 4s8.1.9 18 .9h18v103H8V57h35.8l.6-3.1c.3-1.7.6-3.5.6-4S34.9 49 22.5 49H0v60z" />
  </svg>
);

export default SvgComponent;
