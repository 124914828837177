import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClearIcon from '@material-ui/icons/Clear';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import DropzoneArea from '../shared/dropzone/DropzoneArea';

const styles = (theme) => ({
  dialogCloseButton: {
    paddingRight: 0
  },
  dialogTitle: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
  },
  noImageButton: {
    marginTop: theme.spacing.unit
  },
  uploadingSpinner: {
    display: 'flex',
    justifyContent: 'center'
  }
});

const ImageUploader = ({ allowNull, classes, maxFiles, onClose, onUpdate, preset, show, title }) => {
  const [files, setFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [dropzoneState, setDropzoneState] = useState({});

  const handleUpload = async () => {
    setIsUploading(true);
    try {
      const fileResponses = await Promise.all(files.map(async (file) => {
        const formData = new FormData();
        formData.append('upload_preset', preset);
        formData.append('file', file.data);
        const response = await fetch(process.env.REACT_APP_CLOUDINARY_URL, {
          method: 'POST',
          body: formData
        });

        return (await response.json()).secure_url;
      }));
      
      setIsUploading(false);
      onClose();
      onUpdate(fileResponses);
    } catch (error) {
      console.error(error);
      setIsUploading(false);
    }
  };

  const handleNoImage = () => {
    onClose();
    onUpdate([null]);
  };

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      maxWidth="xs"
      open={show}>
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <IconButton className={classes.dialogCloseButton} onClick={onClose}>
          <ClearIcon />
        </IconButton>
        <Typography variant="h5" className={classes.dialogTitleText}>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent classes={{ root: classes.dropzoneWrapper }}>
        {!isUploading &&
          <React.Fragment>
            <DropzoneArea
              allowedFileTypes={['image/*']}
              clearOnUnmount={true}
              files={files}
              filesLimit={maxFiles}
              setFiles={setFiles}
              setState={setDropzoneState}
              state={dropzoneState} />
            <Button
              className={classes.actionButton}
              color="primary"
              disabled={!files.length || isUploading}
              onClick={handleUpload}
              size="large"
              fullWidth
              variant="contained">
              Upload
            </Button>
            {allowNull && <Button
              className={cx(classes.actionButton, classes.noImageButton)}
              color="secondary"
              onClick={handleNoImage}
              size="large"
              fullWidth
              variant="contained">
              No Image
            </Button>}
          </React.Fragment>}
        {isUploading &&
          <div className={classes.uploadingSpinner}>
            <CircularProgress />
          </div>}
      </DialogContent>
    </Dialog>
  );
};

ImageUploader.propTypes = {
  allowNull: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  maxFiles: PropTypes.number,
  onClose: PropTypes.func,
  onUpdate: PropTypes.func,
  preset: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  title: PropTypes.node
};

export default withStyles(styles)(ImageUploader);
