import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { logAppInteraction, pinUserApp, unpinUserApp } from '../shared/actions';
import App from './AppCard';

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ logAppInteraction, pinUserApp, unpinUserApp }, dispatch);
};

export default connect(null, mapDispatchToProps)(App);
