import React from 'react';
import PropTypes from 'prop-types';
import FileIcon, { defaultStyles } from 'react-file-icon';

const Ppt = ({ size = 36 }) => (
  <FileIcon extension="pptx" {...defaultStyles.pptx} size={size} />
);

Ppt.propTypes = {
  size: PropTypes.number
};

export default Ppt;

