import {
  APP_REVIEWS_READ_REQUEST,
  APP_REVIEWS_READ_SUCCESS,
  APP_REVIEWS_READ_ERROR,
  APP_REVIEWS_CLEAR,
  APP_REVIEW_WRITE_SUCCESS,
} from '../../constants/actionTypes';

export default (state = { isFetching: true, hasError: false, reviews: {} }, action) => {
  switch (action.type) {
    case APP_REVIEWS_CLEAR:
      return {
        ...state,
        isFetching: false,
        hasError: false,
        reviews: {}
      };

    case APP_REVIEWS_READ_REQUEST:
      return {
        ...state,
        isFetching: true,
        hasError: false,
      };

    case APP_REVIEWS_READ_SUCCESS:
      if (JSON.stringify(action.response) === '{}') {
        return {
          ...state,
          isFetching: false,
          hasError: false,
        };
      }
      return {
        ...state,
        isFetching: false,
        hasError: false,
        reviews: {
          ...action.response,
          docs: [...(state.reviews.docs || []), ...action.response.docs],
          my: action.response.my ? { ...action.response.my } : { ...state.reviews.my }
        }
      };

    case APP_REVIEWS_READ_ERROR:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        error: action.response,
      };

    case APP_REVIEW_WRITE_SUCCESS:
      // update my review
      const newState = {
        ...state,
        reviews: {
          ...state.reviews,
          docs: state.reviews.docs || [],
          my: { ...action.response },
        }
      };

      // update my review in reviews list (or prepend it if not yet present)
      const myVisibleReview = newState.reviews.docs.find((d) => d._id === action.response._id);
      if (myVisibleReview) {
        myVisibleReview.rating = action.response.rating;
        myVisibleReview.review = action.response.review;
      } else {
        newState.reviews.docs.unshift({ ...action.response });
      }
      return newState;

    default:
      return state;
  }
};
