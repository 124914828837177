import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import Typography from '@material-ui/core/Typography';
import SortIcon from '@material-ui/icons/Sort';
import IconButton from '@material-ui/core/IconButton';
import ConnectedAppListItem from './ConnectedAppListItem';

const styles = {
  buttonHeader: {
    width: 320,
  },
  table: {
    margin: '0 auto',
    backgroundColor: 'white',
    borderRadius: '4px',
  },
  splitColumns: {
    padding: 0
  }
};

class ListView extends Component {
  static propTypes = {
    apps: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
    filteredApps: PropTypes.array.isRequired,
    sorted: PropTypes.bool,
    updateSort: PropTypes.func.isRequired
  };

  render() {
    const { classes, filteredApps, apps, updateSort, alphabeticalSorted, featureSorted, pinnedSorted } = this.props;
    return (
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <b>APPLICATIONS</b>
                <IconButton onClick={updateSort(alphabeticalSorted ? 'az' : 'za')}>
                  {alphabeticalSorted ? <SortIcon style={{ transform: 'scaleY(-1)' }} /> : <SortIcon /> }
                </IconButton>
              </div>
            </TableCell>
            <TableCell />
            <TableCell className={classes.splitColumns}>
              <IconButton onClick={updateSort('feature')}>
                {featureSorted ? <SortIcon style={{ transform: 'scaleY(-1)' }} /> : <SortIcon /> }
              </IconButton>
            </TableCell>
            <TableCell className={classes.splitColumns}>
              <IconButton onClick={updateSort('pin')}>
                {pinnedSorted ? <SortIcon style={{ transform: 'scaleY(-1)' }} /> : <SortIcon /> }
              </IconButton>
            </TableCell>
            <TableCell><b>TAGS</b></TableCell>
            <TableCell><b>DESCRIPTION</b></TableCell>
            <TableCell className={classes.buttonHeader} />
          </TableRow>
        </TableHead>
        <TableBody>
          {
            filteredApps.map((app, index) => (
              <ConnectedAppListItem 
                {...app} 
                key={index} />
            ))
          }
          {
            filteredApps.length === 0 && apps.length > 0 &&
            <TableRow>
              <TableCell colSpan={5}>
                <Typography>
                     No applications match your current search parameters.
                </Typography>
              </TableCell>
            </TableRow>
          }
        </TableBody>
      </Table>
    );
  }
}

export default withStyles(styles)(ListView);
