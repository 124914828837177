import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import App from './App';
import * as actions from './actions';

const mapStateToProps = ({ auth, app }) => {
  return {
    hasError: false,
    isFetching: true,
    appTitle: app.appTitle,
    routeTitle: app.routeTitle,
    ...auth
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(actions, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
