import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Rating from './Rating';
import * as actions from './actions';

const mapStateToProps = ({ rating }) => {
  return {
    ratings: {},
    averageRatings: {},
    hasRatingsError: false,
    areRatingsFetching: false,
    ...rating,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(actions, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Rating);