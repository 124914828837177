import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchNotifications, dismissNotification } from '../app/actions';
import Notifications from './Notifications';

const mapStateToProps = ({ app: { notifications } }) => {
  return {
    notifications: (notifications || []).filter((n) => !n.dismissedAt)
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    dismissNotification,
    fetchNotifications,
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
