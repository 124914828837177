import { goBack } from 'connected-react-router';
import api from '../services/myColliersApi';
import {
  APPS_READ_REQUEST,
  APPS_READ_SUCCESS,
  APPS_READ_ERROR,
  GROUPS_CREATE_REQUEST,
  GROUPS_CREATE_SUCCESS,
  GROUPS_CREATE_ERROR,
  GROUPS_CREATE_CLEAR_ERROR,
  GROUPS_READ_REQUEST,
  GROUPS_READ_SUCCESS,
  GROUPS_READ_ERROR,
  GROUP_UPDATE_REQUEST,
  GROUP_UPDATE_SUCCESS,
  GROUP_UPDATE_ERROR,
  GROUP_DELETE_REQUEST,
  GROUP_DELETE_SUCCESS,
  GROUP_DELETE_ERROR
} from '../constants/actionTypes';
import { fetchUserPermissions } from '../app/actions';

export function navigateBack() {
  return (dispatch) => {
    return dispatch(goBack());
  };
}

function appsReadRequest() {
  return {
    type: APPS_READ_REQUEST
  };
}

function appsReadSuccess(response) {
  return {
    type: APPS_READ_SUCCESS,
    response
  };
}

function appsReadError(error) {
  return {
    type: APPS_READ_ERROR,
    error
  };
}

export function fetchApps() {
  return (dispatch) => {
    dispatch(appsReadRequest());
    return api.read('apps')
      .then((data) => dispatch(appsReadSuccess(data)))
      .catch((response) => {
        return dispatch(appsReadError(response));
      });
  };
}

function groupCreateRequest() {
  return {
    type: GROUPS_CREATE_REQUEST
  };
}

function groupCreateSuccess(response) {
  return {
    type: GROUPS_CREATE_SUCCESS,
    response
  };
}

function groupCreateError(error) {
  return {
    type: GROUPS_CREATE_ERROR,
    error
  };
}

export function createGroup(group) {
  return (dispatch) => {
    dispatch(groupCreateRequest(group));
    return api.create('groups', group)
      .then((data) => {
        // refetch permissions and groups (since they will have changed)
        return fetchUserPermissions()(dispatch).then(() => {
          return dispatch(groupCreateSuccess(data));
        });
      })
      .catch((response) => {
        return dispatch(groupCreateError(response));
      });
  };
}

export function clearGroupCreateError() {
  return (dispatch) => {
    dispatch({
      type: GROUPS_CREATE_CLEAR_ERROR
    });
  };
}

function groupReadRequest() {
  return {
    type: GROUPS_READ_REQUEST
  };
}

function groupReadSuccess(response) {
  return {
    type: GROUPS_READ_SUCCESS,
    response
  };
}

function groupReadError(error) {
  return {
    type: GROUPS_READ_ERROR,
    error
  };
}

export function fetchGroups() {
  return (dispatch) => {
    dispatch(groupReadRequest());
    return api.read('groups')
      .then((data) => dispatch(groupReadSuccess(data)))
      .catch((response) => {
        return dispatch(groupReadError(response));
      });
  };
}

function groupUpdateRequest(data) {
  return {
    type: GROUP_UPDATE_REQUEST,
    data
  };
}

function groupUpdateSuccess(response) {
  return {
    type: GROUP_UPDATE_SUCCESS,
    response
  };
}

function groupUpdateError(error) {
  return {
    type: GROUP_UPDATE_ERROR,
    error
  };
}

export function updateGroup(data) {
  return (dispatch) => {
    dispatch(groupUpdateRequest(data));
    return api.update('groups', data)
      .then((data) => {
        dispatch(groupUpdateSuccess(data));
      })
      .catch((response) => {
        return dispatch(groupUpdateError(response));
      });
  };
}

function groupDeleteRequest(id) {
  return {
    type: GROUP_DELETE_REQUEST,
    id
  };
}

function groupDeleteSuccess(id) {
  return {
    type: GROUP_DELETE_SUCCESS,
    id
  };
}

function groupDeleteError(error) {
  return {
    type: GROUP_DELETE_ERROR,
    error
  };
}

export function deleteGroup(id) {
  return (dispatch) => {
    dispatch(groupDeleteRequest(id));
    return api.destroy(`groups/${id}`)
      .then(() => {
        dispatch(groupDeleteSuccess(id));
      })
      .catch((response) => {
        return dispatch(groupDeleteError(response));
      });
  };
}
