import api from '../services/myColliersApi';
import {
  PROJECT_ROOM_INVITE_REQUEST,
  PROJECT_ROOM_INVITE_SUCCESS,
  PROJECT_ROOM_INVITE_ERROR,
} from '../constants/actionTypes';

function inviteRequest(data) {
  return {
    type: PROJECT_ROOM_INVITE_REQUEST,
    data
  };
}

function inviteSuccess(response) {
  return {
    type: PROJECT_ROOM_INVITE_SUCCESS,
    response
  };
}

function inviteError(error) {
  return {
    type: PROJECT_ROOM_INVITE_ERROR,
    error
  };
}

export function createInvites(data) {
  return (dispatch) => {
    dispatch(inviteRequest(data));
    return api.create('/invites', data)
      .then((data) => dispatch(inviteSuccess(data)))
      .catch((response) => {
        return dispatch(inviteError(response));
      });
  };
}
