import React, { useState } from 'react';
import { useTracker } from '@colliers-international/usage-tracker';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import color from 'color';
import { Link } from 'react-router-dom';
import curry from 'lodash/curry';
import memoize from 'lodash/memoize';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import BookmarkIcon from '../assets/icons/BookmarkIcon';
import Placeholder from '../assets/images/placeholder skyline.png';
import { strip } from '../app/utils';

const styles = (theme) => ({
  hideScrollbars: {
    '-ms-overflow-style': 'none',
    overflow: '-moz-scrollbars-none',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  cardContainer: {
    position: 'relative',
    willChange: 'transform',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: '0 !important',
      paddingRight: '0 !important'
    },
    '&:hover button': {
      display: 'inline-flex'
    }
  },
  cardContent: {
    height: 220,
    overflow: 'hidden',
    width: '100%',
    wordWrap: 'break-word',
    position: 'relative',
    '&:after': {
      position: 'absolute',
      bottom: 0,
      height: '100%',
      width: '100%',
      content: '""',
      pointerEvents: 'none'
    },
  },
  chevronLink: {
    alignSelf: 'center',
  },
  description: {
    flex: 1,
    fontSize: '0.85em',
    margin: 0,
    height: '4.75em',
    overflowY: 'hidden',
    marginTop: 2 * theme.spacing.unit,
    marginBottom: 2 * theme.spacing.unit,
    display: '-webkit-box',
    '-webkit-line-clamp': '3',
    '-webkit-box-orient': 'vertical'
  },
  featured: {
    display: ['inline-flex', '!important'],
    height: 30,
    marginRight: theme.spacing.unit,
    marginTop: -3,
    width: 30,
    zIndex: 999,
    '&:hover': {
      backgroundColor: 'transparent',
    }
  },
  footer: {
    padding: theme.spacing.unit,
    paddingTop: 0
  },
  hoverPanel: {
    alignItems: 'center',
    display: 'flex',
    height: 60,
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%'
  },
  hoverWrapper: {
    position: 'relative'
  },
  iconsHeader: {
    display: 'flex',
    flexDirection: 'row-reverse',
    paddingRight: 12,
    position: 'absolute',
    right: 0,
    '& > button': {
      display: 'none'
    },
  },
  launchButton: {
    background: `linear-gradient(
      to right,
      ${theme.palette.primary.dark},
      ${theme.palette.primary.main}
    )`,
    borderRadius: '5px',
    fontWeight: 'bold',
    width: '50%',
    '&:hover': {
      background: `linear-gradient(
        to right,
        ${theme.palette.primary.dark},
        ${theme.palette.primary.main}
      )`,
    },
    [theme.breakpoints.down('xs')]: {
      background: theme.palette.primary.dark,
      fontSize: '13px',
      lineHeight: '20px',
      '&:hover': {
        background: theme.palette.primary.main,
      }
    }
  },
  launchButtonDisabled: {
    borderRadius: 5,
    width: '50%',
  },
  learnMoreButton: {
    borderRadius: 5,
    width: '50%',
    marginLeft: theme.spacing.unit,
  },
  learnMoreLink: {
    color: theme.palette.text.primary,
    textDecoration: 'none'
  },
  link: {
    display: 'flex',
    textDecoration: 'none',
    '&: focus, &: hover, &: visited, &: link, &: active': {
      textDecoration: 'none',
      color: 'inherit'
    }
  },
  pin: {
    paddingTop: 0,
    zIndex: 999,
    '&:hover': {
      backgroundColor: 'transparent',
    }
  },
  pinIcon: {
    color: theme.palette.grey[300],
    '&:hover': {
      color: color(theme.palette.primary.main).darken(0.3).hex()
    }
  },
  pinned: {
    color: theme.palette.primary.main,
  },
  pinnedButton: {
    display: ['inline-flex', '!important']
  },
  scrollHider: {
    height: 24,
    overflow: 'hidden'
  },
  tag: {
    marginRight: theme.spacing.unit
  },
  tags: {
    width: '100%',
    height: 100,
    overflowX: 'auto',
    overflowY: 'hidden',
    whiteSpace: 'nowrap'
  },
  thumbnail: {
    height: 45,
    maxHeight: 45
  },
  title: {
    color: theme.palette.text.primary,
    fontSize: '20px',
    lineHeight: '1em',
    fontWeight: '600',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textDecoration: 'none',
    [theme.breakpoints.down('xs')]: {
      color: theme.palette.text.primary,
    }
  },
  titleWrapper: {
    marginBottom: theme.spacing.unit,
    marginTop: theme.spacing.unit * 1.5,
  },
  wrapper: {
    borderRadius: '10px',
    height: 268,
    [theme.breakpoints.down('xs')]: {
      border: '1px solid #DDD',
      height: 268,
    }
  },
});

function App({ 
  _id, 
  classes,
  description, 
  featured, 
  logAppInteraction, 
  pinUserApp, 
  pinned, 
  slug, 
  tags, 
  thumbnail, 
  title, 
  unpinUserApp, 
  url 
}) {
  const [inHoverState, setInHoverState] = useState(false);

  const { trackEvent } = useTracker();

  const handleLaunchClick = () => {
    if (url) {
      logAppInteraction(_id, title, 'launch');
      window.open(url, '_blank');
    }
  };
  const handleLearnClick = () => {
    trackEvent({ category: 'MyColliers App Card Interface', action: 'learn more click', name: title });
    logAppInteraction(_id, title, 'learn');
  };
  const handleMouseEnter = () => {
    setInHoverState(true);
  };

  const handleMouseLeave = () => {
    setInHoverState(false);
  };

  const handlePinClick = () => { 
    if (pinned) {
      trackEvent({ category: 'MyColliers App Card Interface', action: 'unpin', name: title });
      unpinUserApp(slug);  
    } else {
      trackEvent({ category: 'MyColliers App Card Interface', action: 'pin', name: title });
      pinUserApp(slug);
    }

    return false;
  };

  const handleTagClick = memoize(curry(() => {
    //console.log('TAG', tag);
  }, 2));

  return (
    <Grid
      item
      className={classes.cardContainer}
      lg={3}
      md={4}
      sm={6}
      xs={12}>
      <div className={classes.iconsHeader}>
        {featured && <Tooltip title="Featured" aria-label="Featured" placement="top">
          <Avatar
            className={classes.featured}
            src="/images/featuredApp.png" />
        </Tooltip>}
        <Tooltip
          title={pinned ? 'Remove bookmark' : 'Bookmark app'}
          aria-label={pinned ? 'Remove bookmark' : 'Bookmark app'}
          placement="top">
          <IconButton
            className={classNames(classes.pin, { [classes.pinnedButton]: pinned })}
            disableRipple
            onClick={handlePinClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            <BookmarkIcon className={classNames(classes.pinIcon, { [classes.pinned]: pinned })} />
          </IconButton>
        </Tooltip>
      </div>
      <Card
        className={classes.wrapper}
        elevation={(inHoverState ? 8 : 0)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        square={false}>
        <div className={classes.link}>
          <CardContent classes={{ root: classes.cardContent }} >
            <div>
              <img
                className={classes.thumbnail}
                alt="Application logo"
                src={thumbnail ? thumbnail : Placeholder} />
            </div>
            <div className={classNames(classes.titleWrapper, classes.hideScrollbars)}>
              <Typography
                className={classes.title}
                variant="h5">
                {title}
              </Typography>
              {/* <Rating slug={slug} showAverage={false} /> */}
            </div>
            <div className={classes.scrollHider}>
              <div className={classNames(classes.tags, classes.hideScrollbars)}>
                {tags.map((tag) => (
                  <Chip
                    className={classes.tag}
                    clickable={false}
                    color="primary"
                    key={`${slug}-tag-${tag}`}
                    label={tag}
                    onClick={handleTagClick(tag)} />
                ))}
              </div>
            </div>
            <Typography className={classes.description} paragraph={true} variant="caption">
              {strip(description || '')}
            </Typography>
          </CardContent>
        </div>
        <CardActions className={classes.footer}>
          <Button
            className={url ? classes.launchButton : classes.launchButtonDisabled}
            color={url ? 'primary' : 'secondary'}
            onClick={handleLaunchClick}
            disabled={!url}
            variant="contained">
              Launch
          </Button>
          <Button
            className={classes.learnMoreButton}
            component={Link}
            color="primary"
            onClick={handleLearnClick}
            to={`/apps/${slug}`}
            variant="outlined">
              Learn More
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
}

App.propTypes = {
  _id: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  description: PropTypes.string,
  featured: PropTypes.bool,
  logAppInteraction: PropTypes.func.isRequired,
  pinUserApp: PropTypes.func.isRequired,
  pinned: PropTypes.bool,
  slug: PropTypes.string.isRequired,
  tags: PropTypes.array,
  thumbnail: PropTypes.string,
  title: PropTypes.string.isRequired,
  unpinUserApp: PropTypes.func.isRequired,
  url: PropTypes.string
};

export default withStyles(styles)(App);