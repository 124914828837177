import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Avatar from '@material-ui/core/Avatar';
import EditIcon from '@material-ui/icons/Edit';
import Fade from '@material-ui/core/Fade';
import ImageUploader from '../shared/ImageUploader';

const styles = (theme) => ({
  avatar: {
    backgroundColor: theme.palette.grey[50],
    border: [3, 'solid', theme.palette.grey[50]],
    height: '100%',
    width: '100%',
    minHeight: 175,
    maxHeight: 175,
  },
  avatarWrapper: {
    flex: 1,
    height: '100%',
    position: 'relative',
    width: '100%'
  },
  hoverBackground: {
    backgroundColor: theme.palette.primary.dark,
    borderRadius: '50%',
    height: '100%',
    opacity: 0.7,
    width: '100%',
  },
  hoverPanel: {
    alignItems: 'center',
    color: theme.palette.common.white,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%'
  },
  hoverWrapper: {
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 1
  },
  fallbackAvatar: {
    backgroundColor: theme.palette.grey[50],
    height: 225,
    width: 225,
    '@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)': {
      top: 0,
      marginLeft: -28,
    }
  }
});

const EditableAvatar = ({ className: classNameProp, classes, editable, onUpdate, src }) => {
  const [hovered, setHovered] = useState(false);
  const [showUploader, setShowUploader] = useState(false);

  return (
    <div
      className={classnames(classes.avatarWrapper, classNameProp)}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}>
      <Fade in={hovered}>
        <div className={classes.hoverWrapper} style={{ display: editable ? 'flex' : 'none' }}>
          <div className={classes.hoverBackground} />
          <div className={classes.hoverPanel} onClick={() => setShowUploader(true)}>
            <EditIcon />
          </div>
        </div>
      </Fade>
      <Avatar className={classes.avatar} src={src}>
        {!src && <AccountCircleIcon className={classes.fallbackAvatar} color="primary" />}
      </Avatar>
      <ImageUploader
        allowNull
        onClose={() => setShowUploader(false)}
        onUpdate={onUpdate}
        maxFiles={1}
        preset={process.env.REACT_APP_CLOUDINARY_PRESET}
        show={showUploader}
        title="New Avatar" />
    </div>
  );
};

EditableAvatar.propTypes = {
  className: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  onUpdate: PropTypes.func.isRequired,
  src: PropTypes.string
};

export default withStyles(styles)(EditableAvatar);
