import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AuthConsumer } from 'react-check-auth';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Can, { ability } from '../config/ability';
import ConnectedLegalese from '../legalese/ConnectedLegalese';
import ConnectedLogo from './ConnectedLogo';
import ConnectedNotifications from './ConnectedNotifications';
import ConnectedCreateClientGroup from './ConnectedCreateClientGroup';

const styles = (theme) => ({
  appBar: {
    borderBottom: '1px solid #ddd',
    borderRadius: '5px 0 0 0',
    boxShadow: 'none',
    display: 'flex',
    zIndex: theme.zIndex.drawer + 1,
    background: `linear-gradient(${theme.palette.grey[50]}, ${theme.palette.grey[100]})`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('sm')]: {
      position: 'static',
    },
    [theme.breakpoints.down('xs')]: {
      borderRadius: '0',
      background: `linear-gradient(
        to right,
        ${theme.palette.primary.main},
        ${theme.palette.primary.darkish}
      )`,
      borderBottomColor: theme.palette.primary.main,
    },
  },
  appBarShift: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: 'none',
  },
  appLoginButton: {
    float: 'right',
    [theme.breakpoints.down('xs')]: {
      color: theme.palette.common.white,
    }
  },
  appMenuItem: {
    borderBottom: '1px solid #e7e7e7',
    color: theme.palette.secondary.light,
    paddingLeft: '4px',
    paddingRight: '4px',
    '&:last-child': {
      borderBottom: '0'
    }
  },
  appMenuPaper: {
    padding: '0 15px',
    '& a': {
      color: 'inherit',
      textDecoration: 'none',
      '&:focus': {
        outline: 'none'
      }
    }
  },
  clientAvatar: {
    height: 32,
    marginRight: theme.spacing.unit,
    width: 32,
  },
  clientLabel: {
    flex: 1,
    marginRight: theme.spacing.unit,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  clientMenuItem: {
    display: 'flex',
    '& $onHoverIcon': {
      display: 'none'
    },
    '&:hover $onHoverIcon': {
      display: 'block'
    },
    '&:hover $noHoverIcon': {
      display: 'none'
    }
  },
  clientSearch: {
    boxSizing: 'border-box',
    margin: 0,
  },
  clientSearchInputFieldset: {
    border: 'none',
  },
  clientSearchInputInput: {
    paddingBottom: 8,
    paddingTop: 8,
  },
  clientSearchInputRoot: {
    paddingLeft: 8
  },
  fallbackAvatar: {
    top: 0,
    backgroundColor: theme.palette.grey[50],
    height: 50,
    width: 50,
    '@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)': {
      marginLeft: -5,
    }
  },
  hiddenClient: {
    border: 0,
    height: 0,
    overflow: 'hidden',
    paddingBottom: 0,
    paddingTop: 0
  },
  onHoverIcon: {},
  noHoverIcon: {},
  toolbar: {
    display: 'flex',
    height: 85,
    justifyContent: 'flex-end',
    '& a': {
      textDecoration: 'none'
    },
    [theme.breakpoints.down('xs')]: {
      height: theme.xsToolbarHeight,
      justifyContent: 'flex-start',
    },
  },
  menuButton: {
    height: 36,
    width: 36
  },
  menuLink: {
    display: 'block',
    width: '100%'
  },
  menuLinkLabel: {
    color: theme.palette.primary.dark,
    textDecoration: 'underline',
    textTransform: 'uppercase',
    fontSize:'70%',
  },
  menuName: {
    color: theme.palette.secondary.light,
    fontWeight: 'bold'
  },
  mobilePageTitle: {
    color: 'white',
    flex: 1,
    fontSize: 24,
    fontWeight: 600,
    height: 72,
    lineHeight: '72px',
    overflow: 'hidden',
    padding: 0,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  navMenu: {
    marginTop: 22,
    minWidth: 275,
    zIndex: 1000,
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
      minWidth: 0,
      width:'97vw'
    }
  },
  scrollableInnerMenu: {
    height: 'auto',
    padding: 0,
    '&:hover': {
      background: 'unset'
    }
  },
  scrollableInnerMenuInner: {
    maxHeight: 'calc(100vh - 330px)',
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: 0,
    width: '100%'
  },
  viewAsClient: {
    color: 'black'
  },
  viewAsClientAvatar: {
    border: '1px solid white',
    borderRadius: '50%',
    cursor: 'pointer',
    height: 22,
    marginLeft: -6,
    marginTop: -18,
    position: 'absolute',
    width: 22
  },

  avatarMenuContainer: {
    height: 40,
    width: 40,
    marginRight: 28,
    [theme.breakpoints.down('xs')]: {
      marginRight: 15,
    }
  },
  userAvatar: {
    border: '1px solid white',
    cursor: 'pointer',
    margin: '0 auto',
  },
});

export class Header extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    clearGroupCreateError: PropTypes.func.isRequired,
    createError: PropTypes.string,
    groups: PropTypes.array,
    mobileTitle: PropTypes.string,
    navDrawerOpen: PropTypes.bool.isRequired,
    permissionsFetched: PropTypes.bool.isRequired,
    setViewAsClient: PropTypes.func.isRequired,
    toggleNavDrawer: PropTypes.func.isRequired,
    viewAsClientId: PropTypes.string,
  }

  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      createClientGroup: false,
      clientSearchString: ''
    };
  }

  handleClientSelect = (clientId) => () => {
    // deselect if clicks the same client and has Colliers groups to fall back to
    if (clientId === this.props.viewAsClientId) {
      if (this.props.groups.filter((group) => !group.isClient).length > 0) {
        this.props.setViewAsClient(null);
      }

    // else, select
    } else {
      this.props.setViewAsClient(clientId, this.props.groups);
    }
    this.handleMenuClose();
  }

  handleMenuClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleMenuClose = () => {
    // setTimeout is for if "clicks away" on menu button
    setTimeout(() => {
      this.setState({ anchorEl: null });
    }, 0);
  }

  handleCreateClient = () => {
    this.setState({ createClientGroup: true });
  }

  handleCreateClientClose = () => {
    this.setState({ createClientGroup: false });
  }

  handleClientLogo = () => {
    const { groups, viewAsClientId, setViewAsClient } = this.props;
    const activeClient = groups.find((group) => group._id === viewAsClientId);
    if (!activeClient) {
      if (groups.length > 0) {
        const firstClient = groups[0];
        setViewAsClient(firstClient._id, groups);
        return (firstClient.logoUrl);
      }
      setViewAsClient();
      return ('');
    }

    return activeClient.logoUrl;
  }

  smallImgFix = (srcUrl, width, height) => {
    // if IE11...
    if (!!window.MSInputMethodContext && !!document.documentMode) {
      // ... and if cloudinary url, rewrite to different size
      if (/^https:\/\/res\.cloudinary\.com\/.+\/image\/upload/.test(srcUrl)) {
        const parts = srcUrl.split('/');
        parts[parts.indexOf('upload') + 1] = `w_${2 * width},h_${2 * height},c_fit`;
        return parts.join('/');
      }
    }
    return srcUrl;
  }

  clearClientSearch = () => {
    // wrap in a timeout so the click that probably happened on a search result happens first
    setTimeout(() => {
      this.setState({ clientSearchString: '' });
    }, 500);
  };
  updateClientSearch = (event) => {
    this.setState({ clientSearchString: event.target.value });
  };

  render() {
    const {
      classes,
      clearGroupCreateError,
      createError,
      groups,
      mobileTitle,
      navDrawerOpen,
      permissionsFetched,
      toggleNavDrawer,
      viewAsClientId
    } = this.props;

    // is colliers user if in any non-client groups
    const isColliersUser = (groups.filter((group) => !group.isClient).length > 0);

    // set clients list
    const clients = groups.filter((group) => group.isClient);

    // only need client switcher if this is a colliers member with client(s) or a user part of multiple client groups
    // client switcher applies client search string and sorts alphabetically
    // (we choose to "hide" rather than filter so navmenu doesn't change width when searching clients)
    const clientSearch = (this.state.clientSearchString || '').toLowerCase();
    const clientSwitcher = (isColliersUser || clients.length > 1)
      ? clients
        .map((c) => ({
          ...c,
          hide: !(clientSearch.length === 0 || c.name.toLowerCase().indexOf(clientSearch) > -1)
        }))
        .sort((a, b) => {
          if (a._id === viewAsClientId) return -1;
          if (b._id === viewAsClientId) return 1;
          return (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1);
        })
      : [];
    const noClientSwitcherResults = !clientSwitcher.find((c) => !c.hide);

    return (
      <div>
        <AppBar
          position="fixed"
          className={classNames(classes.appBar, {
            [classes.appBarShift]: navDrawerOpen
          })}>
          {permissionsFetched && (
            <Hidden className={classes.logo} xsDown implementation="css">
              <ConnectedLogo />
            </Hidden>
          )}
          <Toolbar className={classes.toolbar} disableGutters={true}>
            <AuthConsumer>
              {({ isLoading, userInfo }) => {
                if (isLoading) return (<CircularProgress className={classes.userAvatar} />);
                if (userInfo) {
                  return (
                    <React.Fragment>
                      <Hidden smUp implementation="js">
                        <React.Fragment>
                          <IconButton color="inherit" onClick={toggleNavDrawer}>
                            <MenuIcon className={classes.menuButton} />
                          </IconButton>
                          
                          <Typography className={classes.mobilePageTitle}>{mobileTitle}</Typography>
                        </React.Fragment>
                      </Hidden>

                      <span style={{ display: 'flex', flexDirection: 'row' }}>
                        <ConnectedNotifications />
                        <div className={classes.avatarMenuContainer}>
                          <Avatar
                            className={classes.userAvatar}
                            onClick={this.handleMenuClick}
                            src={this.smallImgFix(userInfo.photo, 38, 38)}>
                            {!userInfo.photo &&
                              <AccountCircleIcon className={classes.fallbackAvatar} color="primary" />}
                          </Avatar>
                          {viewAsClientId &&
                            <Avatar
                              className={classes.viewAsClientAvatar}
                              onClick={this.handleMenuClick}
                              src={this.smallImgFix(this.handleClientLogo(), 20,20)} />
                          }
                          <Popper
                            anchorEl={this.state.anchorEl}
                            disablePortal
                            id="nav-user-menu"
                            className={classes.navMenu}
                            open={Boolean(this.state.anchorEl)}
                            placement="bottom-end"
                            transition>
                            {({ TransitionProps }) => (
                              <Grow
                                {...TransitionProps}
                                id="menu-list-grow"
                                style={{ transformOrigin: 'center top' }} >
                                <Paper className={classes.appMenuPaper}>
                                  <ClickAwayListener onClickAway={this.handleMenuClose}>
                                    <MenuList>
                                      <MenuItem
                                        onClick={this.handleMenuClose}
                                        className={classes.appMenuItem}>
                                        <Link to="/profile" className={classes.menuLink}>
                                          {userInfo.name &&
                                            <span
                                              className={classes.menuLinkLabel}
                                              style={{ float:'right', marginLeft: 20 }}>
                                              Profile
                                            </span>
                                          }
                                          <span className={classes.menuName}>{userInfo.name || 'Your Profile'}</span>
                                        </Link>
                                      </MenuItem>

                                      {(clients.length > 4) && (
                                        <MenuItem className={classNames([classes.appMenuItem, classes.searchMenuItem])}>
                                          <TextField
                                            className={classes.clientSearch}
                                            fullWidth
                                            InputProps={{
                                              classes: {
                                                input: classes.clientSearchInputInput,
                                                notchedOutline: classes.clientSearchInputFieldset,
                                                root: classes.clientSearchInputRoot
                                              },
                                              startAdornment: (
                                                <InputAdornment position="start">
                                                  <SearchIcon color={noClientSwitcherResults ? 'error' : undefined} />
                                                </InputAdornment>
                                              ),
                                            }}
                                            onBlur={this.clearClientSearch}
                                            onChange={this.updateClientSearch}
                                            placeholder="Search clients..."
                                            value={this.state.clientSearchString}
                                            variant="outlined" />
                                        </MenuItem>
                                      )}
                                      {clientSwitcher.length > 0 && (
                                        // eslint-disable-next-line max-len
                                        <MenuItem className={classNames([classes.appMenuItem, classes.scrollableInnerMenu])}>
                                          <MenuList className={classes.scrollableInnerMenuInner}>
                                            {clientSwitcher.map((client) => (
                                              <MenuItem
                                                key={client._id}
                                                onClick={this.handleClientSelect(client._id)}
                                                className={
                                                  classNames(classes.appMenuItem, classes.clientMenuItem, {
                                                    [classes.viewAsClient]: client._id === viewAsClientId,
                                                    [classes.hiddenClient]: client.hide
                                                  })}>
                                                <Avatar
                                                  className={classes.clientAvatar}
                                                  src={this.smallImgFix(client.logoUrl, 32, 32)} />
                                                <span className={classes.clientLabel}>{client.name}</span>
                                                {(client._id === viewAsClientId)
                                                  ? (
                                                    <span>
                                                      <CheckIcon
                                                        className={classNames({
                                                          [classes.noHoverIcon]: isColliersUser
                                                        })} />
                                                      <CloseIcon
                                                        className={classNames({
                                                          [classes.onHoverIcon]: isColliersUser
                                                        })} />
                                                    </span>
                                                  )
                                                  : (
                                                    <Link to="#">
                                                      <span className={classes.menuLinkLabel}>View As</span>
                                                    </Link>
                                                  )
                                                }
                                              </MenuItem>
                                            ))}
                                          </MenuList>
                                        </MenuItem>
                                      )}

                                      <Can do="create" on="tenants">
                                        <MenuItem
                                          className={classes.appMenuItem}
                                          onClick={this.handleCreateClient}>
                                          Create A New Client
                                        </MenuItem>
                                      </Can>
                                      {/* this checks for update:tenantSettings on any tenant */}
                                      {ability.can('update', 'tenantSettings') && (
                                        <MenuItem
                                          onClick={this.handleMenuClose}
                                          className={classes.appMenuItem}>
                                          <Link to="/groups" className={classes.menuLink}>
                                            Client Admin
                                          </Link>
                                        </MenuItem>
                                      )}
                                      <MenuItem
                                        onClick={this.handleMenuClose}
                                        className={classes.appMenuItem}>
                                        <Link to="/logout" className={classes.menuLink} style={{ textAlign: 'center' }}>
                                          <span className={classes.menuLinkLabel}>Log Out</span>
                                        </Link>
                                      </MenuItem>
                                    </MenuList>
                                  </ClickAwayListener>
                                </Paper>
                              </Grow>
                            )}
                          </Popper>
                          <ConnectedCreateClientGroup
                            onClose={this.handleCreateClientClose}
                            onSubmit={this.handleCreateClientClose}
                            open={this.state.createClientGroup} />
                          <Snackbar
                            autoHideDuration={10000}
                            onClose={clearGroupCreateError}
                            open={!!createError} 
                            message={<span>{createError}</span>} />
                        </div>
                      </span>
                      <ConnectedLegalese email={userInfo.email} />
                    </React.Fragment>
                  );
                }

                return (
                  <React.Fragment>
                    <Link to="/login">
                      <Button
                        className={classes.appLoginButton}>
                        Login
                      </Button>
                    </Link>
                  </React.Fragment>
                );
              }}
            </AuthConsumer>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

export default withStyles(styles)(Header);
