import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Groups from './Groups';
import * as actions from './actions';

const mapStateToProps = ({ groups }) => {
  return {
    hasError: false,
    isFetchingApps: true,
    isFetchingGroups: true,
    ...groups
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(actions, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Groups);
