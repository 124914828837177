import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import FullScreenLoader from '../shared/FullScreenLoader';
import MaterialMarkdown from '../shared/MaterialMarkdown';
import Subheader from '../shared/subheader/ConnectedSubheader';
import Placeholder from '../assets/images/placeholder skyline.png';
import ActionButtons from './ActionButtons';
import Contact from './Contact';
import Gallery from './Gallery';
import ReviewContainer from './reviews/ConnectedReviewContainer';

const styles = (theme) => ({
  bottomButtons: {
    background: 'white',
    justifyContent: 'center',
    padding: [60, 0, 100],
    width: '100%',
  },
  chip: {
    margin: theme.spacing.unit,
    '&>span': {
      position: 'relative',
      top: 2,
    },
  },
  chipWrapper: {
    marginLeft: -theme.spacing.unit,
    padding: 0,
  },
  contentContainer: {
    paddingLeft: theme.spacing.unit * 4,
    paddingRight: theme.spacing.unit * 4,
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  cost: {
    margin: [theme.spacing.unit * 3, 0],
    color: theme.palette.secondary.light,
    fontWeight: 'bold',
  },
  description: {
    color: [theme.palette.secondary.light, '!important'],
    fontSize: [18, '!important'],
    fontWeight: ['600', '!important'],
    lineHeight: ['1.6em', '!important'],
    '& p': {
      margin: [theme.spacing.unit * 2, 0],
    },
  },
  descriptionOverflow: {
    /*
    [theme.breakpoints.down('xs')]: {
      maxHeight: '20vh',
      overflow: 'auto',
      borderBottom: '1px solid #DDD'
    }
    */
  },
  descriptionWrapper: {
    padding: [theme.spacing.unit * 4, theme.spacing.unit * 3, 0, theme.spacing.unit * 3],
    wordWrap: 'break-word',
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing.unit * 2,
    },
  },
  featured: {
    display: 'inline-flex',
    marginBottom: -theme.spacing.unit * 0.5,
    height: theme.spacing.unit * 4,
    width: theme.spacing.unit * 4,
  },
  flexCol: {
    display: 'flex',
    flexDirection: 'column',
  },
  galleryWrapper: {
    minWidth: '50%',
    flex: [1, 1, '50%'],
    padding: theme.spacing.unit * 4,
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing.unit * 2,
      paddingTop: theme.spacing.unit * 2,
      paddingBottom: theme.spacing.unit,
    },
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  mobileActionContainer: {
    padding: 2 * theme.spacing.unit,
    background: theme.palette.background.default,
  },
  mobileAction: {
    width: '100%',
    fontWeight: 'bold',
    borderRadius: 5,
  },
  mobileCost: {
    fontWeight: 'bold',
  },
  mobilePrice: {
    textAlign: 'center',
  },
  reviewContainer: {
    marginBottom: theme.spacing.unit * 5,
    paddingLeft: theme.spacing.unit * 4,
    paddingRight: theme.spacing.unit * 4,
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  reviewSection: {
    padding: [0, 2 * theme.spacing.unit],
  },
  sectionHeader: {
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    marginTop: 2 * theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  spaceOut: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  thumbnailImage: {
    maxWidth: 250,
  },
  title: {
    fontWeight: 'bold',
    color: theme.palette.primary.dark,
    marginTop: theme.spacing.unit * 4,
    padding: 0,
    display: 'inline-flex',
  },
  toolbar: {
    borderBottom: [1, 'solid', theme.palette.grey[400]],
    justifyContent: 'flex-end',
    padding: theme.spacing.unit,
  },
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    '@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)': {
      display: 'block',
      overflowX: 'hidden',
    },
  },
});

class Details extends React.Component {
  static propTypes = {
    app: PropTypes.object,
    classes: PropTypes.object.isRequired,
    clearApp: PropTypes.func.isRequired,
    deleteApp: PropTypes.func.isRequired,
    fetchApp: PropTypes.func.isRequired,
    groups: PropTypes.array.isRequired,
    hasError: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool.isRequired,
    logAppInteraction: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    pinUserApp: PropTypes.func.isRequired,
    setPageTitles: PropTypes.func.isRequired,
    unpinUserApp: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { fetchApp, match } = this.props;
    fetchApp(match.params.slug);
  }

  componentDidUpdate(prevProps) {
    if (this.props.app && this.props.app.title && !(prevProps.app && prevProps.app.title)) {
      this.props.setPageTitles({
        route: `Application | ${this.props.app.title}`,
        mobile: `${this.props.app.title}`,
      });
    }
  }

  componentWillUnmount() {
    this.props.clearApp();
  }

  launchApp = () => {
    if (this.props.app.url) {
      this.props.logAppInteraction(this.props.app._id, this.props.app.title, 'launch');
      window.open(this.props.app.url, '_blank');
    }
  };

  render() {
    const {
      app,
      classes,
      deleteApp,
      groups,
      hasError,
      isFetching,
      logAppInteraction,
      pinUserApp,
      unpinUserApp,
    } = this.props;

    if (hasError) return <div>Error</div>;

    if (isFetching) return <FullScreenLoader />;

    const isFeatured = groups.reduce((a, b) => {
      return a || (b.featuredApps || []).indexOf(app._id) > -1;
    }, false);

    return (
      <div className={classes.wrapper}>
        <Subheader overrideBack="/launcher">
          <ActionButtons
            app={app}
            deleteApp={deleteApp}
            logAppInteraction={logAppInteraction}
            pinUserApp={pinUserApp}
            unpinUserApp={unpinUserApp} />
        </Subheader>
        <Grid className={classes.contentContainer} container spacing={24}>
          <Grid item xs={12} sm={6}>
            <Grid container className={classes.descriptionWrapper}>
              <Grid item className={classes.header} xs={12}>
                <img
                  className={classes.thumbnailImage}
                  alt={app.title}
                  src={app.thumbnail ? app.thumbnail : Placeholder} />
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.title} variant="h4">
                  {app.title}
                </Typography>
                {isFeatured && (
                  <Tooltip title="Featured" aria-label="Featured" placement="top">
                    <img alt="featured" className={classes.featured} src="/images/featuredApp.png" />
                  </Tooltip>
                )}
              </Grid>
              <Grid item xs={12}>
                <div className={classes.chipWrapper}>
                  {app.tags &&
                    app.tags.map((tag) => (
                      <Chip
                        className={classes.chip} clickable={false} color="primary"
                        key={tag} label={tag} />
                    ))}
                </div>
              </Grid>
              <Grid item xs={12} className={classes.descriptionOverflow}>
                <MaterialMarkdown skipHtml className={classes.description} source={app.description} />
              </Grid>
              {app.cost && (
                <Hidden xsDown>
                  <Grid item xs={12}>
                    <Typography className={classes.cost} variant="h5">
                      {app.cost}
                    </Typography>
                  </Grid>
                </Hidden>
              )}
            </Grid>
          </Grid>
          {app.gallery && !!app.gallery.length && (
            <Grid item xs={12} sm={6}>
              <div className={classes.galleryWrapper}>
                <Gallery images={app.gallery} />
              </div>
            </Grid>
          )}
        </Grid>

        <Hidden smUp>
          <Grid container className={classes.mobileActionContainer} spacing={8}>
            <Grid item xs={12}>
              <Button
                color="primary"
                disabled={!app.url}
                variant="contained"
                className={classes.mobileAction}
                onClick={this.launchApp}
                size="large">
                Launch
              </Button>
            </Grid>
          </Grid>
        </Hidden>

        <Contact contact={app.contact} />

        <ReviewContainer title={app.title} slug={app.slug} />

        <Hidden xsDown>
          <ActionButtons
            app={app}
            className={classes.bottomButtons}
            deleteApp={deleteApp}
            logAppInteraction={logAppInteraction}
            pinUserApp={pinUserApp}
            unpinUserApp={unpinUserApp} />
        </Hidden>
      </div>
    );
  }
}

export default withStyles(styles)(Details);
