import React from 'react';
import { AuthConsumer } from 'react-check-auth';
import { Redirect } from 'react-router-dom';
import { auth } from '../auth/auth';

class Logout extends React.Component {
  componentDidMount() {
    //localStorage.clear();
    auth.logout();
  }

  componentWillUnmount() {
    this.refreshAuth();
  }

  render() {
    return (
      <AuthConsumer>
        {({ refreshAuth }) => {
          this.refreshAuth = refreshAuth;
          return (<Redirect to="/" />);
        }}
      </AuthConsumer>
    );
  }
}

export default Logout;
