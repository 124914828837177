import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';

const styles = (theme) => ({
  caption: {
    margin: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 3,
    textAlign: 'center',
  },
  card: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    mexHeight: 250,
    maxWidth: 350,
    padding: theme.spacing.unit * 4
  },
  icon: {
    color: theme.palette.primary.main
  },
  title: {
    fontWeight: 'bold',
    margin: theme.spacing.unit * 2,
    textAlign: 'center',
  },
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: '100%'
  }
});

const VerifyEmailNotification = ({ classes, history, redirect }) => {
  const onClick = useCallback(() => {
    history.push(redirect);
  }, ['redirect']);

  return (
    <div className={classes.wrapper}>
      <Paper className={classes.card}>
        <MailOutlineIcon className={classes.icon} />
        <Typography variant="subtitle2" className={classes.title}>
          {'Please check your inbox for a verification email.'}
        </Typography>
        <Typography variant="caption" className={classes.caption}>
          {'Once you\'ve clicked the verification link, come back to this tab and click below to continue.'}
        </Typography>
        <Button
          className={classes.button}
          color="primary"
          onClick={onClick}
          variant="contained">
          {'I Have Verified My Email'}
        </Button>
      </Paper>
    </div>
  );
};

VerifyEmailNotification.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  redirect: PropTypes.string.isRequired
};

export default withStyles(styles)(withRouter(VerifyEmailNotification));
